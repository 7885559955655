import {observer} from "mobx-react";
import style from "./style.module.sass"
import {FadeInOutVertical} from "../../components/Animated";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useStores} from "../../hooks";
import {Trans, useTranslation} from "react-i18next";
import {BookingOrder, ICompanyBankAccountRequisites, Order} from "../../services/order/models";

import {Space} from "../../components/libs";
import {MenuType} from "../../services/menu/models";

const PayByInvoicePage = () => {
    const history = useHistory();
    const {generalStore, menuStore, ordersStore, bookingMenuStore, generalMenuStore} = useStores();
    const params = useParams<{
        orderId: string,
        orgId: string,
        branchId: string,
        menuId: string,
        viewOnly?: string,
        isWidget?: string
    }>();

    const {t, i18n} = useTranslation(['general', 'viewOrder']);
    const [isReady, setIsReady] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const currentMenu = generalMenuStore.menu;
    let currentOrder: Order | BookingOrder | undefined;
    (currentMenu.type === MenuType.regular) ? currentOrder = ordersStore.currentOrder : currentOrder = ordersStore.currentBookingOrder;


    useEffect(() => {
        if (currentMenu.type === MenuType.regular) {
            menuStore.init({
                menuId: generalStore.menuId,
                organizationBranchId: generalStore.organizationBranchId,
                organizationId: generalStore.organizationId
            }).then(() => setIsReady(true));
        } else {
            bookingMenuStore.init({
                bookingMenuId: generalStore.menuId,
                organizationBranchId: generalStore.organizationBranchId,
                organizationId: generalStore.organizationId
            }).then(() => setIsReady(true));
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (!currentOrder || currentOrder.id !== parseInt(params.orderId)) {
                await ordersStore.setCurrentOrder(
                    parseInt(params.orderId),
                    parseInt(params.orgId),
                    parseInt(params.branchId),
                    currentMenu.type
                );



                const order = currentOrder;

                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: !!params.viewOnly,
                        isWidget: !!params.isWidget
                    })
                }
            }
        })()
    }, [currentOrder, ordersStore, params]);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 3000)
        }
    }, [isCopied])

    if (!isReady || !currentOrder) return null;

    const paymentDetails = currentOrder.paymentDetails as ICompanyBankAccountRequisites;

    let hintClasses = [style.copiedHint];
    if (!isCopied) {
        hintClasses.push(style.hidden)
    }
    return <div className={style.container}>
        <FadeInOutVertical>
            <div className={style.block}>
                <p className={style.header}>
                    <Trans i18nKey="viewOrder:order" values={{ orderId: currentOrder.id }}/>
                </p>
                <p className={style.text}>
                    {t("viewOrder:documents.description")}
                </p>

                <div
                    className={style.currency}>
                    <Trans i18nKey="viewOrder:orderTotal" values={
                        { total: `${(currentOrder.totalPrice.price / 100).toFixed(0)} ${t('general:currency.rub')}` }
                    }/>
                </div>

                <Space size={3}/>

                <div className={[style.button, style.inverse].join(" ")} onClick={() => {
                    window.open(paymentDetails.pdfInvoiceUrl)
                }}>
                    {t("viewOrder:documents.invoice")}
                </div>
                <div className={[style.button, style.inverse].join(" ")} onClick={() => {
                    window.open(paymentDetails.pdfActUrl)
                }}>
                    {t("viewOrder:documents.act")}

                </div>
                <div className={[style.button].join(" ")} onClick={() => {
                    const { organizationId, organizationBranchId, menuId } = generalStore;
                    if (params.orderId) {
                        history.push(`/order/${organizationId}/${organizationBranchId}/${menuId}/${params.orderId}/view`);
                    } else {
                        history.push(
                            `/?orgId=${organizationId}&branchId=${organizationBranchId}&menuId=${menuId}&tableId=${generalStore.tableId}`
                        );
                    }
                }}>
                    {
                        t("general:finishToBankCardPayment")
                    }
                </div>


            </div>
        </FadeInOutVertical>

    </div>
};


export default observer(PayByInvoicePage);
