import style from "./style.module.sass"

export const Spinner = () => {
    return <div className={style.skFadingCircle}>
        <div className={[style.skCircle, style.skCircle1].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle2].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle3].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle4].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle5].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle6].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle7].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle8].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle9].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle10].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle11].join(" ")}></div>
        <div className={[style.skCircle, style.skCircle12].join(" ")}></div>
    </div>
};



