import { observer } from "mobx-react";
import * as React from "react";
import styles from "./style.module.sass"
import { MenuBookingItem, MenuItem, MenuType } from "../../services/menu/models";
import { useStores } from "../../hooks";
import { MinusButton, PlusButton } from "../buttons/plusButton";
import { useHistory } from "react-router";
import { ChosenMenuItemModifierSet } from "../../types/types";
import moment from "moment-timezone";


interface IProps {
    menuItem: MenuItem | MenuBookingItem,
    index: number,
    size?: "small" | "default",
    menuItemsCount?: number,
    indexToRemove?: number,
    onCartPage: boolean,
    chosenMenuItemModifierSets?: ChosenMenuItemModifierSet[]
}

const PlusMinusWidget = (props: IProps) => {
    const { cartStore, generalStore, generalMenuStore, bookingCheckoutStore } = useStores();
    const history = useHistory();
    const currentMenu = generalMenuStore.menu;

    let count = props.menuItemsCount;

    if (!currentMenu) return null;

    if (!count && currentMenu) {
        const cartItem = cartStore.getCartItemById(props.menuItem.id, currentMenu.type);
        if (!cartItem) return null;
        count = cartItem.count;
    }


    const size = props.size || 'default';
    const counterClasses = [styles.counter];
    if (size === 'small') {
        counterClasses.push(styles.small)
    }

    return <div className={styles.plusMinusWidget}>
        <MinusButton
            size={props.size}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                cartStore.removeItem(props.menuItem.id, currentMenu.type, {
                    indexToRemove: props.indexToRemove !== undefined ? props.indexToRemove : -1,
                    all: false
                });
                bookingCheckoutStore.resetChosenBookingSlots()
            }}/>
        <span className={counterClasses.join(" ")}>{count}</span>
        <PlusButton size={props.size}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        if (props.menuItem.data.menuItemModifierSets.length === 0) {
                            if (currentMenu.type === MenuType.regular) {
                                cartStore.addItem(props.menuItem, [], 1);
                            }
                        } else {
                            if (props.onCartPage && currentMenu.type === MenuType.regular) {
                                cartStore.addItem(props.menuItem, props.chosenMenuItemModifierSets || [], 1);
                            } else {
                                const item = props.menuItem;
                                if (generalStore.alias) {
                                    if ("menuCategoryId" in item.data) {
                                        history.push(`/${generalStore.alias}/category/${item.data.menuCategoryId}/item/${item.id}`)
                                    }
                                    if ("menuBookingCategoryId" in item.data) {
                                        history.push(`/${generalStore.alias}/category/${item.data.menuBookingCategoryId}/item/${item.id}`)
                                        bookingCheckoutStore.getTimeSlots(moment(), item.id);
                                    }

                                } else {
                                    if ("menuCategoryId" in item.data) {
                                        history.push(`/menu/category/${item.data.menuCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
                                    }
                                    if ("menuBookingCategoryId" in item.data) {
                                        history.push(`/menu/category/${item.data.menuBookingCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
                                        bookingCheckoutStore.getTimeSlots(moment(), item.id);
                                    }
                                }
                            }
                        }
                    }}/>
    </div>
};


export default observer(PlusMinusWidget)
