import style from "./style.module.sass"
import {observer} from "mobx-react";
import React, {useState} from "react";
import {useStores} from "../../../../hooks";
import {WorkingScheduleDay} from "../../../../services/menu/models";
import {Trans, useTranslation} from "react-i18next";
import logo from "../../../../assets/restify-logo-green.png";

const NonWorkingHours = () => {
    const {bookingMenuStore} = useStores();
    const [close, setClose] = useState(false);
    const {t, i18n} = useTranslation(['general', 'menu'])




    const findNextWorkingTime = () => {
        if (!bookingMenuStore.bookingMenu) return;

        const scheduleWithMin = (dayOfWeekTodayString: string) => {
            return <>
                <span>{
                    // @ts-ignore
                    t(`menu:dayOfWeekType.${dayOfWeekTodayString}`)
                }
                </span>
                <Trans i18nKey="menu:dayAndTime" values={{
                    hour: daySchedule.slots[0].fromHour,
                    min: daySchedule.slots[0].fromMinute
                }}/>
                </>
        }
        const scheduleWithoutMin = (dayOfWeekTodayString: string)=>{
            return <>
                  <span>
                      {// @ts-ignore
                          t(`menu:dayOfWeekType.${dayOfWeekTodayString}`)
                      }</span>
                <Trans i18nKey="menu:dayAndTime" values={{
                    hour: daySchedule.slots[0].fromHour,
                    min: "00"
                }}/>
                </>
        }

        const date = new Date();
        const hour = date.getHours();
        const min = date.getMinutes();
        const dayOfWeek = date.getDay();
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const schedule = Object.entries(bookingMenuStore.bookingMenu.data.workingSchedule).filter(item => (typeof item[1] !== 'number'))
        let workingHours;
        const dayOfWeekTodayString = days[dayOfWeek];
        const daySchedule = schedule.reduce((schedule, item) => {
            if (item[0] === dayOfWeekTodayString) schedule = item[1];
            return schedule;
        }, {} as WorkingScheduleDay);

        if ((!daySchedule.isDayOff && hour < daySchedule.slots[0].fromHour) ||
            (!daySchedule.isDayOff && hour === daySchedule.slots[0].fromHour && min < daySchedule.slots[0].fromMinute)) {
            if (daySchedule.slots[0].fromMinute != 0) {
                workingHours = () => scheduleWithMin(dayOfWeekTodayString);
                return workingHours();
            } else {
                workingHours = () => scheduleWithoutMin(dayOfWeekTodayString);
                return workingHours();
            }
        } else {
            for (let i = dayOfWeek + 1; i < schedule.length; i++) {
                const dayOfWeekTodayString = days[i];
                const daySchedule = schedule.reduce((schedule, item) => {
                    if (item[0] === dayOfWeekTodayString) schedule = item[1];
                    return schedule;
                }, {} as WorkingScheduleDay);
                if (!daySchedule.isDayOff) {
                    if (daySchedule.slots[0].fromMinute != 0) {
                        workingHours = () => scheduleWithMin(dayOfWeekTodayString);
                        return workingHours();
                    } else {
                        workingHours = () => scheduleWithoutMin(dayOfWeekTodayString);
                        return workingHours();
                    }
                }
                if (i === schedule.length - 2) {
                    for (i = 0; i <= dayOfWeek; i++) {
                        const dayOfWeekTodayString = days[i];
                        const daySchedule = schedule.reduce((schedule, item) => {
                            if (item[0] === dayOfWeekTodayString) schedule = item[1];
                            return schedule;
                        }, {} as WorkingScheduleDay);
                        if (!daySchedule.isDayOff) {
                            if (daySchedule.slots[0].fromMinute != 0) {
                                workingHours = () => scheduleWithMin(dayOfWeekTodayString);
                                return workingHours();
                            } else {
                                workingHours = () => scheduleWithoutMin(dayOfWeekTodayString);
                                return workingHours();
                            }
                        }
                    }

                }
            }
        }
    }


    const classes = [style.workingTimeInfo];
    if (close) classes.push(style.close);


    return (
        <>
            {!bookingMenuStore.workingTime ?
                    <div className={classes.join(" ")}>
                        <div className={style.workingTimeContainer}>
                            <div className={style.infoContainer}>
                                <svg width="50" height="50" viewBox="0 0 30 30" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M24.778 6.39398C24.6797 6.29584 24.58 6.19986 24.4795 6.10506L25.4503 4.9408L27.0413 6.26754L28.5424 4.46754L23.5604 0.312949L22.0594 2.11295L23.6503 3.43969L22.6454 4.64473C20.7188 3.36111 18.5047 2.58791 16.1719 2.39338V0H13.8281V2.39338C10.5765 2.66449 7.55561 4.06037 5.22205 6.39393C2.61023 9.00568 1.17188 12.4782 1.17188 16.1719C1.17188 19.8655 2.61023 23.3381 5.22205 25.9498C7.83381 28.5616 11.3064 30 15 30C18.6936 30 22.1662 28.5616 24.778 25.9498C27.3898 23.3381 28.8281 19.8655 28.8281 16.1719C28.8281 12.4782 27.3898 9.00568 24.778 6.39398ZM15 27.6562C8.66748 27.6562 3.51562 22.5044 3.51562 16.1719C3.51562 9.83936 8.66748 4.6875 15 4.6875C21.3325 4.6875 26.4844 9.83936 26.4844 16.1719C26.4844 22.5044 21.3325 27.6562 15 27.6562Z"
                                        fill="#FF2C3D"/>
                                    <path
                                        d="M19.7981 8.38L15.3659 13.8569C15.2466 13.8381 15.1245 13.8281 15 13.8281C13.7077 13.8281 12.6562 14.8795 12.6562 16.1719C12.6562 17.4642 13.7077 18.5156 15 18.5156C16.2923 18.5156 17.3438 17.4642 17.3438 16.1719C17.3438 15.8758 17.288 15.5927 17.1874 15.3317L21.6199 9.8544L19.7981 8.38Z"
                                        fill="#FF2C3D"/>
                                </svg>

                                <div>
                                    <h4>{t('menu:notWorking')}</h4>
                                    <p>
                                        {t('menu:openAt')} {findNextWorkingTime()}
                                    </p>
                                    <p>{t('menu:seeMenu')}</p>
                                </div>
                            </div>
                            <div className={style.button}
                                 onClick={() => setClose(!close)}>{t('menu:openMenu')}</div>
                        </div>
                        <div className={style.footer}>
                            <div onClick={() => {
                                window.open("https://restify.one")
                            }}>
                                <img src={logo} alt=""/>
                            </div>
                            <p>
                                {t("general:footer.description")}
                            </p>
                        </div>
                    </div>

                :
                <></>
            }
        </>
    )
}

export default observer(NonWorkingHours);
