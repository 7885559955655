import { observer } from "mobx-react";
import style from "./style.module.sass"
import { FadeInOutVertical } from "../../components/Animated";
import React, { useState } from "react";
import { useStores } from "../../hooks";
import { Loader } from "../../components/loader";
import { useTranslation } from "react-i18next";
import { MenuType } from "../../services/menu/models";


const OrderingNotAvailable = () => {
    const {generalStore, generalMenuStore, bookingMenuStore, menuStore} = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation(["general"]);

    if((generalStore.isMenuAvailable || generalMenuStore.type === MenuType.booking && !bookingMenuStore.isLoaded) ||
        (generalMenuStore.type === MenuType.regular && !menuStore.isLoaded) || generalMenuStore.type === MenuType.none)
        return null;




    if(isLoading){
        return <div className={style.container} style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
        }}>
            <Loader/>
        </div>
    }

    return <div className={style.container}>
        <FadeInOutVertical>
            <div className={style.block}>
                {/*<img src={orderCreatedImg} alt=""/>*/}
                <p className={style.header}>{t("general:orderingNotAvailable.title")}</p>
                <p className={style.text}>
                    {t("general:orderingNotAvailable.description")}
                </p>

                <div className={style.button} onClick={() => {
                    setIsLoading(true);
                    if (generalMenuStore.type === MenuType.booking) {
                        bookingMenuStore.init({
                            bookingMenuId: generalStore.menuId,
                            organizationBranchId: generalStore.organizationBranchId,
                            organizationId: generalStore.organizationId
                        }).then(() =>{
                            setIsLoading(false);
                        })
                    } else (
                        menuStore.init({
                            menuId: generalStore.menuId,
                            organizationBranchId: generalStore.organizationBranchId,
                            organizationId: generalStore.organizationId
                        }).then(() =>{
                            setIsLoading(false);
                        })
                    )

                }}>
                    {t("general:orderingNotAvailable.refresh")}
                </div>
            </div>
        </FadeInOutVertical>
    </div>
};


export default observer(OrderingNotAvailable);
