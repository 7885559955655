import React, {MutableRefObject} from "react";

import styled, {keyframes} from "styled-components";

import LazyLoad from "react-lazyload";
import {MediaItem, MediaType} from "../../services/menu/models";
import noImage from "../../assets/noImageProduct.png"
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props: { height?: number | string }) => props.height || 'calc(50vw - 15px)'};
  max-height: 200px;
  @media (min-width: 700px) and (max-width: 895px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(24vw - 15px)'};
  };
  @media (min-width: 896px) and (max-width: 1199px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(24vw - 15px)'};
    max-height: 300px;
  };
  @media (min-width: 1200px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(16vw - 15px)'};
    max-height: 277px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props: { height?: number | string, maxHeight?: string | null}) => props.height || 'calc(50vw - 15px)'};
  max-height: ${(props: {height?: number | string, maxHeight?: string | null}) => props.maxHeight || 'unset'};
  @media (min-width: 700px) and (max-width: 895px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(24vw - 15px)'};
  };
  @media (min-width: 896px) and (max-width: 1199px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(24vw - 15px)'};
    max-height: 300px;
  };
  @media (min-width: 1200px) {
    height: ${(props: { height?: number | string }) => props.height || 'calc(16vw - 15px)'};
    max-height: 277px;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #eeeeee;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 2s infinite;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// interface ImageProps{
//     media: null
//     src: string,
//     height?: number | string,
//     disableLazy?: boolean
// }
interface MediaProps {
    media: MediaItem[] | null,
    type: MediaType,
    height?: number | string,
    disableLazy?: boolean,
    asCarousel?: boolean,
    maxHeight?: string | null
}


// type Props = MediaProps | ImageProps;
type Props = MediaProps;

const LazyImage = (props: Props) => {
    let url = '';

    if (props.media == null || props.media.length == 0) {
        url = noImage
    } else {
        if (props.type === MediaType.preview) {
            if (props.media[0].previewHighRes) {
                url = props.media[0].previewHighRes;
            } else {
                url = props.media[0].source;
            }
        } else if (props.type === MediaType.regular) {
            if (props.media[0].regularHighRes) {
                url = props.media[0].regularHighRes;
            } else {
                url = props.media[0].source;
            }
        }
    }

    const refPlaceholder = React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

    const removePlaceholder = () => {
        refPlaceholder.current?.remove();
    };

    // if (props.disableLazy) {
    //     return <img src={url} alt="" style={{width: '100%'}}/>
    // }

    const getImageSrc = (mediaItem: MediaItem) => {
        let url = mediaItem.source
        if (props.type === MediaType.preview) {
            if (mediaItem.previewHighRes) {
                url = mediaItem.previewHighRes;
            }
        } else if (props.type === MediaType.regular) {
            if (mediaItem.regularHighRes) {
                url = mediaItem.regularHighRes;
            }
        }

        return url
    }

    return (props.asCarousel && props.media && props.media?.length > 1 ?
            (
                <CarouselWrapper height={props.height}>
                    <AwesomeSlider
                        organicArrows={false}
                        className={props.type == MediaType.preview ? 'awssldPreview' : 'awssldRegular'}
                    >
                        {props.media.map((mediaItem) => {
                            return props.disableLazy ?
                                (
                                    <ImageWrapper height={props.height}>

                                        <img src={getImageSrc(mediaItem)} alt="" style={{width: '100%'}}/>
                                    </ImageWrapper>
                                ):
                                (
                                    <ImageWrapper height={props.height}>
                                        <LazyLoad offset={500}>
                                            <StyledImage
                                                onLoad={removePlaceholder}
                                                onError={removePlaceholder}
                                                src={getImageSrc(mediaItem)}
                                                alt={""}
                                            />
                                        </LazyLoad>
                                        <Placeholder ref={refPlaceholder}/>
                                    </ImageWrapper>
                                )
                        })}
                    </AwesomeSlider>
                </CarouselWrapper>
            ) :
            ( props.disableLazy ? <img src={url} alt="" style={{width: '100%', objectFit: 'cover'}}/> :
                <ImageWrapper height={props.height} maxHeight={props.maxHeight}>
                    <LazyLoad offset={500}>
                        <StyledImage
                            onLoad={removePlaceholder}
                            onError={removePlaceholder}
                            src={url}
                            alt={""}
                        />
                    </LazyLoad>
                    <Placeholder ref={refPlaceholder}/>
                </ImageWrapper>
            )
    );
};


export default LazyImage;


