import vegImage from "./assets/veg@3x.png";
import style from "../style.module.sass";

const VegBadge = () => {
    return (
        <img src={vegImage} alt='' className={style.badge}
        />
    )
}

export default VegBadge;
