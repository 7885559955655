import { CompanyCardTypes, ICompanyCard, PaymentProviderDetails,  EventType, NewEventName, } from "../services/general/models";
import GeneralService from "../services/general";
import { generateClientSessionID } from "../components/libs";
import { saveStoreToIndexedDB } from "./persistence";
import { PaymentProviderType } from "../services/order/models";

import { action, computed, makeAutoObservable, runInAction } from "mobx"
import {ExecutionStatus} from "../apiCommandsExecutor/api";
import {rootStore} from "./index";


export enum MenuMode {
    catalog = 'catalog',
    menu = 'menu'
}

interface CatalogModeData {
    mode: MenuMode.catalog,
    catalogAlias: string
}

interface MenuModeData {
    mode: MenuMode.menu
}

type ModeData = CatalogModeData | MenuModeData;

interface ISavesAddress {
    count: number,
    value: string
}

interface ISavesPhone {
    count: number,
    value: string
}

class GeneralStore {
    modeData: ModeData = {
        mode: MenuMode.menu
    };
    organizationId: number = -1;
    organizationBranchId: number = -1;
    menuId: number = -1;
    tableId: string = '';

    pointId: string = '';
    paymentProviderDetails: PaymentProviderDetails = {
        [PaymentProviderType.cloudpayments]: null,
        [PaymentProviderType.sberbank]: null,
    }
    locale = { id: "ru" };
    clientSessionId: string = generateClientSessionID(32);
    orderSessionId: string = generateClientSessionID(32);
    isAcceptingOrders: boolean = false;
    isMenuAvailable: boolean = false;
    alias: string | null = null;
    viewOnly: boolean = false;
    isLogoAndLinkEnabled: boolean = true;
    isWidget: boolean = false;
    savedAddresses: ISavesAddress[] = [];
    savedPhones: ISavesPhone[] = [];
    legalDetails:  ICompanyCard | null = null;
    vkPixel: any | null;

    @action
    setLang(lang: string) {
        this.locale.id = lang;
        saveStoreToIndexedDB();
    }


    constructor() {
        makeAutoObservable(this)
    }

    @action
    setAlias(alias: string | null) {
        this.alias = alias;
    }


    @action
    setMode(modeData: ModeData) {
        this.modeData = modeData;
        this.alias = null;
    }
    @action
    setTableId(tableId: string) {
        this.tableId = tableId;
    }

    async init(params: {
        organizationId: number,
        organizationBranchId: number,
        menuId: number,
        tableId: string,
        pointId: string,
        viewOnly: boolean,
        isWidget: boolean
    }) {
        try {
            const res = await GeneralService.getPaymentProvider({
                organizationId: params.organizationId,
                organizationBranchId: params.organizationBranchId
            });
            const legalInfo = await GeneralService.getLegalInfo({
                organizationId: params.organizationId,
                organizationBranchId: params.organizationBranchId
            });

            let vkPixel: any | null = null;
            try {
                if (params.menuId === 771) {
                    //@ts-ignore
                    const VK = window.VK
                    console.log('initate pixel')
                    vkPixel = new VK.Pixel('VK-RTRG-1704601-aFm3x')
                    vkPixel.Hit()
                    console.log('initated')
                } else {
                    vkPixel = null
                }
            } catch (e) {
                vkPixel = null
            }

            runInAction(() => {
                this.legalDetails = legalInfo;
                this.organizationBranchId = params.organizationBranchId;
                this.organizationId = params.organizationId;
                this.menuId = params.menuId;
                this.tableId = params.tableId;
                this.paymentProviderDetails = res.paymentProvidersDetailsPublic;
                this.pointId = params.pointId;
                this.viewOnly = params.viewOnly;
                this.isWidget = params.isWidget;
                this.vkPixel = vkPixel;
            });

            saveStoreToIndexedDB();
            return { err: false };
        } catch (e) {
            console.error(`Ошибка при получении данных для проведения платежей`);
            console.error(e);
            return { err: true };
        }
    }

    @action
    setIsAcceptingOrders(value: boolean) {
        this.isAcceptingOrders = value;
    }

    @action
    setIsMenuAvailable(value: boolean) {
        this.isMenuAvailable = value;
    }


    @action
    setViewOnlyMode(viewOnlyMode: boolean) {
        this.viewOnly = viewOnlyMode;
    }

    @action
    setData(obj: any) {
        this.organizationBranchId = obj.organizationBranchId;
        this.organizationId = obj.organizationId;
        this.isAcceptingOrders = obj.isAcceptingOrders;
        this.menuId = obj.menuId;
        this.tableId = obj.tableId;
        this.paymentProviderDetails = obj.paymentProviderDetails;
        this.locale = obj.locale;
        this.clientSessionId = obj.clientSessionId || generateClientSessionID(32);
        this.alias = obj.alias;
        this.viewOnly = obj.viewOnly || false;
        this.isWidget = obj.isWidget || false;
        this.savedAddresses = obj.savedAddresses || [];
        this.savedPhones = obj.savedPhones || [];

        try {
            if (obj.menuId === 771) {
                // @ts-ignore
                const VK = window.VK
                console.log('initate pixel')
                this.vkPixel = new VK.Pixel('VK-RTRG-1704601-aFm3x') // hochu burger pixel
                this.vkPixel.Hit()
                console.log('initiated')
            } else {
                this.vkPixel = null
            }
        } catch (e) {
            console.log('ERROR INITIATE PIXEL')
            //console.log(e)
            this.vkPixel = null
        }
    }

    static fromJSON(obj: any) {
        const generalStore = new GeneralStore();
        generalStore.setData(obj);
        return generalStore;
    }

    @action
    saveAddress(address: string) {
        const item = this.savedAddresses.find(item => item.value === address);
        if (item) {
            item.count++;
        } else {
            this.savedAddresses.push({ value: address, count: 1 });
        }
        saveStoreToIndexedDB()
    }

    @action
    savePhone(phone: string) {
        const item = this.savedPhones.find(item => item.value === phone);
        if (item) {
            item.count++;
        } else {
            this.savedPhones.push({
                value: phone,
                count: 1
            });
        }
        saveStoreToIndexedDB()
    }


    getDefaultLanguage(availableLangs: string[]) {
        const defaultLangs = ['ru', 'en', 'it', 'zh'];
        const t = availableLangs.reduce((memo, item) => {
            memo[item] = true;
            return memo;
        }, {} as { [s: string]: boolean });

        for (const lang of defaultLangs) {
            if (t[lang]) return lang;
        }


        //
        throw new Error(`Default language not supported`);

    }


    @computed
    extractLegalInfo(){
        if(!this.legalDetails) return;
        if(this.legalDetails.type === CompanyCardTypes.RussianIPCard){
            return {
                title: this.legalDetails.fullTitle[this.locale.id],
                inn: this.legalDetails.inn,
                ogrn: this.legalDetails.ogrnip,
                phone: this.legalDetails.phoneNumber,
                legalAddress: this.legalDetails.legalAddress[this.locale.id],
            }
        }else{
            return {
                title: this.legalDetails.fullTitle[this.locale.id],
                inn: this.legalDetails.inn,
                ogrn: this.legalDetails.ogrn,
                phone: this.legalDetails.phoneNumber,
                legalAddress: this.legalDetails.legalAddress[this.locale.id],
            }
        }
    }





    @computed
    getPhoneSuggestion(){
        if(this.savedPhones.length > 0){
            return this.savedPhones.sort((a, b) => a.count - b.count)[0].value;
        }

        return ""
    }

    @action
    resetOrderSessionId() {
        this.orderSessionId = generateClientSessionID(32)
    }
    async addNewEvent(params: {metainfo: string | null, name: NewEventName, type: EventType }) {
        const ordersStore = rootStore.ordersStore;
        const orderId = ordersStore.currentOrder?.id || null;
        const orderSessionId = this.orderSessionId || null;
        const clientSessionId = this.clientSessionId || this.clientSessionId;
        const menuId = this.menuId;
        const organizationBranchId = this.organizationBranchId;
        const organizationId = this.organizationId;

        if (this.vkPixel) {
            switch (params.name) {
                case NewEventName.menuVisit:
                    this.vkPixel.Event('page_view')
                    break
                case NewEventName.checkout:
                    this.vkPixel.Event('initiate_payment')
                    break
                case NewEventName.orderCreated:
                    this.vkPixel.Event('purchase')
                    break
                case NewEventName.orderPaid:
                    break
                case NewEventName.cart:
                    this.vkPixel.Event('initiate_checkout')
                    break
                case NewEventName.addToCart:
                    this.vkPixel.Event('add_to_cart')
                    break
            }
        }

        // TODO tmp check
        if (params.name != NewEventName.addToCart) {
            const data = {
                ...params, menuId: menuId, organizationBranchId: organizationBranchId, organizationId: organizationId,
                orderId: orderId, orderSessionId: orderSessionId, clientSessionId: clientSessionId
            }
            const res = await GeneralService.addNewEvent({ newEvent: data });
            if (res.executionStatus !== ExecutionStatus.Finished) {
                return {
                    err: true,
                    msg: `Failed to add new event`
                }
            }
        }
    }





}

export default GeneralStore
