import {observer} from "mobx-react";
import {useStores} from "hooks";
import {Trans, useTranslation} from "react-i18next";
import {CouponType} from "../../../../services/order/models";
import {DeliveryConditionType, DeliveryType, MenuType, PriceInCurrency} from "../../../../services/menu/models";
import style from "../../style.module.sass"

const PriceWithCoupon = () => {

    const {cartStore, menuStore, checkoutStore, generalStore} = useStores();
    const {t} = useTranslation(["general", "cart", "coupon"]);

    const failedCouponCheck = checkoutStore.failedCouponCheck;
    const deliverySettings = menuStore.menu.data.deliverySettings;
    const deliveryConditions = deliverySettings[DeliveryType.toAddress]?.deliveryConditions;
    const subtotal = cartStore.getTotal(MenuType.regular);
    let freeDeliveryMinimalOrderPrice: PriceInCurrency = {
        currencyId: "RUB",
        price: 0
    };
    let fixedDeliveryPrice: PriceInCurrency =  {
        currencyId: "RUB",
        price: 0
    }

    if (deliveryConditions.type === DeliveryConditionType.fixedPrice) {
        freeDeliveryMinimalOrderPrice = deliveryConditions.freeDeliveryMinimalOrderPrice;
        fixedDeliveryPrice = deliveryConditions.fixedDeliveryPrice;
    }
    if (deliveryConditions.type === DeliveryConditionType.zonePrice) {
        freeDeliveryMinimalOrderPrice = checkoutStore.zoneFreeDeliveryMinimalOrderPrice;
        fixedDeliveryPrice = checkoutStore.zoneFixedPrice;
    }


    const addDeliveryPrice = (!generalStore.viewOnly && checkoutStore.deliveryDetails?.type === DeliveryType.toAddress)
        && (
            (subtotal < freeDeliveryMinimalOrderPrice?.price && fixedDeliveryPrice?.price !== 0)
            || (freeDeliveryMinimalOrderPrice?.price === 0 && fixedDeliveryPrice?.price !== 0)
        )


    const coupon = checkoutStore.coupon;
    const totalWithFixedDiscount = (coupon && coupon.data.type===CouponType.fixedAmount) ?
    ((cartStore.getTotal(MenuType.regular) / 100 + (addDeliveryPrice ? fixedDeliveryPrice.price / 100 : 0)) - coupon.data.discountAmount.amount/100).toFixed(0) :
    0;



    return (
        <>
        {(coupon && coupon.data.type === CouponType.percentage &&
                    new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now() &&
                    !failedCouponCheck) &&
                    <>
                        <div className={style.horizontalLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:totalWithoutDiscount")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.regular) / 100).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemDiscountTitle}>
                                        <Trans i18nKey="cart:discountAmount" values={{ discountPercent: coupon.data.discountPercent}}/>
                                    </p>
                                </div>
                                <p className={style.itemDiscountPrice}>
                                    -{(cartStore.getDiscountAmount(MenuType.regular, coupon.data.discountPercent) / 100).toFixed(0)}
                                    <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                        <div className={style.horizontalRedLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:toPay")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {((cartStore.getTotal(MenuType.regular) / 100 + (addDeliveryPrice ? fixedDeliveryPrice.price / 100 : 0)) -
                                        (cartStore.getDiscountAmount(MenuType.regular, coupon.data.discountPercent) / 100) ).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                    </>
                }
                {(coupon && coupon.data.type === CouponType.fixedAmount &&
                    new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now() &&
                    !failedCouponCheck) &&
                    <>
                        <div className={style.horizontalLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:totalWithoutDiscount")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.regular) / 100 + (addDeliveryPrice ? fixedDeliveryPrice.price / 100 : 0)).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemDiscountTitle}>
                                        {t("coupon:discount")}
                                    </p>
                                </div>
                                <p className={style.itemDiscountPrice}>
                                    -{coupon.data.discountAmount.amount/100}
                                    <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                        <div className={style.horizontalRedLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:toPay")}
                                    </p>
                                </div>
                                { (totalWithFixedDiscount>0) ?
                                    <p className={style.itemPrice}>
                                        {totalWithFixedDiscount}
                                        <span className={style.currency}>{t("general:currency.rub")}</span>
                                    </p>
                                    :
                                    <p className={style.itemPrice}>
                                        0
                                        <span className={style.currency}>{t("general:currency.rub")}</span>
                                    </p>
                                }
                            </div>
                        </div>
                    </>
                }
        </>
    )
}

export default observer(PriceWithCoupon);
