import React from "react";
import { animated, useSpring } from 'react-spring';

export const FadeInOutVertical: React.FC<{ children: JSX.Element}> = (props) => {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(0,20px,0) scale(0.9)'},
        to: { opacity: 1, transform: `translate3d(0, 0, 0) scale(1)` },
    });

    return (
        <animated.div style={style}>
            {props.children}
        </animated.div>
    );
};
