import {observer} from "mobx-react";
import {PaperBlock} from "../../components/paperBlock";
import style from "./style.module.sass"
import {Trans, useTranslation} from "react-i18next";
import TitleList from "../../components/titles/titleList";
import React, {useEffect} from "react";
import {useStores} from "../../hooks";
import SmallGreyText from "../../components/titles/SmallGreyTitle";
import {Loader} from "../../components/loader";
import {useHistory, useParams} from "react-router";
import {BookingOrder, Order, PaymentProviderType} from "../../services/order/models";
import {FadeInOutVertical} from "../../components/Animated";
import {EventType, NewEventName} from "../../services/general/models";
import {MenuType} from "../../services/menu/models";


const OrderPaymentCheck = () => {
    const { ordersStore, generalStore, checkoutStore, bookingCheckoutStore, cartStore, generalMenuStore, bookingMenuStore } = useStores();
    const history = useHistory();

    const { t } = useTranslation(['viewOrder', 'cart'])
    const params = useParams<{ orderId: string, orgId: string, branchId: string, menuId: string }>();
    const currentMenu = generalMenuStore.menu;
    let currentOrder: Order | BookingOrder | undefined;
    (currentMenu.type === MenuType.regular) ? currentOrder = ordersStore.currentOrder : currentOrder = ordersStore.currentBookingOrder;


    useEffect(() => {
        (async () => {
            if (!currentOrder || currentOrder.id !== parseInt(params.orderId)) {

                await ordersStore.setCurrentOrder(
                    parseInt(params.orderId),
                    parseInt(params.orgId),
                    parseInt(params.branchId),
                    currentMenu.type
                );

                const order = currentOrder;


                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: false,
                        isWidget: false
                    })
                }
            }
        })()
    }, [ordersStore.currentOrder, ordersStore.currentBookingOrder, ordersStore, params]);

    useEffect(() => {
        const timer = setInterval(() => {
            ordersStore.reloadCurrentOrder(currentMenu.type);
        }, 5000)
        return () => {
            clearInterval(timer);
        }
    }, [ordersStore.currentOrder?.paymentDetails, ordersStore.currentBookingOrder?.paymentDetails])
    const reset = () => {
        (currentMenu.type === MenuType.regular) ? checkoutStore.reset() : bookingCheckoutStore.reset();
        cartStore.reset();
        ordersStore.forceReload();
    }

    useEffect(() => {
        let order;
        if (currentMenu.type === MenuType.regular) {
            if (currentOrder && currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka &&
                currentOrder.paymentDetails.isPaid && checkoutStore.deliveryDetails !== null) {
                order = {
                    ...currentOrder as Order,
                    deliveryDetails: checkoutStore.deliveryDetails,
                    clientId: checkoutStore.clientId,
                }
                ordersStore.addOrder(order, currentMenu.type);
            }
        } else {
            if (currentOrder && currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka &&
                currentOrder.paymentDetails.isPaid) {
                order = {
                    ...currentOrder as BookingOrder,
                    clientId: bookingCheckoutStore.clientId,
                }
                ordersStore.addOrder(order, currentMenu.type);
            }
            generalStore.addNewEvent({
                name: NewEventName.checkout,
                type: EventType.conversion,
                metainfo: JSON.stringify({
                    provider: PaymentProviderType.sbpTochka
                })
            });

            reset();
        }

    }, [ordersStore.currentOrder?.paymentDetails, ordersStore.currentBookingOrder?.paymentDetails])

    if (!currentOrder) {
        return <div style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: 500
        }}>
            <Loader/>
        </div>
    }

    return (

        <div className={style.container}>
            <FadeInOutVertical>
                <PaperBlock>
                    <div className={style.title}>
                        <TitleList>
                            <Trans i18nKey="viewOrder:order" values={{ orderId: currentOrder.id }}/>
                        </TitleList>
                        <SmallGreyText>
                            {t("cart:waiting")}
                        </SmallGreyText>
                        {(currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka && currentOrder.paymentDetails.isPaid && currentMenu?.type === MenuType.booking) &&
                            history.push(`/booking-order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${currentOrder.id}/view`)
                        }
                        {(currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka && currentOrder.paymentDetails.isPaid && currentMenu?.type === MenuType.regular) &&
                            history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${currentOrder.id}/view`)
                        }
                        {currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka && !currentOrder.paymentDetails.isPaid &&
                            <Loader/>
                        }
                        <div className={style.button}
                             onClick={() => {
                                 if (currentOrder && currentOrder.paymentDetails?.type === PaymentProviderType.sbpTochka)
                                     window.open(currentOrder.paymentDetails.qrCodeLink)
                             }}>
                            {t("cart:bankApp")}
                        </div>
                        <div className={style.buttonToCart}
                             onClick={() => {
                                 history.goBack()
                             }
                             }>
                            {t("cart:otherPayment")}
                        </div>
                    </div>
                </PaperBlock>
            </FadeInOutVertical>
        </div>

    )
};

export default observer(OrderPaymentCheck);
