import { useLocation } from "react-router-dom";
import { getPublicAliasBindingByAlias } from "../services/aliases";
import { useEffect, useState } from "react";
import { AliasBinding } from "../services/aliases/models";

function useAlias() {
    const location = useLocation();
    const [alias, setAlias] = useState<AliasBinding | null>(null);
    const [isReady, setIsReady] = useState(false);
    const aliasString = location.pathname !== '/' ? location.pathname.split('/')[1] : null;


    useEffect(() => {
        (async() => {
            if(aliasString && aliasString !== 'menu'){
                const res = await getPublicAliasBindingByAlias({
                    alias: aliasString
                });
                setAlias(res.aliasBinding);
            }

            setIsReady(true);
        })()
    }, [aliasString])


    return [alias, isReady] as [AliasBinding | null, boolean];
}

export default useAlias;
