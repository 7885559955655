
const debounce = (func: () => void, wait: number, immediate: boolean) => {
    let timeout: number | null;
    return function() {
        //@ts-ignore
        let context = this, args = arguments as [];
        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        if(timeout){
            clearTimeout(timeout);
        }
        //@ts-ignore
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


export default debounce;
