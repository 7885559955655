import { rootStore } from "../../store";
import {BookingOrder, Order, PaymentProviderType} from "../../services/order/models";
import { useTranslation } from "react-i18next";


interface IParams {
    onSuccess: () => void;
    onFail: () => void;
    onComplete?: () => void;
}

const useSberbank = () => {
    const paymentProviderDetails = rootStore.generalStore.paymentProviderDetails[PaymentProviderType.sberbank];
    const {t, i18n} = useTranslation(["general", "cart"]);
    const locale = i18n.language;


    if (!paymentProviderDetails || paymentProviderDetails.type !== PaymentProviderType.sberbank) {
        return {
            initCharge: (params: {onSuccess: () => void; onFail: () => void}) => {
                rootStore.checkoutStore.reloadOrder();
                rootStore.bookingCheckoutStore.reloadOrder();
                params.onSuccess();
            }
        }
    }

    const initCharge = (params: IParams, order: Order | BookingOrder) => {
        if (order && order.totalPrice.price === 0) {
            params.onSuccess();
            return;
        }
        const options = {
            language:  locale === 'ru' ? "ru" : "en"
        }

        //@ts-ignore
        IPAY({api_token: paymentProviderDetails.token, language: options.language});
        //@ts-ignore
        window.ipayCheckout({
                amount: (order.totalPrice.price/100).toFixed(2),
                currency:'RUB',
                order_number: order.id.toString(),
                description: t("cart:payments.description") + `#${order.id}`
        },
            (result: any) => {
                setTimeout(() => {
                    rootStore.checkoutStore.reloadOrder();
                    rootStore.bookingCheckoutStore.reloadOrder();
                }, 2000);
                params.onSuccess()
                //console.log(result);
            },
            (result: any) => {
                setTimeout(() => {
                    rootStore.checkoutStore.reloadOrder();
                    rootStore.bookingCheckoutStore.reloadOrder();
                }, 2000);
                params.onFail()
                //console.log(result);
            });
    };
    return { initCharge }
};


export default useSberbank;
