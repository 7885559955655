import verySpicyImage from "./assets/verySpicy@3x.png";
import style from "../style.module.sass";

const VerySpicyBadge = () => {
    return (
        <img src={verySpicyImage} alt='' className={style.badge}
        />
    )
}

export default VerySpicyBadge;
