import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react"
import { useStores } from "../../../../hooks";
import styles from "./style.module.sass"
import {
    ChosenMenuItemModifierChooseMany,
    ChosenMenuItemModifierSet,
    MenuItemModifierSet
} from "../../../../types/types";
import { MinusButton, PlusButton, PrimaryButton } from "../../../../components/buttons/plusButton";
import {
    BookingBadgeType, MediaType,
    MenuItemModifierSetChooseManyData,
    MenuItemsPhotosModes,
    ModifiersSetType
} from "../../../../services/menu/models";
import { Trans, useTranslation } from "react-i18next";
import PlusMinusWidgetModifiers from "../../../../components/plusMinusWidget/PlusMinusWidgetModifiers";
import chevronDown from "./downChevron.png"
import NewBadge from "../menuItems/components/badges/NewBadge";
import { getBookingBadges } from "../menuItems/libs";
import { useSwipeable } from "react-swipeable";
import HitBadge from "../menuItems/components/badges/HitBadge";
import BookingTimeSlots from "../bookingTimeSlots/index"
import LazyImage from "../../../../components/imageLazyLoader";
import ProfitableBadge from "../menuItems/components/badges/ProfitableBadge";
import { PaperBlock } from "../../../../components/paperBlock";
import { FadeInOutVertical } from "../../../../components/Animated";
import SmallGreyText from "../../../../components/titles/SmallGreyTitle";


const ModifiersModal = () => {
    const { bookingMenuStore, bookingCheckoutStore, generalMenuStore, cartStore } = useStores();
    const item = bookingMenuStore.selectedPopupItem;
    const isOpen = generalMenuStore.isModifiersPopupVisible;
    const [chosen, setChosen] = useState<ChosenMenuItemModifierSet[]>([]);
    const [price, setPrice] = useState(0);
    const [counter, setCounter] = useState(1);
    const [isReady, setIsReady] = useState(false);
    const { t, i18n } = useTranslation(['general', 'menu']);
    const [topOffset, setTopOffset] = useState(0);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null)
    const [topScroll, setTopScroll] = useState<number>(0);
    const [isDisabledButtonClick, setIsDisabledButtonClick] = useState<boolean>(false);
    const [mandatoryChosenModifier, setMandatoryChosenModifier] = useState<string[]>([]);
    const bookedAmount = bookingCheckoutStore.bookedAmountForDate;



    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            if (topScroll > 0) return
            bookingCheckoutStore.resetBookedAmountForDate()
            generalMenuStore.onOverlayClick && generalMenuStore.onOverlayClick();
        },
        onSwiping: (eventData) => {
            if (topScroll > 0) return;
            if (eventData.deltaY < 0) {
                setTopOffset(0);
                setOpacity(1);
                setHorizontalOffset(0);
            } else {
                setTopOffset(eventData.deltaY);
                setOpacity(1 - eventData.deltaY / 400);
                setHorizontalOffset(eventData.deltaY / 50);
            }

        },
        preventScrollOnSwipe: true,
        delta: 40
    })


    useEffect(() => {

        setTimeout(() => {
            if (!containerRef.current) return;
            containerRef.current.onscroll = (data) => {
                setTopScroll(containerRef.current?.scrollTop || 0);
            }
        }, 900);
        setIsDisabledButtonClick(false);
        setMandatoryChosenModifier([])
    }, [isOpen])


    useEffect(() => {
        if (!isOpen) {
            setTopOffset(0);
            setOpacity(1);
            setHorizontalOffset(0);
        }
    }, [isOpen])

    const locale = i18n.language;

    useEffect(() => {
        if (!item) return;
        setPrice(item.data.sellPrice.price);
        setChosen(item.data.menuItemModifierSets.map(item => {
            return {
                menuItemModifierSetId: item.menuItemModifierSetId,
                chosenMenuItemModifiers: []
            }
        }));
        setCounter(1);
        setIsReady(true);

        return () => {
            setIsReady(false)
        }
    }, [item]);


    const classes = [styles.modalWindow];
    if (isOpen) {
        classes.push(styles.open);
    }

    if (!item || !isReady) {
        return <div className={classes.join(" ")}/>
    }
    if(!isOpen){
        return null
    }


    const addToCart = () => {
        cartStore.addBookingItem(item, chosen, bookingCheckoutStore.chosenBookingSlots, counter);
        //history.goBack();
        generalMenuStore.onOverlayClick && generalMenuStore.onOverlayClick();
        bookingCheckoutStore.resetBookedAmountForDate()
    };

    const modifierMessage = () => {
        return setIsDisabledButtonClick(true);
    };


    const isChosen = (index: number, itemId: string) => {
        if (chosen.length === 0 || index > chosen.length + 1) return false;
        const item = chosen[index].chosenMenuItemModifiers.find(item => item.menuItemModifierId === itemId);

        return !!item;
    };

    const setChooseOne = (index: number, itemId: string) => {
        let newChosen = [...chosen];
        let selected = newChosen[index].chosenMenuItemModifiers;
        const modifierSet = bookingMenuStore.getModifiersById(newChosen[index].menuItemModifierSetId);
        let newPrice = price;
        const newItem = modifierSet.data.modifiers.find(item => item.id === itemId);
        if (!newItem) return;

        if (selected.length > 0 && selected[0].menuItemModifierId === itemId) {
            const item = modifierSet.data.modifiers.find(item => item.id === selected[0].menuItemModifierId);
            if (!item) return;
            newPrice -= item.data.sellPrice.price;
            newChosen[index].chosenMenuItemModifiers = []
            setChosen(newChosen);
            setPrice(newPrice);
        } else if (modifierSet.data.type === ModifiersSetType.ChooseOne && selected.length === 0) {
            newPrice += newItem.data.sellPrice.price;

            newChosen[index].chosenMenuItemModifiers = [{
                menuItemModifierId: itemId,
                count: 1
            }];
            setChosen(newChosen);
            setPrice(newPrice);
        } else {
            const modifier = modifierSet.data.modifiers.find((t) => t.id === selected[0].menuItemModifierId);
            if (!modifier) return;
            newPrice -= modifier.data.sellPrice.price;
            newPrice += newItem.data.sellPrice.price;

            newChosen[index].chosenMenuItemModifiers = [{
                menuItemModifierId: itemId,
                count: 1
            }];
            setChosen(newChosen);
            setPrice(newPrice);
        }
    };


    const getChooseManyFunctions = (index: number, itemId: string) => {
        let newChosen = [...chosen];
        let itemIndex = newChosen[index].chosenMenuItemModifiers.findIndex(item => item.menuItemModifierId === itemId);
        let newPrice = price;
        const modifierSet = bookingMenuStore.getModifiersById(newChosen[index].menuItemModifierSetId);
        const data = modifierSet.data as MenuItemModifierSetChooseManyData;
        const maxCount = data.maxCountTotalChosen;

        const amountOfAllChosenMenuItemModifiers = (index: number) => {
            let res = 0;
                const modifiers = chosen[index].chosenMenuItemModifiers;
                for (let i=0; i<modifiers.length; i++) {
                    res += modifiers[i].count
                }
            return res
        };

        let add;
        let remove;

        if (itemIndex >= 0) {

            const t = newChosen[index].chosenMenuItemModifiers[itemIndex] as ChosenMenuItemModifierChooseMany;
            add = () => {
                if (maxCount !== undefined && maxCount > 0 && chosen[index].chosenMenuItemModifiers.length >= maxCount) {
                    return;
                }
                if (amountOfAllChosenMenuItemModifiers(index) >=maxCount && maxCount !== 0){
                    return;
                }
                t.count++;
                const newItem = modifierSet.data.modifiers.find(item => item.id === itemId);
                if (!newItem) return;
                newPrice += newItem.data.sellPrice.price;
                setPrice(newPrice);
                setChosen(newChosen);
            }
            remove = () => {
                if (t.count > 1) {
                    t.count--;
                    const newItem = modifierSet.data.modifiers.find(item => item.id === itemId);
                    if (!newItem) return;
                    newPrice -= newItem.data.sellPrice.price;
                } else {
                    let deleted = newChosen[index].chosenMenuItemModifiers.splice(itemIndex, 1);
                    const item = modifierSet.data.modifiers.find(item => item.id === deleted[0].menuItemModifierId);
                    if (!item) return;
                    newPrice -= item.data.sellPrice.price;

                }
                setPrice(newPrice);
                setChosen(newChosen);
            }

        } else {
            add = () => {
                if (maxCount !== undefined && maxCount > 0 && chosen[index].chosenMenuItemModifiers.length >= maxCount) {
                    return;
                }
                if (amountOfAllChosenMenuItemModifiers(index) >=maxCount && maxCount !== 0){
                    return;
                }
                const newItem = modifierSet.data.modifiers.find(item => item.id === itemId);
                if (!newItem) return;
                newPrice += newItem.data.sellPrice.price;

                newChosen[index].chosenMenuItemModifiers.push({
                    menuItemModifierId: itemId,
                    count: 1
                });
                setPrice(newPrice);
                setChosen(newChosen);
            }
            remove = () => {
            }
        }

        return { add, remove };

    };

    const renderChooseMany = (set: MenuItemModifierSet, index: number) => {
        const data = set.data as MenuItemModifierSetChooseManyData;
        const maxCount = data.maxCountTotalChosen;
        const minCount = data.minCountTotalChosen;

        return <div key={set.id} className={styles.modifierSetBox}>
            <p className={styles.modifierSetTitle}>{set.data.name[locale]}</p>
            {maxCount > 0 && minCount === 0 && <p className={styles.modifierSetChooseManyMaxCountHint}>
				<Trans i18nKey="menu:modifiers.chooseManyNoMore" values={{ maxCount }}>
					Выберите не более {{maxCount}}
				</Trans>
			</p>}
            {minCount > 0 && maxCount === 0 && <p className={styles.modifierSetChooseManyMaxCountHint}>
				<Trans i18nKey="menu:modifiers.chooseManyNoLess" values={{ minCount }}>
					Выберите не менее {{minCount}}
				</Trans>
			</p>}
            {minCount > 0 && maxCount > 0 && <p className={styles.modifierSetChooseManyMaxCountHint}>
				<Trans i18nKey="menu:modifiers.chooseManyBetween" values={{ maxCount, minCount }}>
					Выберите от {{minCount}} до {{maxCount}}
				</Trans>
			</p>}
            <div className={styles.modifierSetSpace}/>

            {set.data.modifiers
                .filter(item => !item.data.isInStopList)
                .map((item) => {
                    const { add, remove } = getChooseManyFunctions(index, item.id);
                    let count = 0;
                    const selectedModifier = chosen[index].chosenMenuItemModifiers.find(d => d.menuItemModifierId === item.id) as ChosenMenuItemModifierChooseMany;
                    if (selectedModifier) {
                        count = selectedModifier.count;
                    }


                    return <div key={item.id} className={styles.checkboxButtonGroup}
                    >
                        {/* <input
                        type={'checkbox'}
                        checked={isChosen(index, item.id)}
                        value={item.id}
                        className={styles.checkboxInput}
                        id={`${item.id}_${index}`}
                        onChange={() => toggleChooseMany(index, item.id)}
                    />*/}
                        <label
                            htmlFor={`${item.id}_${index}`}
                            className={styles.checkboxLabel}
                        >
                            {item.data.name[locale]}
                            <span
                                className={styles.addPrice}>+{(item.data.sellPrice.price / 100).toFixed()} {t("general:currency.rub")}</span>
                        </label>
                        <PlusMinusWidgetModifiers
                            count={count}
                            add={add}
                            remove={remove}
                        />
                    </div>
                })}
        </div>
    };

    const renderChooseOne = (set: MenuItemModifierSet, index: number) => {

        const modifierArray = set.data.modifiers.map(item => item.id);
        const isModifierChosen = () => {
            let result: boolean = false;
            if (mandatoryChosenModifier.length === 0) result = false;
            for (let n = 0; n < mandatoryChosenModifier.length; n++) {
                if (modifierArray.includes(mandatoryChosenModifier[n])) {
                    result = true;
                    break;
                }
            }
            return result;
        }

        return <div key={set.id} className={styles.modifierSetBox}>
            <p className={styles.modifierSetTitle}>{set.data.name[locale]}</p>
            {
                set.data.type === ModifiersSetType.ChooseOne && set.data.isRequired &&
				<>
                    {(isDisabledButtonClick && !isModifierChosen()) ? <>
                            <p className={styles.modifierSetChooseManyMaxCountHint + " " + styles.modifierMsg}>
                                <Trans i18nKey="menu:modifiers.chooseOneIsRequired">
                                    Обязательная опция - необходимо выбрать один из вариантов
                                </Trans>
                            </p>
                        </>
                        :
                        <p className={styles.modifierSetChooseManyMaxCountHint}>
                            <Trans i18nKey="menu:modifiers.chooseOneIsRequired">
                                Обязательная опция - необходимо выбрать один из вариантов
                            </Trans>
                        </p>
                    }
				</>

            }
            {set.data.modifiers
                .filter(item => !item.data.isInStopList)
                .map((item) => {
                    return <div key={item.id} className={styles.radioButtonGroup}>
                        <input
                            type={'radio'}
                            checked={isChosen(index, item.id)}
                            value={item.id}
                            className={styles.radioInput}
                            id={`${item.id}_${index}`}
                            onChange={() => {
                                setChooseOne(index, item.id);
                                if (isModifierChosen()) setIsDisabledButtonClick(false);
                                mandatoryChosenModifier.push(item.id)
                            }}
                        />
                        <label
                            htmlFor={`${item.id}_${index}`}
                            className={styles.radioLabel}
                        >
                            {item.data.name[locale]}
                            <span
                                className={styles.addPrice}>+{(item.data.sellPrice.price / 100).toFixed()} {t("general:currency.rub")}</span>
                        </label>
                    </div>
                })}
        </div>
    };

    const isOkToAdd = () => {
        let ok = true;
        if (bookingCheckoutStore.chosenBookingSlots.length === 0) {
            ok = false;
        }
        if (counter > Math.min.apply(null, bookedAmount)){
            ok = false;
        }
        item.data.menuItemModifierSets.forEach((setDescriptor, index) => {
            const set = bookingMenuStore.getModifiersById(setDescriptor.menuItemModifierSetId);
            if (!set) return null;
            const t = chosen[index];
            if (set.data.type === ModifiersSetType.ChooseOne) {
                if (set.data.isRequired && t.chosenMenuItemModifiers.length === 0) {
                    ok = false;
                }
                /* ok = set.data.isRequired ? t.chosenMenuItemModifiers.length > 0 : true;*/
            } else if (set.data.type === ModifiersSetType.ChooseMany) {
                const maxCount = set.data.maxCountTotalChosen;
                const minCount = set.data.minCountTotalChosen;
                const count = t.chosenMenuItemModifiers.reduce((memo, d) => {
                    return memo + (d as ChosenMenuItemModifierChooseMany).count;
                }, 0)

                if (count < minCount || (maxCount > 0 && count > maxCount)) {
                    ok = false;
                }
            }
        })
        return ok;
    }

    const badges = getBookingBadges(item);


    const swippableHandlers = topScroll <= 0 ? handlers : {};
    const windowWidth = document.documentElement.offsetWidth;

    return <div className={classes.join(" ")}
                {...swippableHandlers}
                style={{
                    transform: `translateY(${windowWidth > 700 ? 30 : topOffset}px)`,
                    opacity,
                    left: horizontalOffset,
                    right: horizontalOffset,
                    bottom: windowWidth > 700 ? 20 : 0,
                }}
    >
        <div ref={containerRef}
             style={{ overflowY: 'scroll', height: '100%', paddingBottom: 120, boxSizing: 'border-box' }}>
            {bookingMenuStore.bookingMenu.data.themeSettings.storefrontPhotosMode === MenuItemsPhotosModes.defaultWithPhotos
                && <div className={styles.mainImg}>
                    {item.data.media.length > 0 ?
                        <LazyImage media={item.data.media} type={MediaType.regular} disableLazy={true}
                                   height={'auto'}/>
                        :
                        null
                    }
				</div>}
            <div className={styles.container}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        badges[BookingBadgeType.new].isEnabled &&
						<NewBadge/>
                    }
                    {
                        badges[BookingBadgeType.hit].isEnabled &&
						<HitBadge/>
                    }
                    {
                        badges[BookingBadgeType.profitable].isEnabled &&
						<ProfitableBadge/>
                    }
                </div>
                <p className={styles.title}>{item.data.name[locale]}</p>
                <p className={styles.description}>
                    {item.data.description[locale]}
                </p>
                <PaperBlock>
                    <div className={styles.buttonsContainer}>
                        {item.data.amount > 1 ?
                            <>
                                <div>
                                    <p>{t("menu:amountOfBooking")}</p>
                                    <SmallGreyText>{`${(price / 100).toFixed(0)}`} {t("general:currency.rub")} / {t("menu:bookingSlots.timeSlot")}</SmallGreyText>
                                </div>
                                <div className={styles.plusMinusWidgetContainer}>
                                    <MinusButton onClick={() => {
                                        if (counter === 1) return;
                                        setCounter(counter - 1)
                                    }}/>
                                    <span className={styles.counter}>{counter}</span>
                                    <PlusButton onClick={() => {
                                        if (bookedAmount.length > 0) {
                                            if (counter < (Math.min.apply(null, bookedAmount))) setCounter(counter + 1)
                                        }

                                    }}/>
                                </div>
                            </>
                            :
                            <SmallGreyText>{`${(price / 100).toFixed(0)}`} {t("general:currency.rub")} / {t("menu:bookingSlots.timeSlot")}</SmallGreyText>
                        }

                    </div>
                </PaperBlock>

                <BookingTimeSlots/>
                {item.data.menuItemModifierSets.map((setDescriptor, index) => {
                    const set = bookingMenuStore.getModifiersById(setDescriptor.menuItemModifierSetId);
                    if (!set) return null;
                    if (set.data.type === ModifiersSetType.ChooseMany) {
                        return renderChooseMany(set, index)
                    } else if (set.data.type === ModifiersSetType.ChooseOne) {
                        return renderChooseOne(set, index);
                    }
                    return null;
                })}
            </div>

            <div className={styles.chevronDown} onClick={() => {
                generalMenuStore.onOverlayClick && generalMenuStore.onOverlayClick();
            }}>
                <img src={chevronDown} alt=""/>
            </div>
            <div className={styles.addToCartContainer}>
                {/*<FlexRowSpaceBetween>
                <p className={styles.title}>{item.data.name[locale]}</p>
                <span className={styles.price}>{(price * counter / 100).toFixed(0)}
                    <span className={styles.currency}>{t('general:currency.rub')}</span>
                </span>
            </FlexRowSpaceBetween>*/}
                <div className={styles.buttonsContainer}>
                    <div className={styles.addToCartButtonContainer}>


                        <PrimaryButton title={
                            <Trans i18nKey='general:addMenuItem' values={
                                { price: `${(price * counter * bookingCheckoutStore.chosenBookingSlots.length / 100).toFixed(0)}` }
                            }/>

                        } onClick={() => {
                            if (isOkToAdd()) {
                                addToCart()
                            } else {
                                modifierMessage();
                            }
                        }} disabled={!isOkToAdd()}/>
                    </div>

                </div>
            </div>
        </div>

    </div>
};


export default observer(ModifiersModal);
