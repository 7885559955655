import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../hooks";
import { MenuType } from "../services/menu/models";
import CartPage from "./cart";
import BookingCartPage from "./bookingCart";
import RestifyLoader from "./restifyLoader";
import CartDesktopPage from "./cartDesktop";
import BookingCartDesktopPage from "./bookingCartDesktop";


const MenuTypeCartWrapper = () => {

    const { generalMenuStore, generalStore, bookingMenuStore, menuStore, isInited } = useStores();
    useEffect(() => {

        if(!isInited) return;
        if (generalMenuStore.type !== MenuType.none) return;
        (async () => {
            await generalMenuStore.getMenuTypeByMenuId(
                generalStore.menuId,
                generalStore.organizationId,
                generalStore.organizationBranchId
            )
            if (generalMenuStore.type === MenuType.booking) {
                await bookingMenuStore.init({
                        bookingMenuId: generalStore.menuId,
                        organizationId: generalStore.organizationId,
                        organizationBranchId: generalStore.organizationBranchId
                    }
                )
                generalMenuStore.setMenu(bookingMenuStore.bookingMenu)
            } else {
                await menuStore.init({
                        menuId: generalStore.menuId,
                        organizationId: generalStore.organizationId,
                        organizationBranchId: generalStore.organizationBranchId
                    }
                )
                generalMenuStore.setMenu(menuStore.menu);
            }
        })();

    }, [generalMenuStore.type, isInited]);

    return <>
        <RestifyLoader
            show={!generalMenuStore.menu}
        />
        {(generalMenuStore.type === MenuType.regular && window.screen.width < 700) &&
            <CartPage/>
        }
        {(generalMenuStore.type === MenuType.regular && window.screen.width >= 700) &&
			<CartDesktopPage/>
        }
        {(generalMenuStore.type === MenuType.booking && window.screen.width < 700) &&
			<BookingCartPage/>
        }
        {(generalMenuStore.type === MenuType.booking && window.screen.width >= 700) &&
            <BookingCartDesktopPage/>
        }
    </>
}

export default observer(MenuTypeCartWrapper)
