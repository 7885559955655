import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import { useStores } from "../../../../hooks";
import CategoryContentWithPhoto from "./menuItemsWithPhoto/CategoryContentWithPhoto";
import CategoryContentNoPhoto from "./menuItemsNoPhoto/CategoryContentNoPhoto";
import {Trans, useTranslation} from "react-i18next";

import styles from "./style.module.sass";
import style from "../categories/style.module.sass";
import { MenuItemsPhotosModes } from "../../../../services/menu/models";
import SideMenu from "./components/SideMenu";

const MenuItemsList = () => {
    const {bookingMenuStore, generalMenuStore} = useStores();
    const categories = bookingMenuStore.bookingCategories;
    const [categoryData, setCategoryData] = useState<{[s: number]: {visible: boolean, offset: number}}>({});
    const [categoriesHeaderRefs, setCategoriesHeaderRefs] = useState<{ [s: string]: any }>({});
    const {t, i18n} = useTranslation(['menu']);

    useEffect(() => {
        setCategoriesHeaderRefs(bookingMenuStore.bookingCategories.reduce((acc, item) => {
            acc[item.id] = React.createRef();
            return acc;
        }, {} as { [s: string]: any }))
    }, [bookingMenuStore.bookingCategories]);

    useEffect(() => {
        const ref =  categoriesHeaderRefs[bookingMenuStore.activeBookingCategory];

        if(ref && ref.current && generalMenuStore.scroll) {
            window.scrollTo({left: 0, top: ref.current.offsetTop + 40, behavior: 'smooth'});
            setTimeout(() => {
                generalMenuStore.resetScroll();
            }, 600)
        }
    }, [bookingMenuStore.activeBookingCategory]);




    useEffect(() => {
        if(generalMenuStore.scroll) return;

        const visibles = Object.entries(categoryData).filter(([key, item]) => item.visible).map(([key, item]) => {
            return {
                id: parseInt(key),
                ...item
            }
        });

        if(visibles.length === 1){
            if(bookingMenuStore.activeBookingCategory !== visibles[0].id){
                const category = bookingMenuStore.bookingCategories.find(item => item.id === visibles[0].id);
                bookingMenuStore.setActiveCategory(visibles[0].id, false);
            }
        }else if(visibles.length > 0){
            const sorted = visibles.sort((a, b)=> Math.abs(a.offset) - Math.abs(b.offset));
            if(bookingMenuStore.activeBookingCategory !== sorted[0].id){
                const category = bookingMenuStore.bookingCategories.find(item => item.id === sorted[0].id);
                bookingMenuStore.setActiveCategory(sorted[0].id, false);
            }
        }else{
            //console.log(visibles);
        }

    }, [categoryData]);


    return <div className={styles.container}>
        <SideMenu/>
        <div>
            {categories.map(item => {
                if (bookingMenuStore.bookingMenu.data.themeSettings.storefrontPhotosMode === MenuItemsPhotosModes.defaultNoPhotos) {
                    return <CategoryContentNoPhoto
                        key={item.id}
                        categoryId={item.id}
                        ref={categoriesHeaderRefs[item.id]}
                        setIsVisible={(categoryId: number, isVisible: boolean, offset: number) => {
                            const data = categoryData[categoryId] || {
                                visible: false, offset: 10000
                            };
                            data.visible = isVisible;
                            data.offset = isVisible ? offset : 10000;
                            setCategoryData({
                                ...categoryData,
                                [categoryId]: data
                            });

                        }}
                    />
                } else {
                    return <CategoryContentWithPhoto
                        key={item.id}
                        categoryId={item.id}
                        ref={categoriesHeaderRefs[item.id]}
                        setIsVisible={(categoryId: number, isVisible: boolean, offset: number) => {
                            const data = categoryData[categoryId] || {
                                visible: false, offset: 10000
                            };
                            data.visible = isVisible;
                            data.offset = isVisible ? offset : 10000;
                            setCategoryData({
                                ...categoryData,
                                [categoryId]: data
                            });

                        }}
                    />
                }

            })}
        </div>
    </div>
};



export default observer(MenuItemsList);
