import { useStores } from "hooks";
import { observer } from "mobx-react";
import style from "../style.module.sass";
import timeIcon from "../assets/time.svg";
import { useState } from "react";
import { WorkingScheduleDay } from "services/menu/models";
import { useTranslation } from "react-i18next";


const WorkingSchedule = () => {

    const { menuStore } = useStores();
    const [open, setIsOpen] = useState(true);
    const { t } = useTranslation(["contactDetails"]);

    const classes = [style.showSchedule];
    if (open) {
        classes.push(style.hideSchedule)
    }

    const setHourSlot = (number: number) => {

        let res;
        if (number < 10) res = "0" + number
        else res = number
        return res
    }
    const setMinutesSlot = (number: number) => {

        let res;
        if (number < 10) res = "0" + number
        else res = number
        return res
    }

    const setSchedule = (scheduleArray: WorkingScheduleDay) => {
        return (
            <>
                {scheduleArray.slots.map(item => {
                    return (
                        <div key={`${item.fromHour}_${item.toHour}_${Date.now()}`}>
                            {setHourSlot(item.fromHour)}:{setMinutesSlot(item.fromMinute)} - {setHourSlot(item.toHour)}:{setMinutesSlot(item.toMinute)}
                        </div>
                    )
                })}
            </>
        )
    }


    return (
        <div className={classes.join(" ")}
             onClick={() => {
                 setIsOpen(!open)
             }}
        >
            {menuStore.menu.data.workingSchedule.monday.slots.length > 0 &&
            <div className={style.mondaySchedule}>
                <div className={style.schedule}>
                    <div className={style.timeIcon}>
                        <img src={timeIcon} alt=""/>
                    </div>
                    {(menuStore.menu.data.workingSchedule.monday.slots.length > 0 && !menuStore.menu.data.workingSchedule.monday.isDayOff) &&
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:monday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.monday)}
                        </>
                    </p>
                    }
                    {menuStore.menu.data.workingSchedule.monday.isDayOff &&
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:monday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                    }
                </div>
                <svg className={style.arrow} xmlns="http://www.w3.org/2000/svg" width="43.837" height="83.159"
                     viewBox="0 0 43.837 83.159">
                    <g id="Group_94" data-name="Group 94" transform="translate(-410.63 -74.425)">
                        <g id="Group_93" data-name="Group 93" transform="translate(116 6)">
                            <rect id="Rectangle_113" data-name="Rectangle 113" width="11.014" height="55.072" rx="5.507"
                                  transform="matrix(-0.766, 0.643, -0.643, -0.766, 338.467, 144.504)"
                                  fill="rgb(143, 143, 143)"/>
                            <rect id="Rectangle_114" data-name="Rectangle 114" width="11.015" height="55.072" rx="5.507"
                                  transform="translate(330.03 68.425) rotate(40)" fill="rgb(143, 143, 143)"/>
                        </g>
                    </g>
                </svg>
            </div>
            }
            <div className={style.hidingPartOfSchedule}>
                {(menuStore.menu.data.workingSchedule.tuesday.slots.length > 0 && !menuStore.menu.data.workingSchedule.tuesday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:tuesday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.tuesday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.tuesday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:tuesday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
                {(menuStore.menu.data.workingSchedule.wednesday.slots.length > 0 && !menuStore.menu.data.workingSchedule.wednesday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:wednesday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.wednesday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.wednesday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:wednesday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
                {(menuStore.menu.data.workingSchedule.thursday.slots.length > 0 && !menuStore.menu.data.workingSchedule.thursday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:thursday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.thursday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.thursday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:thursday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
                {(menuStore.menu.data.workingSchedule.friday.slots.length > 0 && !menuStore.menu.data.workingSchedule.friday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:friday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.friday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.friday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:friday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
                {(menuStore.menu.data.workingSchedule.saturday.slots.length > 0 && !menuStore.menu.data.workingSchedule.saturday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:saturday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.saturday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.saturday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:saturday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
                {(menuStore.menu.data.workingSchedule.sunday.slots.length > 0 && !menuStore.menu.data.workingSchedule.sunday.isDayOff) &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:sunday')}</span>
                        <>
                            {setSchedule(menuStore.menu.data.workingSchedule.sunday)}
                        </>
                    </p>
                </div>
                }
                {menuStore.menu.data.workingSchedule.sunday.isDayOff &&
                <div className={style.schedule}>
                    <p className={style.dayTime}>
                        <span>{t('contactDetails:sunday')}</span>
                        {t('contactDetails:nonworking')}
                    </p>
                </div>
                }
            </div>
        </div>

    )

}

export default observer(WorkingSchedule);
