import BackButton from "../../components/buttons/backButton";
import style from "./style.module.sass"
import {useStores} from "../../hooks";
import {DeliveryType, MenuType} from "../../services/menu/models";
import usePayments from "../../hooks/payments/usePayments";
import {FadeInOutVertical} from "../../components/Animated";
import {useHistory, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Loader} from "../../components/loader";
import {Trans, useTranslation} from "react-i18next";
import Overlay from "../menu/components/overlay";
import styles from "../menu/components/modifiersModal/style.module.sass";
import OrderingNotAvailable from "../orderingNotAvailable";
import {Spinner} from "../../components/spinner";
import {PaymentProviderType} from "../../services/order/models";


const PayPreorderPage = () => {

    const { cartStore, menuStore, checkoutStore, generalStore, ordersStore, generalMenuStore } = useStores();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const currentOrder = ordersStore.currentOrder;
    const currentMenu = generalMenuStore.menu;


    const { i18n, t } = useTranslation(["general", "cart", 'viewOrder']);
    const locale = i18n.language;
    const params = useParams<{
        menuId: string,
        orgId: string,
        branchId: string,
        preorderId: string,

    }>()

    const payments = usePayments();

    useEffect(() => {
        let options = {
            organizationId: parseInt(params.orgId),
            organizationBranchId: parseInt(params.branchId),
            menuId: parseInt(params.menuId),
            tableId: "",
            pointId: "",
            viewOnly: false,
            isWidget: false
        };
        if (
            isNaN(options.menuId) ||
            isNaN(options.organizationBranchId) ||
            isNaN(options.organizationId)
        ) {
            menuStore.setError("Не найдено меню");
            return;
        }

        generalStore.init(options);
        menuStore.init({
            organizationId: options.organizationId,
            organizationBranchId: options.organizationBranchId,
            menuId: options.menuId,
        }).then(() => setIsReady(true));

    }, [])

    useEffect(() => {
        (async () => {
            if (!currentMenu) return;
            if (!currentOrder || currentOrder.id !== parseInt(params.preorderId)) {
                let order;
                if (currentMenu.type === MenuType.regular) {
                    await ordersStore.setCurrentOrder(
                        parseInt(params.preorderId),
                        parseInt(params.orgId),
                        parseInt(params.branchId),
                        MenuType.regular
                    );
                    order = ordersStore.currentOrder;
                } else {
                    await ordersStore.setCurrentOrder(
                        parseInt(params.preorderId),
                        parseInt(params.orgId),
                        parseInt(params.branchId),
                        MenuType.booking
                    );
                    order = ordersStore.currentBookingOrder;
                }

                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: false,
                        isWidget: false
                    })
                }
            }
        })()
    }, [currentOrder, ordersStore, params]);



    const reset = () => {
        checkoutStore.reset();
        cartStore.reset();
        ordersStore.forceReload();
    }


    if (!generalStore.isAcceptingOrders) {
        return <OrderingNotAvailable/>
    }
    if(currentOrder?.status === "Paid"){
        history.push(`/order-created?orderId=${currentOrder.id}`);
    }





    const deliverySettings = menuStore.menu.data.deliverySettings;
    const paymentSettings = menuStore.menu.data.paymentSettings;


    const onSuccess = () => {
        const orderId = currentOrder?.id;
        reset();
        history.push(`/order-created?orderId=${orderId}`);
    };
    const onFail = () => {
        console.log("Ошибка")
    };

    if (!currentOrder) {
        return <div style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: 500
        }}>
            <Loader/>
        </div>
    }

    return <div className={style.container}>
        <div className={style.header}>
            <BackButton/>
            <p className={style.text}>{t("cart:title")}</p>
        </div>
        <FadeInOutVertical>
            <div className={style.paperBlock}>
                {currentOrder.items.map(({ menuItem, chosenMenuItemModifierSets, menuItemsCount, price }, index) => {
                    return <div key={`${menuItem.id}_${index}`} className={style.menuItemBox}>
                        <div className={style.item}>
                            <div>
                                <p className={style.itemTitle}>
                                    {menuItem.data.name[locale]}
                                </p>
                                <p className={style.itemInfo}>
                                    {menuItemsCount} {t("general:units.pieces")} x {(price.price / 100).toFixed(0)} {t("general:currency.rub")}
                                </p>
                            </div>
                            <p className={style.itemPrice}>
                                {(price.price / 100 * menuItemsCount).toFixed(0)}
                                <span className={style.currency}>
                                    {t("general:currency.rub")}
                                </span>
                            </p>
                        </div>
                        {chosenMenuItemModifierSets.map((chosenModifierSet) => {

                            return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {

                                const modifier = chosenModifierSet.data.modifiers.find(
                                    item => item.id === chosenModifier.menuItemModifierId);
                                if (!modifier) return null;
                                return <p className={style.itemModifier} key={chosenModifier.menuItemModifierId}>
                                    {modifier.data.name[locale]} x {
                                    //@ts-ignore
                                    chosenModifier?.count || 1
                                }
                                </p>
                            });

                        })}
                    </div>
                })}

                <div className={style.menuItemBox}
                     style={{ marginBottom: 0, borderTop: "1px solid #FFA741", paddingTop: 20, paddingBottom: 10 }}>
                    <div className={style.item}>
                        <div>
                            <p className={style.itemTitle}>
                                {t("viewOrder:total")}
                            </p>
                        </div>
                        <p className={style.itemPrice}>
                            {(currentOrder.totalPrice.price / 100).toFixed(0)}
                            <span className={style.currency}>{t('general:currency.rub')}</span>
                        </p>
                    </div>
                </div>
            </div>
        </FadeInOutVertical>

        <FadeInOutVertical>
            <div className={style.paperBlock}>
                <p className={style.deliverySettingsTitle}>
                    {t("cart:chooseDeliveryType")}
                </p>
                {Object.entries(deliverySettings).filter(([key, value]) => {
                    return value.isEnabled
                }).map(([key, value]) => {
                    return <div key={value.type} className={styles.radioButtonGroup}>
                        <input
                            type={'radio'}
                            checked={currentOrder?.deliveryDetails.type === value.type}
                            value={key}
                            className={styles.radioInput}
                            id={key}
                            disabled={true}
                        />
                        <label
                            htmlFor={key}
                            className={styles.radioLabel}
                        >

                            {
                                //@ts-ignore
                                t(`cart:deliveryType.${value.type}`)
                            }

                        </label>
                    </div>
                })}
            </div>
        </FadeInOutVertical>
        {currentOrder.deliveryDetails?.type === DeliveryType.toTable && ordersStore.table && <FadeInOutVertical>
            <div className={style.paperBlock}>
                <p className={style.deliverySettingsTitle}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{t("cart:deliveryToTable.table")}</span>
                    </div>
                </p>
                <div>
                    <input
                        disabled={true}
                        className={style.deliverySettingsAddressInput}
                        value={ordersStore.table.data.title[locale]}
                    />
                </div>
            </div>
        </FadeInOutVertical>}
        {
            currentOrder.deliveryDetails?.type === DeliveryType.toAddress && <FadeInOutVertical>
                <div className={style.paperBlock}>
                    <p className={style.deliverySettingsTitle}>
                        {t("cart:deliveryToAddressLabels.whereToDeliver")}
                    </p>
                    <div>
                        <input
                            className={style.deliverySettingsAddressInput}
                            value={currentOrder.deliveryDetails.address}
                            disabled={true}
                        />
                    </div>
                    <br/>
                    <p className={style.deliverySettingsTitle}>
                        {t("cart:deliveryToAddressLabels.contact")}
                    </p>
                    <div>
                        <input
                            className={style.deliverySettingsAddressInput}
                            value={currentOrder.deliveryDetails.phoneNumber}
                            disabled={true}
                        />
                    </div>
                    {/* {
                        deliverySettings[DeliveryType.toTable].isEnabled &&
                        <div className={[style.button, style.inverse].join(" ")} onClick={() => {
                            setDeliveryType(DeliveryType.toTable)
                        }}>
                            {t("cart:actions.deliverToTable")}
                        </div>
                    }*/}
                </div>
            </FadeInOutVertical>
        }

        <div className={style.buttonContainer}>
            <div className={[style.button].join(" ")} onClick={async () => {
                if (isLoading) return false;
                setIsLoading(true);

                let paymentProvider = paymentSettings[PaymentProviderType.cloudpayments]?.isEnabled
                    ? PaymentProviderType.cloudpayments
                    : PaymentProviderType.offline;

                paymentProvider = paymentSettings[PaymentProviderType.sberbank]?.isEnabled
                    ? PaymentProviderType.sberbank
                    : paymentProvider;


                if (currentOrder && paymentProvider === PaymentProviderType.cloudpayments) {
                    payments.initCharge(paymentProvider, currentOrder, {
                        onSuccess, onFail
                    });
                }else if (currentOrder && paymentProvider === PaymentProviderType.sberbank) {
                    payments.initCharge(paymentProvider, currentOrder, {
                        onSuccess, onFail
                    });
                } else {
                    onSuccess();
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 4000);
            }}>
                {isLoading ?
                    <Spinner/>
                    :
                    paymentSettings[PaymentProviderType.offline].isEnabled ? t("cart:confirmOrder") : t("cart:pay")
                }
            </div>
            {
                menuStore.menu.docs.menuPublicOfferUrl && <p className={style.offertaBlock}>
                    <Trans i18nKey="cart:ofertaText">
                        Отправляя заказ, <br></br> вы соглашаетесь с <a href={menuStore.menu.docs.menuPublicOfferUrl}>
                        офертой</a>
                    </Trans>

                </p>
            }
        </div>
        <Overlay/>
    </div>
};


export default observer(PayPreorderPage);
