import {observer} from "mobx-react";
import React from "react"
import styles from "./style.module.sass"
import {FlexRowSpaceBetween} from "../../../../../components/containers/containers";
import PriceComponent from "../../../../../components/priceComponent";
import {PlusGreyButton} from "../../../../../components/buttons/plusButton";
import {useStores} from "../../../../../hooks";
import {BookingBadgeType, MenuBookingItem, MenuItem, MenuType} from "../../../../../services/menu/models";
import {useTranslation} from "react-i18next";
import PlusMinusWidget from "../../../../../components/plusMinusWidget";
import NewBadge from "../components/badges/NewBadge";
import ProfitableBadge from "../components/badges/ProfitableBadge";
import {getBadges, getBookingBadges} from "../libs";
import {useHistory} from "react-router";

interface IProps {
    item: MenuBookingItem,
}


const MenuItemNoPhotoComponent = ({ item }: IProps) => {

    const { cartStore, menuStore, generalStore } = useStores();
    const isInCart = cartStore.isInCart(item.id, MenuType.booking);
    const { i18n, t } = useTranslation();
    const locale = i18n.language;
    const history = useHistory();


    const badges = getBookingBadges(item);

    const onPlusClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (generalStore.alias) {
            history.push(`/${generalStore.alias}/category/${item.data.menuBookingCategoryId}/item/${item.id}`)
        } else {
            history.push(`/menu/category/${item.data.menuBookingCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
        }
    }

    const showModalWindow = () => {
        if (generalStore.alias) {
            history.push(`/${generalStore.alias}/category/${item.data.menuBookingCategoryId}/item/${item.id}`)
        } else {
            history.push(`/menu/category/${item.data.menuBookingCategoryId}/item/${item.id}?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}`)
        }
        /*menuStore.openModifiersPopup(item, () => {
            menuStore.closeModifiersPopup();
            history.goBack();
        });*/
    };

    let price: number;
    if (item.data.sellPrice.price === 0) {
        price = menuStore.getMinPrice(item);
    } else {
        price = item.data.sellPrice.price;
    }

    return <div className={styles.menuItemContainer} onClick={showModalWindow}>
        <div className={styles.content}>
            {
                badges[BookingBadgeType.new].isEnabled &&
                <NewBadge/>
            }
            {
                badges[BookingBadgeType.profitable].isEnabled &&
                <ProfitableBadge/>
            }
            <p className={styles.title}>{item.data.name[locale]}</p>
            {(item.data.description[locale] || "").length > 0 && <p className={styles.description}>
                {item.data.description[locale]}
            </p>
            }
        </div>
        <div style={{
            padding: "0 5px",
            marginTop: 5,
            minWidth: "100%",
            boxSizing: "border-box"
        }}>
            {
                !isInCart ? <FlexRowSpaceBetween>
                        <PriceComponent
                            item={{
                                price: price,
                                currencyId: item.data.sellPrice.currencyId
                            }}
                        />
                        <PlusGreyButton onClick={onPlusClick}/>
                    </FlexRowSpaceBetween>
                    :
                    <FlexRowSpaceBetween>
                        <PriceComponent item={{
                            price: price,
                            currencyId: item.data.sellPrice.currencyId
                        }}/>
                        <PlusMinusWidget menuItem={item} index={0} onCartPage={false}/>
                    </FlexRowSpaceBetween>
            }

        </div>
    </div>
};

export default observer(MenuItemNoPhotoComponent);
