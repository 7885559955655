import { execute } from "../../apiCommandsExecutor/api";
import { MenuApiMethods } from "./methods";
import {
    BookingMenusGetPublicBookingMenuRequest, BookingMenusGetPublicBookingMenuResponse,
    IGetPublicMenuRequest,
    IGetPublicMenuResponse, MenusGetPublicMenuInfoRequest, MenusGetPublicMenuInfoResponse,
    MenusReportCallWaiterRequest,
    MenusReportCallWaiterResponse
} from "./requestsResponses";



class MenuService {

    static async getMenu(params: {menuId: number, organizationId: number, organizationBranchId: number}){
        const res = await execute<IGetPublicMenuRequest, IGetPublicMenuResponse>(
            MenuApiMethods.getMenu,
            params);
        return res.data;
    };

    static async getBookingMenu(params: {bookingMenuId: number, organizationId: number, organizationBranchId: number}){
        const res = await execute<BookingMenusGetPublicBookingMenuRequest, BookingMenusGetPublicBookingMenuResponse>(
            MenuApiMethods.bookingMenusGetBookingMenu,
            params);
        return res.data;
    };

    static async callWaiter(params: MenusReportCallWaiterRequest){
        const res = await execute<MenusReportCallWaiterRequest, MenusReportCallWaiterResponse>(
            MenuApiMethods.menusReportCallWaiter,
            params);
        return res.data;
    };

    static async getMenuTypeByMenuId(params: {menuId: number, organizationId: number, organizationBranchId: number}){
        const res = await execute<MenusGetPublicMenuInfoRequest, MenusGetPublicMenuInfoResponse>(
            MenuApiMethods.getPublicMenuInfo,
            params);
        return res.data;
    };
}

export default MenuService;
