
export interface Token {
    authToken: string;
    authTokenExpiresAt: Date;
    refreshToken: string;
    refreshTokenExpiresAt: Date;
}

export const getToken =  () => {
    let tokenObj = localStorage.getItem("token");

    if(tokenObj){
        return (JSON.parse(tokenObj) as Token).authToken;
    }else{
        return ""
    }
};

export const saveTokenPair = (token: Token) => {
    if(!token) return;
    localStorage.setItem("token", JSON.stringify(token));
};


export const removeTokenPair = () => {
    localStorage.removeItem("token");
};

