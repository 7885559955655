export enum OrdersApiMethods {
    createOrder = "Orders.CreateOrderPublic",
    getOrder = "Orders.GetOrderPublic",
    getOrdersByTableId = 'Orders.GetOrdersPublic',
    couponsGetCouponPublic = "Coupons.GetCouponPublic",
    geoCodingGetAddressSuggestionsPublic ="GeoCoding.GetAddressSuggestionsPublic",
    bookingOrdersCancelBookingOrderPayment = "BookingOrders.CancelBookingOrderPayment",
    bookingOrdersCreateBookingOrderPublic = "BookingOrders.CreateBookingOrderPublic",
    bookingOrdersGetBookingOrderPublic = "BookingOrders.GetBookingOrderPublic",
    bookingOrdersGetBookingOrders = "BookingOrders.GetBookingOrders",
    bookingsDeleteBooking = "Bookings.DeleteBooking",
    bookingsGetBooking = "Bookings.GetBooking",
    bookingsGetBookings = "Bookings.GetBookings",
    bookingsGetPublicMenuItemBookings = "Bookings.GetPublicMenuItemBookings",
    getDeliveryPriceFromZone = "DeliveryZones.GetDeliveryPriceFromZone"
}
