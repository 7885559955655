export const Space = (props: { size: number }) => {
    return <div style={{ minHeight: props.size * 4 }}/>
};

export const toRubbles = (x: number) => {
    const hasFractionDigits = x && x % 100 > 0
    if (hasFractionDigits) {
        return (x || 0) / 100
    } else {
        return parseInt(((x || 0) / 100).toFixed(0));
    }
};


export const numberWithSpaces = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const generateClientSessionID = (len: number) => {
    let str = "";
    const min = 0, max = 62;
    for (let i = 0; i++ < len;) {
        let r = Math.random() * (max - min) + min << 0;
        str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
    }
    return str;
};

export const changeEventHandlerDecorator = (fn: (value: any) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        fn(e.target.value);
    };
};



export const formatText = (text: string) => {
    return text.replaceAll("\n", "<br/>")
}
