import { execute } from "../../apiCommandsExecutor/api";
import {
    IGetLegalInfoRequest, IGetLegalInfoResponse,
    IGetPaymentProviderDetailsRequest,
    IGetPaymentProviderDetailsResponse,
    StatsAddEventRequest, StatsAddEventResponse
} from "./requestsResponses";
import { GeneralApiMethods } from "./methods";
import { NewEvent } from "./models";


class GeneralService {
    static async getPaymentProvider(params: { organizationId: number, organizationBranchId: number }) {
        const res = await execute<IGetPaymentProviderDetailsRequest, IGetPaymentProviderDetailsResponse>(
            GeneralApiMethods.getPaymentProviderDetails,
            { ...params });
        return res.data;
    }

    static async getLegalInfo(params: { organizationId: number, organizationBranchId: number }) {
        const res = await execute<IGetLegalInfoRequest, IGetLegalInfoResponse>(
            GeneralApiMethods.getLegalInfo,
            { ...params });
        return res.data.legalDetails;
    }

    static async addNewEvent(params: { newEvent: NewEvent }) {
        const res = await execute<StatsAddEventRequest, StatsAddEventResponse>(
            GeneralApiMethods.StatsAddEvent, params);
        return res.data;
    }
}

export default GeneralService;
