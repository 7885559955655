import style from "./style.module.sass"
import React from "react"
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks";

const Overlay = () => {
    const {generalMenuStore} = useStores();
    const isCartModalVisible = generalMenuStore.isCartModalVisible;
    const classes = [style.overlay];

    if(generalMenuStore.isOverlayOpen){
        classes.push(style.open)
    }


    const closeOverlay = () => {
        if (isCartModalVisible) {
            generalMenuStore.closeCartModal()
        } else {
            if (generalMenuStore.onOverlayClick) generalMenuStore.onOverlayClick()
        }
    }
    return <div className={classes.join(" ")} onClick={closeOverlay}/>
};

export default observer(Overlay);
