import style from "./style.module.sass";
import React, {PropsWithChildren, ReactNode} from "react";

interface Props {
    children: ReactNode;
}

const TitleList: React.FC<Props> = (props) => {

    return <p className={style.text}>{props.children}</p>
}

export default TitleList;