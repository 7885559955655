import style from "./style.module.sass"

export const Loader = (props: { size?: number } = { size: 80 }) => {
    const size = props.size;
    return <div className={style.loader} style={{
        width: size,
        height: size
    }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
};



