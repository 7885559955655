import { observer } from "mobx-react";
import { useStores } from "../../../../hooks";
import CatalogItem from "../catalogItem";
import style from "./style.module.sass"



const CatalogItems = () => {
    const {catalogStore} = useStores();
    const items = catalogStore.aliasBinding.data.aliasedMenus;
    return <div className={style.catalogItems}>
        {items.map(item => {
            const key = `${item.menuId}_${item.organizationId}`;
            return <CatalogItem key = {key}
                                item={item}/>
        })}
    </div>
}

export default observer(CatalogItems);
