import React from 'react';
import BoundaryText from "./BoundaryText";

interface State{
    error: null | string,
    errorInfo: null | string
}

interface Props{

}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return <BoundaryText/>
        }
        // Normally, just render children
        return this.props.children;
    }
}
