import React, { Ref, RefObject, useEffect, useRef, useState } from "react";
import style from "./style.module.sass"
import { useStores } from "../../../../hooks";
import ActiveCategoryHover from "./components/activeCategoryHover";
import { observer } from "mobx-react";
import { MenuCategory } from "../../../../services/menu/models";
import { useTranslation } from "react-i18next";


interface ITabProps {
    item: MenuCategory,
    ref: RefObject<HTMLDivElement>,
    startAnimating: () => void;
    animating: boolean,
    active: boolean,
    setActiveCategory: (id: number,) => void;
    scrollTo: (left: number) => void;
    currentScrollX: number
}

const Tab = React.forwardRef(
    (
        (props: ITabProps, ref: Ref<HTMLDivElement>) => {
            const {i18n} = useTranslation()
            const { item, active, startAnimating, setActiveCategory } = props;
            const classes = [style.item];
            if (active) {
                classes.push(style.active)
            }

            useEffect(() => {
                //@ts-ignore
                if (active && ref && ref.current) {
                    //console.log("Scrolling into view");
                    //@ts-ignore
                    const { x } = ref.current.getBoundingClientRect();

                    props.scrollTo(props.currentScrollX + x - 70);
                }
            }, [active, ref]);

            return  <div
                    className={classes.join(" ")}
                    ref={ref}
                    onClick={() => {
                        setActiveCategory(item.id);
                    }}
                >
                    {item.data.name[i18n.language]}
                </div>
        })
);

const CategoriesTabs = () => {
    const [animating, setAnimating] = React.useState(false);
    const { menuStore, generalMenuStore } = useStores();
    const tabRefs = menuStore.categories.reduce((acc, item) => {
        acc[item.id] = React.createRef();
        return acc;
    }, {} as { [s: string]: any });

    const categoriesColor = menuStore.menu?.data?.themeSettings?.categoriesBackgroundColor || '#1a1a1a'


    const tabsRef = useRef<HTMLDivElement>(null);

    const scrollTo = (left: number) => {
        if(tabsRef && tabsRef.current){
            tabsRef!.current!.scrollTo({left: left, behavior: "smooth"})
        }
    };

    const showCategoryMenu = () => {
        generalMenuStore.openCategoryMenu( () => {
            generalMenuStore.closeCategoryMenu()
        });
    };

    return <div className={style.burgerCategories}>
        <div className={style.burger} onClick = {showCategoryMenu}>
            <span style={{ background: categoriesColor }}></span>
            <span style={{ background: categoriesColor }}></span>
            <span style={{ background: categoriesColor }}></span>
        </div>
        <div ref={tabsRef} className={style.categoriesContainer}>
            {menuStore.categories.map((item, index) => {
                return <Tab
                    key={item.id}
                    item={item}
                    ref={tabRefs[item.id]}
                    startAnimating={() => setAnimating(true)}
                    animating={animating}
                    active={item.id === menuStore.activeCategory}
                    setActiveCategory={(id: number) => {
                        menuStore.setActiveCategory(id, true);
                    }}
                    scrollTo={scrollTo}
                    currentScrollX={tabsRef?.current?.scrollLeft || 0}
                />
            })}
            <div style={{minWidth: 20}}/>
            <ActiveCategoryHover
                refs={tabRefs}
                activeCategoryId={menuStore.activeCategory}
                finishAnimating={() => setAnimating(false)}
                animating={animating}
            />
        </div>



    </div>
};

export default observer(CategoriesTabs);
