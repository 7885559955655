import {observer} from "mobx-react";
import style from "./style.module.sass"
import {useStores} from "../../hooks";
import {FadeInOutVertical} from "../../components/Animated";
import {useHistory, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import moment from "moment"
import receiptIcon from "./assets/bill.svg"
import {
    BookingItemType,
    PaymentProviderType
} from "../../services/order/models";
import {MediaType, Menu, MenuBookingItem, MenuItemsPhotosModes, MenuType} from "../../services/menu/models";
import {Trans, useTranslation} from "react-i18next";
import {MenuMode} from "../../store/general";
import {Space} from "../../components/libs";

import OrderStatusBlock from "./components/orderStatusBlock";
import BackButton from "components/buttons/backButton";
import TitleList from "../../components/titles/titleList";
import {SimpleGreyBackgroundContainer} from "../../components/containers/simpleGreyBackgroundContainer";
import {PaperBlock} from "../../components/paperBlock";
import SmallGreyTitle from "../../components/titles/SmallGreyTitle";
import RestifyLoader from "../restifyLoader";
import RestifyFooter from "../../components/restifyFooter";
import LazyImage from "../../components/imageLazyLoader";
import noImage from "../bookingCart/assets/noImageProduct.png";

const ViewBookingOrderPage = () => {
    const {ordersStore, generalStore, generalMenuStore, catalogStore, bookingMenuStore} = useStores();
    const history = useHistory();
    const params = useParams<{ orderId: string, orgId: string, branchId: string, menuId: string }>();
    const currentOrder = ordersStore.currentBookingOrder;
    const {t, i18n} = useTranslation(['viewOrder', "general", "menu"])
    const locale = i18n.language;
    const [timer, setTimer] = useState<any>(null)
    let bookingMenu: Menu = bookingMenuStore.bookingMenu;

    document.body.style.overflowY = 'scroll';

    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    }
    const reloadOrder = (timeout: number) => {
        const t = setTimeout(async () => {
            await ordersStore.reloadCurrentOrder(MenuType.booking);
            if (ordersStore.currentOrder &&
                ordersStore.currentOrder.paymentDetails?.type === PaymentProviderType.cloudpayments &&
                //@ts-ignore
                !ordersStore.currentOrder.paymentDetails?.receiptLink) {
                reloadOrder(timeout)
            }
        }, timeout);
        setTimer(t);
    }

    useEffect(() => {
        (async () => {
            if (!bookingMenu) {
                await bookingMenuStore.init({
                    bookingMenuId: parseInt(params.menuId),
                    organizationId: parseInt(params.orgId),
                    organizationBranchId: parseInt(params.branchId)
                })
                generalMenuStore.setMenu(bookingMenuStore.bookingMenu)
            }
        })();

    }, [params, bookingMenu, ordersStore.currentBookingOrder]);

    useEffect(() => {
        (async () => {
            if (!currentOrder || currentOrder.id !== parseInt(params.orderId)) {
                //await ordersStore.reloadOrders();
                await ordersStore.setCurrentOrder(
                    parseInt(params.orderId),
                    parseInt(params.orgId),
                    parseInt(params.branchId),
                    MenuType.booking
                );

                const order = ordersStore.currentBookingOrder;

                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: false,
                        isWidget: false
                    })
                }
                if (order &&
                    order.paymentDetails?.type === PaymentProviderType.cloudpayments &&
                    //@ts-ignore
                    !order.paymentDetails?.receiptLink
                ) {
                    reloadOrder(2000)
                }
            }
        })()
        return clearTimer()
    }, [ordersStore.currentBookingOrder, ordersStore, params]);

    useEffect(() => {
        if (
            currentOrder?.paymentDetails?.type === PaymentProviderType.cloudpayments &&
            //@ts-ignore
            !currentOrder?.paymentDetails?.receiptLink) {
            ordersStore.forceReload();
        }
    }, [ordersStore.currentBookingOrder?.id])


    const receiptNeeded = currentOrder ? (currentOrder.paymentDetails?.type !== PaymentProviderType.offline
        //@ts-ignore
        && currentOrder.paymentDetails?.receiptLink) : false


    return <SimpleGreyBackgroundContainer>
        <RestifyLoader
            show={!currentOrder}
        />
        {currentOrder && <>
            <div className={style.header}>
                <div>
                    <BackButton
                        to={`/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`}/>
                    <TitleList>
                        <Trans i18nKey="viewOrder:order" values={{orderId: currentOrder.id}}/>
                    </TitleList>
                    <div className={style.orderCreatedText}>
                        <SmallGreyTitle>
                            {moment(currentOrder.createdAt).format("HH:mm, DD.MM.YYYY")}
                        </SmallGreyTitle>
                    </div>
                </div>
                <div>
                    {
                        receiptNeeded &&
                        <FadeInOutVertical>
                            <img src={receiptIcon} alt="" onClick={() => {
                                if (receiptNeeded) {
                                    //@ts-ignore
                                    window.open(currentOrder?.paymentDetails?.receiptLink)
                                }
                            }}/>
                        </FadeInOutVertical>
                    }
                </div>

            </div>

            <FadeInOutVertical>
                <OrderStatusBlock order={currentOrder}/>
            </FadeInOutVertical>
            <FadeInOutVertical>
                <div className={style.paperBlock}>
                    <PaperBlock>
                        {currentOrder.items.map((item, index) => {
                            if (item.type === BookingItemType.bookingOrderItemRegularItem) {
                                return <div key={`${item.menuItem.id}_${index}`} className={style.menuItemBox}>
                                    <div className={style.item}>
                                        <div>
                                            <p className={style.itemTitle}>
                                                {item.menuItem.data.name[locale]}
                                            </p>
                                            <p className={style.itemInfo}>
                                                {item.itemsCount} {t("general:units.pieces")} x {(item.price.price / 100).toFixed(0)} {t("general:currency.rub")}
                                            </p>
                                        </div>
                                        <p className={style.itemPrice}>
                                            {(item.price.price / 100 * item.itemsCount).toFixed(0)}
                                            <span className={style.currency}>
                                                {t("general:currency.rub")}
                                            </span>
                                        </p>
                                    </div>
                                    {item.chosenMenuItemModifierSets.map((chosenModifierSet) => {

                                        return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {

                                            const modifier = chosenModifierSet.data.modifiers.find(
                                                item => item.id === chosenModifier.menuItemModifierId);
                                            if (!modifier) return null;
                                            return <p className={style.itemModifier}
                                                      key={chosenModifier.menuItemModifierId}>
                                                {modifier.data.name[locale]} x {
                                                //@ts-ignore
                                                chosenModifier?.count || 1
                                            }
                                            </p>
                                        });

                                    })}
                                </div>
                            }

                            // if (item.type === BookingItemType.bookingOrderItemBookingItem) {

                            return <div key={`${item.menuBookingItem.id}_${index}`} className={style.menuItemBox}>
                                <div className={style.item}>
                                    <div className={style.nameWithPhoto}>
                                        {(bookingMenu && (bookingMenuStore.storeFrontMode === MenuItemsPhotosModes.defaultWithPhotos)) &&
                                            <div className={style.menuItemBoxPhotoBlock}>
                                                <LazyImage
                                                    media={item.menuBookingItem.data.media}
                                                    type={MediaType.regular}
                                                    disableLazy={true}
                                                    height={'auto'}/>

                                            </div>
                                        }
                                        <div>
                                            <p className={style.itemTitle}>
                                                {item.menuBookingItem.data.name[locale]}
                                            </p>
                                            <p className={style.itemInfo}>
                                                {item.itemsCount} {t("general:units.pieces")} x {(item.price.price / 100).toFixed(0)} {t("general:currency.rub")}
                                            </p>
                                            <p className={style.itemInfo}>{t('menu:bookingSlots.slots')}:</p>
                                            {item.chosenMenuBookingItemSlots.map((chosenSlot, index) => {
                                                let slots =[...item.chosenMenuBookingItemSlots];
                                                slots.slice().sort((slot1, slot2) => {
                                                    return moment(slot1.fromDate).unix() - moment(slot2.fromDate).unix();
                                                });
                                                let from = moment(slots[0].fromDate).clone().startOf("day");
                                                let section = null;
                                                if(from.clone().unix() !== moment(chosenSlot.fromDate).clone().startOf('day').unix()){
                                                    from = moment(chosenSlot.fromDate).clone().startOf('day');
                                                    section = <p className={style.itemDateSection}>{from.format("DD.MM.YYYY")}</p>
                                                }else if (index === 0) {
                                                    section = <p className={style.itemDateSection}>{from.format("DD.MM.YYYY")}</p>
                                                }
                                                return (
                                                    <div>
                                                        {section}
                                                        <p className={style.itemInfo}>
                                                            {moment(chosenSlot.fromDate).format("HH:mm")} - {moment(chosenSlot.toDate).format("HH:mm")}
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                            {item.chosenMenuItemModifierSets.map((chosenModifierSet) => {

                                                return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {

                                                    const modifier = chosenModifierSet.data.modifiers.find(
                                                        item => item.id === chosenModifier.menuItemModifierId);
                                                    if (!modifier) return null;
                                                    return <p className={style.itemModifier}
                                                              key={chosenModifier.menuItemModifierId}>
                                                        {modifier.data.name[locale]} x {
                                                        //@ts-ignore
                                                        chosenModifier?.count || 1
                                                    }
                                                    </p>
                                                });

                                            })}
                                        </div>
                                    </div>
                                    <p className={style.itemPrice}>
                                        {(item.price.price / 100 * item.itemsCount).toFixed(0)}
                                        <span className={style.currency}>
                                    {t("general:currency.rub")}
                                </span>
                                    </p>
                                </div>

                            </div>
                            // }

                        })}

                        <div className={style.menuItemBox}
                             style={{
                                 marginBottom: 0,
                                 borderTop: "1px solid #FF2C3D",
                                 paddingTop: 20,
                                 paddingBottom: 10
                             }}>
                            <div className={style.item}>
                                <p className={style.itemTitle}>
                                    {t("viewOrder:total")}
                                </p>
                                <p className={style.itemPrice}>
                                    {(currentOrder.totalPrice.price / 100).toFixed(0)}
                                    <span className={style.currency}>{t('general:currency.rub')}</span>
                                </p>
                            </div>
                        </div>
                    </PaperBlock>
                </div>
            </FadeInOutVertical>

            <Space size={10}/>
            <div className={style.buttonContainer}>
                <div className={style.button} onClick={() => {
                    let link: string;
                    if (generalStore.alias !== null) {
                        link = `/${generalStore.alias}?tableId=${generalStore.tableId}`
                    } else {
                        link = `/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`
                    }
                    history.push(link);
                }}>
                    {t("viewOrder:backToCatalog")}
                </div>
                <Space size={1}/>
                {
                    generalStore.modeData.mode === MenuMode.catalog &&
                    <div className={[style.button, style.inverse].join(" ")} onClick={() => {
                        if (generalStore.modeData.mode !== MenuMode.catalog) return;
                        const link = `/${generalStore.modeData.catalogAlias}`
                        history.push(link);
                    }}>
                        {t("viewOrder:backToCatalog")}
                    </div>
                }
                {generalStore.isLogoAndLinkEnabled && <RestifyFooter/>}
            </div>
        </>}

    </SimpleGreyBackgroundContainer>
};


export default observer(ViewBookingOrderPage);
