import {observer} from "mobx-react";
import style from "./style.module.sass";
import React from "react";

const OrdinaryCircleLoader = (props: { color?: string } = { color: "#FF2C3D transparent transparent transparent"} ) => {
    const color = props.color;
    return (
        <div className={style.loader}>
            <div
                style={{
                    borderColor: color
            }}></div>
            <div
                style={{
                    borderColor: color
                }}></div>
            <div
                style={{
                    borderColor: color
                }}></div>
            <div
                style={{
                    borderColor: color
                }}></div>
        </div>
    )

}
export default observer(OrdinaryCircleLoader);