import { observer } from "mobx-react";
import { AliasedMenu } from "../../../../services/aliases/models";
import { useStores } from "../../../../hooks";

import styles from "./style.module.sass";
import { FlexRowSpaceBetween } from "../../../../components/containers/containers";
import { useHistory } from "react-router";
import DeliveryChip from "../chips";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";


const hideDisabledMenus = {
    [117]: true,
    [121]: true
} as {
    [s: number]: boolean
}
interface Props {
    item: AliasedMenu
}

const CatalogItem = (props: Props) => {

    const { catalogStore, generalStore } = useStores();
    const { item } = props;
    const menu = catalogStore.getMenuById(item.menuId);
    const history = useHistory();
    const { t, i18n } = useTranslation(['catalog'])
    const [open, setIsOpen] = useState(false);

    useEffect(() => {
        if(hideDisabledMenus[item.menuId]){
            setIsOpen(true)
        }
    }, [])

    const descClasses = [styles.description];
    if(!open){
        descClasses.push(styles.descriptionClosed)
    }
    return <div className={styles.catalogItem}>
        {
            menu.data.photos.backgroundPhotoUrl && <div style={{ position: "relative" }}>
                <img
                    src={menu.data.photos.backgroundPhotoUrl}
                    alt=""
                    className={styles.backgroundImg}
                />
                {/*<div className={styles.chips}>
                    {Object.values(menu.data.deliverySettings)
                        .filter(item => item.isEnabled)
                        .map(item => <DeliveryChip type={item.type}/>)
                    }
                </div>*/}
            </div>
        }

        {menu.data.photos.logoPhotoUrl && <FlexRowSpaceBetween>
            <img
                src={menu.data.photos.logoPhotoUrl}
                alt=""
                className={styles.logoImage}
            />
        </FlexRowSpaceBetween>}

        <div className={styles.content}>
            <p className={styles.title}>
                {menu.data.name[i18n.language]}
            </p>
            <p className={descClasses.join(" ")}
               dangerouslySetInnerHTML={{__html: menu.data.description[i18n.language]}}
               onClick={() => {
                   setIsOpen(!open);
               }}
            />
        </div>

        <div className={styles.buttonContainer}>
            {
                !hideDisabledMenus[item.menuId] && <div className={styles.button} onClick={() => {
                    history.push(`/menu?orgId=${item.organizationId}&branchId=${item.organizationBranchId}&menuId=${item.menuId}&tableId=${generalStore.tableId}&fromCatalog=1`)
                }}>
                    {t('catalog:order')}
                </div>
            }
        </div>

    </div>
}

export default observer(CatalogItem);
