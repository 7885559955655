import BackButton from "../../components/buttons/backButton";
import style from "./style.module.sass"
import {useStores} from "../../hooks";
import {
    DeliveryConditionType,
    DeliveryType,
    MediaType,
    MenuItem,
    MenuItemsPhotosModes,
    MenuType,
    PriceInCurrency
} from "../../services/menu/models";
import usePayments from "../../hooks/payments/usePayments";
import {FadeInOutVertical} from "../../components/Animated";
import {useHistory} from "react-router";
import {CouponType, NewOrderItem, Order, PaymentProviderType} from "../../services/order/models";
import React, {useEffect, useState} from "react";
import {changeEventHandlerDecorator} from "../../components/libs";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import PlusMinusWidget from "../../components/plusMinusWidget";
import OrderingNotAvailable from "../orderingNotAvailable";
import {Spinner} from "../../components/spinner";
import 'react-phone-input-2/lib/style.css'
import DeliveryDetailsBlock from "./components/deliveryDetails";
import PaymentDetailsBlock from "./components/paymentDetails";
import PriceWithCoupon from "./components/priceWithCoupon";
import TitleList from "../../components/titles/titleList";
import {PaperBlock} from "../../components/paperBlock";
import {SimpleGreyBackgroundContainer} from "../../components/containers/simpleGreyBackgroundContainer";
import CouponBlock from "./components/couponBlock";
import sbpLogo from "./assets/sbp-logo.png"
import RestifyLoader from "../restifyLoader";

import noImage from "./assets/noImageProduct.png"

import {EventType, NewEventName} from "../../services/general/models";
import SmallGreyTitle from "../../components/titles/SmallGreyTitle";
import BonusCardBlock from "./components/bonusCardBlock";
import RestifyFooter from "../../components/restifyFooter";
import LazyImage from "../../components/imageLazyLoader";


const CartPage = () => {

    const {
        cartStore,
        menuStore,
        checkoutStore,
        generalStore,
        generalMenuStore,
        ordersStore,
        isInited
    } = useStores();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSBP, setIsLoadingSBP] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const {i18n, t} = useTranslation(["general", "cart", "coupon"]);
    const locale = i18n.language;

    const menuId = menuStore?.menu?.id
    const isCakeMenu = menuId == 1111

    const coupon = checkoutStore.coupon;
    const payments = usePayments();

    useEffect(() => {
        if (!isInited) return;
        menuStore.init({
            menuId: generalStore.menuId,
            organizationBranchId: generalStore.organizationBranchId,
            organizationId: generalStore.organizationId

        }).then(async () => {
            setIsReady(true);
            const params = {
                metainfo: null,
                name: NewEventName.cart,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        });

    }, [isInited])


    useEffect(() => {
        if (!menuStore.menu) return;
        const deliverySettings = menuStore.menu.data.deliverySettings;

        if (!deliverySettings) return;
        const isInside = deliverySettings[DeliveryType.toTable]?.isEnabled;
        const isToAddress = deliverySettings[DeliveryType.toAddress]?.isEnabled;
        const isTakeaway = deliverySettings[DeliveryType.takeaway]?.isEnabled;
        const isTakeawayToTime = deliverySettings[DeliveryType.takeawayToTime]?.isEnabled;
        const isInsideNoTable = deliverySettings[DeliveryType.insideTheRestaurant]?.isEnabled;
        const isToVehicle = deliverySettings[DeliveryType.toVehicle]?.isEnabled;
        if (isInside && isToAddress) {
            if (generalStore.tableId.length !== 0) {
                checkoutStore.setDeliveryType(DeliveryType.toTable);
                checkoutStore.setIsReadyToSubmit(!deliverySettings[DeliveryType.toTable]?.canChooseSeat);
            } else {
                checkoutStore.setDeliveryType(DeliveryType.toAddress);
                checkoutStore.setIsReadyToSubmit(false);
            }
        } else if (isToAddress) {
            checkoutStore.setDeliveryType(DeliveryType.toAddress)
            checkoutStore.setIsReadyToSubmit(false);
        } else if (isInside) {
            checkoutStore.setDeliveryType(DeliveryType.toTable);
            checkoutStore.setIsReadyToSubmit(!deliverySettings[DeliveryType.toTable]?.canChooseSeat);
        } else if (isTakeaway) {
            checkoutStore.setDeliveryType(DeliveryType.takeaway);
            checkoutStore.setIsReadyToSubmit(false);
        } else if (isTakeawayToTime) {
            checkoutStore.setDeliveryType(DeliveryType.takeawayToTime);
            checkoutStore.setIsReadyToSubmit(false);
        } else if (isInsideNoTable) {
            checkoutStore.setDeliveryType(DeliveryType.insideTheRestaurant);
            checkoutStore.setIsReadyToSubmit(false);
        } else if (isToVehicle) {
            checkoutStore.setDeliveryType(DeliveryType.toVehicle);
            checkoutStore.setIsReadyToSubmit(false);
        }
    }, [isReady])


    const showBonusCardModal = () => {
        checkoutStore.openBonusCardModal(() => {
            checkoutStore.closeBonusCardModal()
        });
    };

    const reset = () => {
        checkoutStore.reset();
        cartStore.reset();
        ordersStore.forceReload();
        generalStore.resetOrderSessionId();
    }


    const items = cartStore.list.reduce((memo, obj) => {
        let res = obj.items.map((item, index) => {
            return {
                item: item.menuItem,
                comment: null,
                menuItemId: obj.menuItemId,
                chosenMenuItemModifierSets: item.chosenMenuItemModifierSets,
                menuItemsCount: item.count,
                index
            }
        });
        memo.push(...res);
        return memo;
    }, [] as (NewOrderItem & { item: MenuItem, index: number })[]);

    useEffect(() =>{
        if (items.length === 0 && window.location.pathname === '/cart') {
            let link = ``;
            if (generalStore.alias !== null) {
                link = `/${generalStore.alias}?tableId=${generalStore.tableId}`
            } else {
                link = `/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`
            }
            history.push(link);
            generalMenuStore.closeCartModal()
        }
    }, [items.length])

    if (!generalStore.isMenuAvailable) {
        return <OrderingNotAvailable/>
    }





    const getContent = () => {

        if (!isReady || !isInited) return null;
        const deliverySettings = menuStore.menu.data.deliverySettings;
        const deliveryConditions = deliverySettings[DeliveryType.toAddress].deliveryConditions;
        const paymentSettings = menuStore.menu.data.paymentSettings;
        const subtotal = cartStore.getTotal(MenuType.regular);

        let freeDeliveryMinimalOrderPrice: PriceInCurrency = {
                                                                currencyId: "RUB",
                                                                price: 0
                                                            };
        let fixedDeliveryPrice: PriceInCurrency =  {
                                                        currencyId: "RUB",
                                                        price: 0
                                                    }

        if (deliveryConditions.type === DeliveryConditionType.fixedPrice) {
            fixedDeliveryPrice = deliveryConditions.fixedDeliveryPrice;
            freeDeliveryMinimalOrderPrice = deliveryConditions.freeDeliveryMinimalOrderPrice;
        }
        if (deliveryConditions.type === DeliveryConditionType.zonePrice) {
            freeDeliveryMinimalOrderPrice = checkoutStore.zoneFreeDeliveryMinimalOrderPrice;
            fixedDeliveryPrice = checkoutStore.zoneFixedPrice;
        }


        const addDeliveryPrice = (!generalStore.viewOnly && checkoutStore.deliveryDetails?.type === DeliveryType.toAddress)
            && (
                (subtotal < freeDeliveryMinimalOrderPrice?.price && fixedDeliveryPrice?.price !== 0)
                || (freeDeliveryMinimalOrderPrice?.price === 0 && fixedDeliveryPrice?.price !== 0)
            )


        return <>
            <div className={style.header}>
                <div className={style.back}><BackButton/></div>
                <TitleList>
                    <>{t("cart:title")}</>
                </TitleList>
            </div>
            {!generalStore.viewOnly && menuStore.menu.data.isPromoCodesEnabled &&
                <FadeInOutVertical>
                    <PaperBlock>
                        <CouponBlock/>
                    </PaperBlock>
                </FadeInOutVertical>
            }

            <p className={style.sectionTitle} style={{marginBottom: -15}}>
                {t("cart:yourOrder")}
            </p>

            <FadeInOutVertical>
                <PaperBlock>
                    {
                        items.map(({item, menuItemsCount, chosenMenuItemModifierSets, index}, indexNew) => {
                            const total = cartStore.getTotalByMenuItem(item.id, index, MenuType.regular);
                            return <div key={`${item.id}_${indexNew}`} className={style.menuItemBox}>
                                <div className={style.item}>
                                    {menuStore.storeFrontMode === MenuItemsPhotosModes.defaultWithPhotos &&

										<div className={style.menuItemBoxPhotoBlock}>
                                            <LazyImage
                                                media={item.data.media}
                                                type={MediaType.regular}
                                                disableLazy={true}
                                                height={'auto'}/>
                                        </div>
                                    }
                                    <div style={{flexGrow: 1}}>
                                        <div>
                                            <p className={style.itemTitle}>
                                                {item.data.name[locale]}
                                            </p>
                                            <p className={style.itemInfo}>
                                                {menuItemsCount} {t('general:units.pieces')} x {((total / 100)/ menuItemsCount).toFixed(total % 100 > 0 ? 2: 0)} {t('general:currency.rub')}
                                            </p>
                                            {chosenMenuItemModifierSets.map((chosenModifierSet) => {
                                                const modifierSet = menuStore.getModifiersById(chosenModifierSet.menuItemModifierSetId);
                                                return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {
                                                    const modifier = modifierSet.data.modifiers.find(
                                                        item => item.id === chosenModifier.menuItemModifierId);
                                                    if (!modifier) return null;
                                                    return <p className={style.itemModifier}
                                                              key={chosenModifier.menuItemModifierId}>
                                                        {modifier.data.name[locale]} x {
                                                        //@ts-ignore
                                                        chosenModifier?.count || 1
                                                    }
                                                    </p>
                                                });

                                            })}
                                        </div>


                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-around"}}>
                                        <PlusMinusWidget
                                            onCartPage={true}
                                            menuItem={item}
                                            index={0}
                                            menuItemsCount={menuItemsCount}
                                            size='small'
                                            indexToRemove={index}
                                            chosenMenuItemModifierSets={chosenMenuItemModifierSets}
                                        />
                                    </div>
                                </div>

                            </div>
                        })
                    }
                    {
                        (deliveryConditions.type === DeliveryConditionType.fixedPrice || (deliveryConditions.type === DeliveryConditionType.zonePrice && checkoutStore.isZoneEnabledForDelivery)) &&
                        <>
                            {addDeliveryPrice && <div className={style.menuItemBox}>
                                <div className={style.item}>
                                    <div>
                                        <p className={style.itemTitle}>
                                            {t("cart:deliveryItem")}
                                        </p>
                                        <p className={style.itemInfo}>
                                            1 {t("general:units.pieces")} x {(fixedDeliveryPrice.price / 100).toFixed(0)} {t("general:currency.rub")}
                                        </p>
                                    </div>
                                    <p className={style.itemPrice}>
                                        {(fixedDeliveryPrice.price / 100).toFixed(0)} <span
                                        className={style.currency}>{t("general:currency.rub")}</span>
                                    </p>
                                </div>
                            </div>}
                        </>
                    }

                    <PriceWithCoupon/>

                    {(!coupon ||
                            ((coupon.data.type === CouponType.fixedAmount || coupon.data.type === CouponType.percentage) && new Date(coupon.data.startDate).getTime() > Date.now() || new Date(coupon.data.endDate).getTime() < Date.now())) &&
                        <div className={style.horizontalRedLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:total")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.regular) / 100 + (addDeliveryPrice ? fixedDeliveryPrice.price / 100 : 0)).toFixed(cartStore.getTotal(MenuType.regular) % 100 > 0 ? 2 : 0)}
                                    <span className={style.currency}> {t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                    }

                </PaperBlock>
            </FadeInOutVertical>

            {menuStore.menu.data.isLoyaltyProgramEnabled &&
                <PaperBlock>
                    <div className={style.bonuses}>
                        <div className={style.bonusText}>
                            <p className={style.bonusTitle}>
                                {t("cart:bonusCard")}</p>
                            <SmallGreyTitle>{t("cart:addPhone")}</SmallGreyTitle>
                        </div>
                        <div className={style.bonusButton}
                             onClick={showBonusCardModal}>{t("general:add")}</div>
                    </div>
                </PaperBlock>}

            {!generalStore.viewOnly && <>
                <DeliveryDetailsBlock addDeliveryPrice={addDeliveryPrice}/>
                <PaymentDetailsBlock/>
                <div>
                    <p className={style.sectionTitle}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <span>{
                                isCakeMenu ? 'Комментарий/алиас' : t("cart:comment")
                            }</span>
                        </div>
                    </p>
                    <PaperBlock style={{
                        marginTop: 5,
                        marginBottom: 50
                    }}>
                        <div>
                            <input
                                className={style.deliverySettingsAddressInput}
                                value={checkoutStore.comment}
                                onChange={changeEventHandlerDecorator((value: string) => checkoutStore.setComment(value))}
                            />
                        </div>
                    </PaperBlock>
                </div>
            </>
            }
            {(paymentSettings[PaymentProviderType.sbpTochka]?.isEnabled && [PaymentProviderType.sbpTochka, PaymentProviderType.cloudpayments, PaymentProviderType.sberbank].indexOf(checkoutStore.paymentType) >= 0 && checkoutStore.isReadyToSubmit && menuStore.workingTime) &&
                <div className={style.sbpButtonContainer}>
                    <div className={style.sbpButton}
                         onClick={async () => {
                             if (!menuStore.workingTime) return false;
                             if (isLoadingSBP) return false;
                             setIsLoadingSBP(true);
                             checkoutStore.setPaymentType(PaymentProviderType.sbpTochka);
                             checkoutStore.setPaymentDetails({type: PaymentProviderType.sbpTochka});
                             const result = await checkoutStore.createOrder();
                             if (!result.err && result.order.paymentDetails?.type === PaymentProviderType.sbpTochka && result.order) {
                                 const url = result.order.paymentDetails.qrCodeLink;
                                 const orderId = result.order.id;
                                 history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/payment`)
                                 window.open(url, '_blank');
                                 setIsLoadingSBP(false);
                             } else {
                                 onFail();
                             }

                         }}>
                        {isLoadingSBP ?
                            <Spinner/>
                            :
                            <>
                                <p>{t("cart:pay")}</p>
                                <img src={sbpLogo}/>
                            </>
                        }

                    </div>
                </div>
            }
            {(paymentSettings[PaymentProviderType.cloudpayments]?.isEnabled || paymentSettings[PaymentProviderType.sberbank]?.isEnabled || checkoutStore.paymentType === PaymentProviderType.offline) &&
                !generalStore.viewOnly &&
                <div className={style.buttonContainer}>
                    <div
                        className={[style.button, !checkoutStore.isReadyToSubmit || isLoadingSBP || !menuStore.workingTime ? style.disabled : ""].join(" ")}
                        onClick={async () => {
                            if (isLoading || !checkoutStore.isReadyToSubmit || !menuStore.workingTime) return false;
                            setIsLoading(true);

                            const result = await checkoutStore.createOrder();
                            if (!result.err) console.log(result.order);
                            if (!result.err && checkoutStore.paymentType === PaymentProviderType.cloudpayments) {
                                payments.initCharge(PaymentProviderType.cloudpayments, result.order, {
                                    onSuccess, onFail
                                });
                            } else if (!result.err && checkoutStore.paymentType === PaymentProviderType.sberbank) {
                                payments.initCharge(PaymentProviderType.sberbank, result.order, {
                                    onSuccess, onFail
                                });
                            } else if (!result.err) {
                                onSuccess();
                            } else {
                                onFail();
                            }
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 2000);
                        }}>
                        {isLoading ?
                            <Spinner/>
                            :
                            (paymentSettings[PaymentProviderType.offline].isEnabled
                                && checkoutStore.paymentType === PaymentProviderType.offline) ? t("cart:confirmOrder") : t("cart:pay")
                        }
                    </div>
                </div>
            }
            {(generalStore.isLogoAndLinkEnabled && generalStore.viewOnly) &&
                <RestifyFooter/>}


            <BonusCardBlock/>
        </>
    }


    const goToOrderPage = (orderId?: number) => {
        const {organizationId, organizationBranchId, menuId} = generalStore;
        if (orderId) {
            history.push(`/order/${organizationId}/${organizationBranchId}/${menuId}/${orderId}/view`);
        } else {
            history.push(
                `/menu?orgId=${organizationId}&branchId=${organizationBranchId}&menuId=${menuId}&tableId=${generalStore.tableId}`
            );
        }
        generalMenuStore.closeCartModal()
    }
    const onSuccess = async () => {
        const orderId = checkoutStore.order?.id;
        if (checkoutStore.paymentType === PaymentProviderType.toBankCard) {
            history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/toBankCardPayment`);
        } else if (checkoutStore.paymentType === PaymentProviderType.offline) {
            goToOrderPage(orderId);
            const params = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        } else if (checkoutStore.paymentType === PaymentProviderType.cloudpayments) {
            const params1 = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params1);
            const params2 = {
                metainfo: null,
                name: NewEventName.orderPaid,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params2);
            goToOrderPage(orderId);
        } else if (checkoutStore.paymentType === PaymentProviderType.sberbank) {
            const params1 = {
                metainfo: null,
                name: NewEventName.orderCreated,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params1);
            const params2 = {
                metainfo: null,
                name: NewEventName.orderPaid,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params2);
            goToOrderPage(orderId);

        } else if (checkoutStore.paymentType === PaymentProviderType.companyInvoice) {
            history.push(`/order/${generalStore.organizationId}/${generalStore.organizationBranchId}/${generalStore.menuId}/${orderId}/toCompanyByInvoice`);
        }

        setTimeout(async () => {
            if (checkoutStore.deliveryDetails !== null && checkoutStore.order) {
                await checkoutStore.reloadOrder();
                const order: Order = {
                    ...checkoutStore.order,
                    deliveryDetails: checkoutStore.deliveryDetails,
                    clientId: checkoutStore.clientId,
                    loyaltyProgram: checkoutStore.loyaltyProgram,
                }
                ordersStore.addOrder(order, MenuType.regular);
                reset()
            } else {
                console.log("Ошибка добавления заказа")
            }
        }, 2000)


    };
    const onFail = () => {
        console.log("Ошибка")
    };


    return <SimpleGreyBackgroundContainer>
        <RestifyLoader
            show={!isReady || !isInited}
        />

        {isReady && isInited && getContent()}

    </SimpleGreyBackgroundContainer>
};


export default observer(CartPage);
