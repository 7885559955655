import { AliasesAPI } from "./methods";
import {
    GetPublicAliasBindingRequest,
    GetPublicAliasBindingResponse,
} from "./requestsResponses";
import { execute, ExecutionStatus } from "../../apiCommandsExecutor/api";


export const getPublicAliasBindingByAlias = async (params: GetPublicAliasBindingRequest) => {
    const response = await execute<GetPublicAliasBindingRequest, GetPublicAliasBindingResponse>(
        AliasesAPI.getPublicAliasBinding, params);
    return {
        err: !(response.status === 200 && response.data.executionStatus === ExecutionStatus.Finished),
        aliasBinding: response.data.aliasBinding,
        menusByOrgBranchIdByMenuId: response.data.menusByOrgBranchIdByMenuId
    }
};


