import { observer } from "mobx-react";
import style from "./style.module.sass"
import { FadeInOutVertical } from "../../components/Animated";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { changeEventHandlerDecorator } from "../../components/libs";



const EnterAlias = () => {
    const history = useHistory();
    const [alias, setAlias] = useState("")
    const {t} = useTranslation(["general"]);



    return <div className={style.container}>
        <FadeInOutVertical>
            <div className={style.block}>
                {/*<img src={orderCreatedImg} alt=""/>*/}
                <p className={style.header}>{t("general:enterAlias.title")}</p>
               {/* <p className={style.text}>
                    {t("general:orderingNotAvailable.description")}
                </p>*/}
                <div >
                    <div>
                        <input
                            className={style.input}
                            value={alias}
                            onChange={changeEventHandlerDecorator(setAlias)}
                        />
                    </div>
                </div>

                <div className={style.button} onClick={() => {
                    history.replace(`/${alias}`)
                }}>
                    {t("general:enterAlias.proceed")}
                </div>
            </div>
        </FadeInOutVertical>
    </div>
};


export default observer(EnterAlias);
