import style from "./errorBoundary.module.sass"
import {observer} from "mobx-react";
import {Trans, useTranslation} from "react-i18next";
import {SimpleGreyBackgroundContainer} from "../containers/simpleGreyBackgroundContainer";
import RestifyFooter from "../restifyFooter";
import { FadeInOutVertical } from "../Animated";
import { useStores } from "../../hooks";
import { dropLocalState } from "../../store/persistence";

const BoundaryText = () => {
    const {t} = useTranslation(['general', 'menu']);
    const {menuStore, cartStore} = useStores();

    return <div className={style.errorBoundary}>
        <SimpleGreyBackgroundContainer>
            <FadeInOutVertical>
                <div className={style.errorBoundaryContainer}>
                <div className={style.paperBlock}>
                    <div className={style.infoContainer}>
                        <svg width="45" height="48" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M42.2068 20.3055C42.2068 9.09188 33.0913 0 21.8459 0C10.6033 0 1.48779 9.09188 1.48779 20.3055C1.48779 31.5218 10.6032 40.611 21.8459 40.611C33.0913 40.611 42.2068 31.5218 42.2068 20.3055Z"
                                  fill="#FFC344"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M42.2068 20.3055C42.2068 9.09188 33.0913 0 21.8459 0C21.2613 0 20.6794 0.0246563 20.1057 0.0711562C30.5359 0.952688 38.7263 9.675 38.7263 20.3055C38.7263 30.936 30.5359 39.6582 20.1057 40.5398C20.6794 40.5863 21.2613 40.611 21.8459 40.611C33.0913 40.611 42.2068 31.5218 42.2068 20.3055Z"
                                  fill="#FEB237"/>
                            <path
                                d="M25.3808 30.3462C25.2173 30.3462 25.0528 30.2912 24.9173 30.1783C22.9721 28.557 19.8089 28.5568 17.8661 30.178C17.5589 30.4343 17.1022 30.3932 16.8459 30.086C16.5896 29.7789 16.6309 29.3221 16.938 29.0658C18.1327 28.0689 19.7139 27.5199 21.3906 27.5199C23.0671 27.5199 24.6489 28.0688 25.8449 29.0655C26.1522 29.3216 26.1937 29.7784 25.9376 30.0857C25.7943 30.2575 25.5882 30.3462 25.3808 30.3462Z"
                                fill="#554E56"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M19.2301 19.6238C19.2301 16.9271 17.037 14.7369 14.3306 14.7369C11.6242 14.7369 9.43115 16.9271 9.43115 19.6238C9.43115 22.3231 11.6242 24.5133 14.3306 24.5133C17.037 24.5133 19.2301 22.3232 19.2301 19.6238Z"
                                  fill="#FAF7F7"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M33.9889 19.6238C33.9889 16.9271 31.7958 14.7369 29.0894 14.7369C26.383 14.7369 24.1899 16.9271 24.1899 19.6238C24.1899 22.3231 26.383 24.5133 29.0894 24.5133C31.7958 24.5133 33.9889 22.3232 33.9889 19.6238Z"
                                  fill="#FAF7F7"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M32.8197 11.2601C33.5663 10.8933 33.8765 9.98709 33.5087 9.24243C33.1409 8.49778 32.2324 8.1884 31.4858 8.55524C30.0722 9.24787 28.3649 8.66746 27.6705 7.25756C27.3054 6.5129 26.3969 6.20353 25.6476 6.57037C24.9009 6.93721 24.5935 7.8434 24.9586 8.58806C26.3887 11.4901 29.9075 12.6864 32.8197 11.2601Z"
                                  fill="#554E56"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M10.6004 11.2601C13.5098 12.6864 17.0286 11.4901 18.4587 8.58816C18.8264 7.8435 18.5163 6.93731 17.7697 6.57047C17.0231 6.20362 16.1146 6.513 15.7468 7.25766C15.0524 8.66756 13.3451 9.24797 11.9315 8.55534C11.1849 8.1885 10.2765 8.49787 9.91139 9.24253C9.5437 9.98709 9.85383 10.8933 10.6004 11.2601Z"
                                  fill="#554E56"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M19.23 19.6238C19.23 16.9271 17.037 14.7369 14.3306 14.7369C14.0643 14.7369 13.8008 14.7589 13.5483 14.7999C15.6865 15.3256 17.273 17.2502 17.273 19.5443C17.273 21.9782 15.4889 23.9959 13.1531 24.3709C13.5318 24.464 13.9244 24.5133 14.3306 24.5133C17.037 24.5133 19.23 22.3232 19.23 19.6238Z"
                                  fill="#DEDBEE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M33.9888 19.6238C33.9888 16.9271 31.7957 14.7369 29.0893 14.7369C28.8231 14.7369 28.5624 14.7589 28.3071 14.7999C30.4452 15.3256 32.0318 17.2502 32.0318 19.5443C32.0318 21.9782 30.2476 23.9959 27.9146 24.3709C28.2906 24.464 28.6831 24.5133 29.0893 24.5133C31.7957 24.5133 33.9888 22.3232 33.9888 19.6238Z"
                                  fill="#DEDBEE"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M16.0432 19.6237C16.0432 18.6819 15.2747 17.9154 14.3305 17.9154C13.3836 17.9154 12.615 18.682 12.615 19.6237C12.615 20.5683 13.3836 21.3348 14.3305 21.3348C15.2747 21.3349 16.0432 20.5683 16.0432 19.6237Z"
                                  fill="#554E56"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M29.0893 21.3349C30.0363 21.3349 30.802 20.5683 30.802 19.6238C30.802 18.682 30.0363 17.9155 29.0893 17.9155C28.1423 17.9155 27.3738 18.6821 27.3738 19.6238C27.3739 20.5683 28.1423 21.3349 29.0893 21.3349Z"
                                  fill="#554E56"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M2.9205 33.5532H41.5918C43.2003 33.5532 44.515 34.8646 44.515 36.4688V45.0844C44.515 46.6886 43.2003 48 41.5918 48H2.9205C1.31484 48 0 46.6886 0 45.0844V36.4688C9.375e-05 34.8645 1.31484 33.5532 2.9205 33.5532Z"
                                  fill="#FF646F"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M40.2003 36.4688V45.0844C40.2003 46.6886 38.8856 48 37.2771 48H41.5919C43.2004 48 44.5152 46.6886 44.5152 45.0844V36.4688C44.5152 34.8646 43.2004 33.5532 41.5919 33.5532H37.2771C38.8856 33.5532 40.2003 34.8645 40.2003 36.4688Z"
                                  fill="#FB4455"/>
                            <path
                                d="M7.65514 39.9282C6.80923 39.6177 6.02333 39.2781 5.80376 39.182C5.63473 39.0534 5.55298 38.8518 5.58392 38.6337C5.6038 38.4932 5.70383 38.1502 6.19995 38.0004C7.21339 37.6967 8.13739 38.4324 8.15858 38.4496C8.46476 38.7039 8.91936 38.6636 9.17595 38.3586C9.43329 38.0523 9.39373 37.5954 9.08754 37.338C9.02614 37.2864 7.56251 36.0796 5.78267 36.6131C4.89626 36.8806 4.27048 37.577 4.14945 38.4305C4.03667 39.2261 4.38355 39.9904 5.0547 40.4251C5.0862 40.4455 5.1193 40.4634 5.15361 40.4787C5.19233 40.4961 6.11567 40.9065 7.15517 41.2881C7.47711 41.4067 8.21473 41.7453 8.11076 42.3283C8.03567 42.7497 7.53261 43.1857 6.82076 43.1857C6.11942 43.1857 5.44348 42.9027 5.0127 42.4289C4.74373 42.133 4.28558 42.1112 3.98952 42.3803C3.69355 42.6493 3.6717 43.1075 3.94086 43.4034C4.65205 44.1857 5.70177 44.6343 6.82086 44.6343C8.18276 44.6343 9.32511 43.7713 9.53708 42.5825C9.69579 41.6927 9.28479 40.5283 7.65514 39.9282Z"
                                fill="#433F43"/>
                            <path
                                d="M40.1843 36.6122C39.8494 36.3937 39.4007 36.4882 39.182 36.8232L37.6575 39.1608L36.1155 36.8057C35.8964 36.4711 35.4476 36.3775 35.1128 36.5965C34.7782 36.8156 34.6844 37.2646 34.9036 37.5993L36.9339 40.7003L36.9261 43.9082C36.9251 44.3082 37.2487 44.6333 37.6486 44.6343H37.6504C38.0497 44.6343 38.3738 44.3111 38.3747 43.9117L38.3825 40.7012L40.3953 37.6147C40.614 37.2795 40.5195 36.8307 40.1843 36.6122Z"
                                fill="#433F43"/>
                            <path
                                d="M25.6827 38.9465C25.6827 37.5855 24.5291 36.4782 23.1112 36.4782H21.3215C20.9273 36.4782 20.5972 36.8083 20.5972 37.2025V43.9099C20.5972 44.3099 20.9214 44.6342 21.3215 44.6342C21.7215 44.6342 22.0458 44.3099 22.0458 43.9099V41.707L24.4154 44.3894C24.5586 44.5515 24.7581 44.6342 24.9586 44.6342C25.129 44.6342 25.3001 44.5744 25.4379 44.4527C25.7377 44.1879 25.7661 43.7301 25.5012 43.4303L23.6685 41.3556C24.8194 41.1105 25.6827 40.1237 25.6827 38.9465ZM23.1113 39.966C22.8668 39.966 22.4333 39.9673 22.0554 39.9687C22.0534 39.6003 22.0514 39.1814 22.0514 38.9465C22.0514 38.747 22.05 38.3126 22.0487 37.9269H23.1112C23.7198 37.9269 24.234 38.3938 24.234 38.9465C24.234 39.4991 23.7199 39.966 23.1113 39.966Z"
                                fill="#433F43"/>
                            <path
                                d="M14.6654 36.4782C12.4105 36.4782 10.5759 38.3077 10.5759 40.5563C10.5759 42.8064 12.4104 44.637 14.6654 44.637C16.9188 44.637 18.7521 42.8064 18.7521 40.5563C18.7521 38.3076 16.9188 36.4782 14.6654 36.4782ZM14.6654 43.1883C13.2093 43.1883 12.0246 42.0075 12.0246 40.5563C12.0246 39.1064 13.2093 37.9269 14.6654 37.9269C16.12 37.9269 17.3034 39.1064 17.3034 40.5563C17.3033 42.0075 16.1199 43.1883 14.6654 43.1883Z"
                                fill="#433F43"/>
                            <path
                                d="M33.0279 38.9465C33.0279 37.5855 31.8744 36.4782 30.4563 36.4782H28.6694C28.6689 36.4782 28.6685 36.4783 28.668 36.4783C28.6675 36.4783 28.6671 36.4782 28.6667 36.4782C28.2666 36.4782 27.9424 36.8025 27.9424 37.2025V43.9099C27.9424 44.3099 28.2667 44.6342 28.6667 44.6342C29.0667 44.6342 29.391 44.3099 29.391 43.9099V41.707L31.7607 44.3894C31.9039 44.5515 32.1034 44.6342 32.3039 44.6342C32.4744 44.6342 32.6454 44.5744 32.7833 44.4527C33.083 44.1879 33.1114 43.7301 32.8465 43.4303L31.0137 41.3556C32.1646 41.1105 33.0279 40.1237 33.0279 38.9465ZM30.4564 39.966C30.2119 39.966 29.7785 39.9673 29.4006 39.9687C29.3986 39.6003 29.3966 39.1814 29.3966 38.9465C29.3966 38.747 29.396 38.3124 29.3952 37.9269H30.4564C31.0651 37.9269 31.5793 38.3938 31.5793 38.9465C31.5793 39.4991 31.0651 39.966 30.4564 39.966Z"
                                fill="#433F43"/>
                        </svg>

                        <div>
                            <h4>{t('general:somethingWrong')}</h4>
                            <p>{t('general:sorrySomethingWrong')}</p>
                            <p>{t('general:weKnowAndFix')}</p>
                        </div>
                    </div>
                </div>
                <div className={style.button}
                     onClick={() => {
                         dropLocalState();
                         window.location.reload();
                     }}>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.67235 11.3871C2.92304 9.14457 3.92577 7.02665 5.5552 5.40707C7.56066 3.53832 10.0675 2.54165 12.6997 2.54165C15.2065 2.54165 17.8387 3.53832 19.8441 5.40707L17.7133 7.52499H23.9804V1.29582L21.5989 3.6629C16.7106 -1.19585 8.68874 -1.19585 3.80043 3.6629C1.79496 5.65624 0.541551 8.27249 0.165527 11.1379V12.5083H2.67235C2.67235 12.1346 2.67235 11.7608 2.67235 11.3871Z"
                            fill="white"/>
                        <path
                            d="M22.727 12.5083C22.727 15.1246 21.7242 17.7408 19.8441 19.6096C17.8387 21.4783 15.3318 22.475 12.6997 22.475C10.0675 22.475 7.56067 21.4783 5.55521 19.6096L7.68601 17.4917H1.41895V23.7208L3.80043 21.3537C6.18191 23.7208 9.31544 24.9667 12.6997 24.9667C16.0839 24.9667 19.2174 23.7208 21.5989 21.3537C23.9804 18.9867 25.2338 15.7475 25.2338 12.5083H22.727Z"
                            fill="white"/>
                    </svg>
                    <p>{t('general:orderingNotAvailable.refresh')}</p>
                </div>
            </div>
            </FadeInOutVertical>
            <div className={style.footer}><RestifyFooter/></div>
        </SimpleGreyBackgroundContainer>
    </div>
}

export default observer(BoundaryText);




