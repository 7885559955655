import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.sass";
import percentage from './assets/percentage.svg';
import acceptedPromocode from './assets/promocode_accepted.svg';
import declinedPromocode from './assets/promocode_not_active.svg';
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../../../hooks";
import { changeEventHandlerDecorator } from "../../../../components/libs";
import SmallGreyText from "../../../../components/titles/SmallGreyTitle";
import OrdinaryCircleLoader from "../../../../components/ordinaryCircleLoader";
import { CouponType } from "services/order/models";


const CouponBlock = () => {
    const { checkoutStore, menuStore } = useStores();
    const coupon = checkoutStore.coupon;
    const failedCouponCheck = checkoutStore.failedCouponCheck;

    const { t } = useTranslation(['coupon']);

    const couponCode = checkoutStore.couponCode;
    const isCheckingCouponInProgress = checkoutStore.isCouponCodeChecking;

    const inputClasses = [];
    if ((failedCouponCheck)) {
        inputClasses.push(style.failedCouponInput)
    } else if (checkoutStore.isCouponValid(coupon)) {
        inputClasses.push(style.activeCouponInput)
    } else {
        inputClasses.push(style.checkingCouponInput)
    }

    const dom8OrgBranch = 699

    if (menuStore.menu?.organizationBranchId == dom8OrgBranch && couponCode == '') {
        checkoutStore.setCouponCode('Barrin')
    }

    const Input = <input
        placeholder={t("coupon:yourPromoCode")}
        className={inputClasses[0]}
        value={couponCode}
        disabled={checkoutStore.isCouponValid(coupon) && !failedCouponCheck}
        onChange={changeEventHandlerDecorator((v: string) => {
            checkoutStore.setCouponCode(v);
        })}
    />


    return (
        <>
            {(!coupon && !failedCouponCheck) &&
            <>
                <div className={style.couponBlock}>
                    <img src={percentage} alt=''/>
                    <div>
                        <h4>{t("coupon:promoCode")}</h4>
                        <SmallGreyText>{t("coupon:enterPromoCode")}</SmallGreyText>
                    </div>
                </div>
            </>
            }
            {(coupon && new Date(coupon.data.startDate).getTime() < Date.now() &&
            new Date(coupon.data.endDate).getTime() > Date.now() && !failedCouponCheck) &&
            <>
                <div className={style.activeCouponBlock}>
                    <div className={style.activeCoupon}>
                        <img src={acceptedPromocode} alt=''/>
                        <div>
                            {coupon.data.type === CouponType.percentage && menuStore.menu?.organizationBranchId == dom8OrgBranch &&
                                <h4>Скидка жителям {coupon.data.discountPercent}%</h4>
                            }
                            {coupon.data.type === CouponType.percentage && menuStore.menu?.organizationBranchId != dom8OrgBranch &&
                                <h4><Trans i18nKey="coupon:percentDiscount"
                                           values={{ discountPercent: coupon.data.discountPercent }}/></h4>
                            }
                            {coupon.data.type === CouponType.fixedAmount &&
                                <h4>
                                    <Trans i18nKey="coupon:fixedDiscount"
                                    values={{ discountFixedAmount: coupon.data.discountAmount.amount/100 }}/>
                                </h4>
                            }
                            {menuStore.menu?.organizationBranchId == dom8OrgBranch &&
                                <SmallGreyText>
                                    <Trans i18nKey="coupon:promoCodeAmount" values={{ couponCode: 'активирован' }}/>
                                </SmallGreyText>
                            }
                            {menuStore.menu?.organizationBranchId != dom8OrgBranch &&
                                <SmallGreyText>
                                    <Trans i18nKey="coupon:promoCodeAmount" values={{ couponCode: couponCode }}/>
                                </SmallGreyText>
                            }


                        </div>
                    </div>
                    <div className={style.removeCoupon}
                         onClick={() => {
                             checkoutStore.setCoupon(null);
                             checkoutStore.resetCouponCode();
                         }}>{t("coupon:remove")}
                    </div>
                </div>
            </>
            }
            {(failedCouponCheck ||
                coupon && new Date(coupon.data.startDate).getTime() > Date.now() ||
                coupon && new Date(coupon.data.endDate).getTime() < Date.now()) &&
            <>
                <div className={style.failedCouponBlock}>
                    <img src={declinedPromocode} alt=''/>
                    <div>
                        <h4>{t("coupon:promoCode")}</h4>
                        <p>{t("coupon:incorrectPromoCode")}</p>
                    </div>
                </div>
            </>
            }
            <div className={style.inputWithLoader}>
                {Input}
                {isCheckingCouponInProgress && <div className={style.loader}>
                    <OrdinaryCircleLoader color={"#FF2C3D transparent transparent transparent"}/>
                </div>}
            </div>
        </>
    )
}

export default observer(CouponBlock);
