import {observer} from "mobx-react";
import style from "./style.module.sass"
import {useStores} from "../../hooks";
import {FadeInOutVertical} from "../../components/Animated";
import {useHistory, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import moment from "moment"
import receiptIcon from "./assets/bill.svg"
import {Order, OrderStatus, PaymentProviderType} from "../../services/order/models";
import {DeliveryType, MenuType} from "../../services/menu/models";
import {Trans, useTranslation} from "react-i18next";
import {MenuMode} from "../../store/general";
import {Space} from "../../components/libs";

import OrderStatusBlock from "./components/orderStatusBlock";
import BackButton from "components/buttons/backButton";
import TitleList from "../../components/titles/titleList";
import {SimpleGreyBackgroundContainer} from "../../components/containers/simpleGreyBackgroundContainer";
import {PaperBlock} from "../../components/paperBlock";
import SmallGreyTitle from "../../components/titles/SmallGreyTitle";
import RestifyLoader from "../restifyLoader";
import RestifyFooter from "../../components/restifyFooter";

const ViewOrderPage = () => {
    const { ordersStore, generalStore } = useStores();
    const history = useHistory();
    const params = useParams<{ orderId: string, orgId: string, branchId: string, menuId: string }>();

    const currentOrder = ordersStore.currentOrder;
    const { t, i18n } = useTranslation(['viewOrder', "general"])
    const locale = i18n.language;
    const [timer, setTimer] = useState<any>(null)

    document.body.style.overflowY = 'scroll';

    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    }
    const reloadOrder = (timeout: number) => {
        const t = setTimeout(async () => {
            await ordersStore.reloadCurrentOrder(MenuType.regular);
            if (ordersStore.currentOrder &&
                ordersStore.currentOrder.paymentDetails?.type === PaymentProviderType.cloudpayments &&
                //@ts-ignore
                !ordersStore.currentOrder.paymentDetails?.receiptLink) {
                reloadOrder(timeout)
            }
        }, timeout);
        setTimer(t);
    }

    useEffect(() => {
        (async () => {
            if (!currentOrder || currentOrder.id !== parseInt(params.orderId)) {
                //await ordersStore.reloadOrders();
                await ordersStore.setCurrentOrder(
                    parseInt(params.orderId),
                    parseInt(params.orgId),
                    parseInt(params.branchId),
                    MenuType.regular
                );

                const order = ordersStore.currentOrder;

                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: false,
                        isWidget: false
                    })
                }
                if (order &&
                    order.paymentDetails?.type === PaymentProviderType.cloudpayments &&
                    //@ts-ignore
                    !order.paymentDetails?.receiptLink
                ) {
                    reloadOrder(2000)
                }
            }
        })()
        return clearTimer()
    }, [ordersStore.currentOrder, ordersStore, params]);

    useEffect(() => {
        if (
            currentOrder?.paymentDetails?.type === PaymentProviderType.cloudpayments &&
            //@ts-ignore
            !currentOrder?.paymentDetails?.receiptLink) {
            ordersStore.forceReload();
        }
    }, [ordersStore.currentOrder?.id])


    const getCurrentOrderNumber = (order: Order) => {
        if (order.externalId) {
            if (order.externalOrderNumber) {
                return `${order.externalOrderNumber}`
            } else {
                if (order.status == OrderStatus.new) {
                    return ` ---`
                } else {
                    return `${order.id}`
                }
            }
        } else {
            return `${order.id}`
        }
    }


    const receiptNeeded = currentOrder ? (currentOrder.paymentDetails?.type !== PaymentProviderType.offline
        //@ts-ignore
        && currentOrder.paymentDetails?.receiptLink) : false

    return <SimpleGreyBackgroundContainer>
        <RestifyLoader
            show={!currentOrder}
        />
        {currentOrder && <>
			<div className={style.header}>
				<div>
					<BackButton to={`/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`}/>
					<TitleList>
						<Trans i18nKey="viewOrder:order" values={{ orderId: getCurrentOrderNumber(currentOrder) }}/>
					</TitleList>
					<div className={style.orderCreatedText}>
						<SmallGreyTitle>
                            {moment(currentOrder.createdAt).format("HH:mm, DD.MM.YYYY")}
						</SmallGreyTitle>
					</div>
				</div>
				<div>
                    {
                        receiptNeeded &&
						<FadeInOutVertical>
							<img src={receiptIcon} alt="" onClick={() => {
                                if (receiptNeeded) {
                                    //@ts-ignore
                                    window.open(currentOrder?.paymentDetails?.receiptLink)
                                }
                            }}/>
						</FadeInOutVertical>
                    }
				</div>

			</div>

			<FadeInOutVertical>
				<OrderStatusBlock order={currentOrder}/>
			</FadeInOutVertical>
			<FadeInOutVertical>
				<>
                    <div className={style.paperBlock}>
					<PaperBlock>
                        {
                            currentOrder.items.map(({
                                                        menuItem,
                                                        chosenMenuItemModifierSets,
                                                        menuItemsCount,
                                                        price
                                                    }, index) => {
                                    return <div key={`${menuItem.id}_${index}`} className={style.menuItemBox}>
                                        <div className={style.item}>
                                            <div>
                                                <p className={style.itemTitle}>
                                                    {menuItem.data.name[locale]}
                                                </p>
                                                <p className={style.itemInfo}>
                                                    {menuItemsCount} {t("general:units.pieces")} x {(price.price / 100).toFixed(price.price % 100 > 0 ? 2 : 0)} {t("general:currency.rub")}
                                                </p>
                                            </div>
                                            <p className={style.itemPrice}>
                                                {(price.price / 100 * menuItemsCount).toFixed(price.price % 100 > 0 ? 2: 0)}
                                                <span className={style.currency}>
                                    {t("general:currency.rub")}
                                </span>
                                            </p>
                                        </div>
                                        {chosenMenuItemModifierSets.map((chosenModifierSet) => {

                                            return chosenModifierSet.chosenMenuItemModifiers.map((chosenModifier) => {

                                                const modifier = chosenModifierSet.data.modifiers.find(
                                                    item => item.id === chosenModifier.menuItemModifierId);
                                                if (!modifier) return null;
                                                return <p className={style.itemModifier}
                                                          key={chosenModifier.menuItemModifierId}>
                                                    {modifier.data.name[locale]} x {
                                                    //@ts-ignore
                                                    chosenModifier?.count || 1
                                                }
                                                </p>
                                            });

                                        })}
                                    </div>
                                }
                            )
                        }

						<div className={style.menuItemBox}
							 style={{ marginBottom: 0, borderTop: "1px solid #FF2C3D", paddingTop: 20, paddingBottom: 10 }}>
							<div className={style.item}>
								<p className={style.itemTitle}>
                                    {t("viewOrder:total")}
								</p>
								<p className={style.itemPrice}>
                                    {(currentOrder.totalPrice.price / 100).toFixed(currentOrder.totalPrice.price % 100 > 0 ? 2: 0)}
									<span className={style.currency}>{t('general:currency.rub')}</span>
								</p>
							</div>
						</div>
					</PaperBlock>
                    </div>
				</>
			</FadeInOutVertical>

            {
                currentOrder.deliveryDetails.type === DeliveryType.toTable && ordersStore.table && <FadeInOutVertical>
					<div className={style.paperBlock}>
						<PaperBlock>
							<div className={style.deliveryDetails}>
								<p className={style.title}>
                                    {t("viewOrder:table")}
								</p>

								<p className={style.value}>
                                    {ordersStore.table.data.title[locale]}
								</p>
							</div>
                            {
                                currentOrder.deliveryDetails.seatId !== null && <div className={style.deliveryDetails}>
									<p className={style.title}>
                                        {t("viewOrder:seat")}
									</p>

									<p className={style.value}>
                                        {currentOrder.deliveryDetails.seatId}
									</p>
								</div>
                            }
						</PaperBlock>

                    </div>
				</FadeInOutVertical>
            }


            {/* {
            currentOrder.deliveryDetails.type === DeliveryType.toAddress && <FadeInOutVertical>
                <>
                    <div className={style.paperBlock}>
                        <div className={style.deliveryDetails}>
                            <p className={style.title}>
                                Контакт
                            </p>

                            <p className={style.value}>
                                {currentOrder.deliveryDetails.phoneNumber}
                            </p>
                        </div>
                        <div>
                            <p className={style.title}>
                                Адрес
                            </p>

                            <p className={style.value}>
                                {currentOrder.deliveryDetails.address}
                            </p>
                        </div>
                    </div>

                </>
            </FadeInOutVertical>
        }*/}
            <Space size={10}/>
			<div className={style.buttonContainer}>
                {
                    generalStore.modeData.mode !== MenuMode.catalog &&
                    <div className={style.button} onClick={() => {
                        let link: string;
                        if (generalStore.alias !== null) {
                            link = `/${generalStore.alias}?tableId=${generalStore.tableId}`
                        } else {
                            link = `/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&tableId=${generalStore.tableId}`
                        }
                        history.push(link);
                    }}>
                        {t("viewOrder:backToCatalog")}
                    </div>
                }
                {
                    generalStore.modeData.mode === MenuMode.catalog &&
					<div className={[style.button, style.inverse].join(" ")} onClick={() => {
                        if (generalStore.modeData.mode !== MenuMode.catalog) return;
                        const link = `/menu?orgId=${generalStore.organizationId}&branchId=${generalStore.organizationBranchId}&menuId=${generalStore.menuId}&fromCatalog=1&tableId=${generalStore.tableId}`
                        history.push(link);
                    }}>
                        {t("viewOrder:backToCatalog")}
					</div>
                }
                {generalStore.isLogoAndLinkEnabled && <RestifyFooter/>}
			</div>
        </>}

    </SimpleGreyBackgroundContainer>
};


export default observer(ViewOrderPage);
