import {observer} from "mobx-react";
import React, {useEffect, useState} from "react"
import {useStores} from "../../../../hooks";
import CategoryContentWithPhoto from "./menuItemsWithPhoto/CategoryContentWithPhoto";
import CategoryContentNoPhoto from "./menuItemsNoPhoto/CategoryContentNoPhoto";

import styles from "./style.module.sass"
import {MediaItem, MediaType, MenuItemsPhotosModes} from "../../../../services/menu/models";
import style from "../categories/style.module.sass";
import noImageCategory from "./menuItemsWithPhoto/assets/noImageProduct.png";
import LazyImage from "../../../../components/imageLazyLoader";
import {Trans, useTranslation} from "react-i18next";
import SideMenu from "./components/SideMenu";

const MenuItemsList = () => {
    const {menuStore, generalMenuStore} = useStores();
    const categories = menuStore.categories;
    const [categoryData, setCategoryData] = useState<{ [s: number]: { visible: boolean, offset: number } }>({});
    const [categoriesHeaderRefs, setCategoriesHeaderRefs] = useState<{ [s: string]: any }>({});
    const {t, i18n} = useTranslation(['menu']);

    useEffect(() => {
        setCategoriesHeaderRefs(menuStore.categories.reduce((acc, item) => {
            acc[item.id] = React.createRef();
            return acc;
        }, {} as { [s: string]: any }))
    }, [menuStore.categories]);

    useEffect(() => {
        const ref = categoriesHeaderRefs[menuStore.activeCategory];

        if (ref && ref.current && generalMenuStore.scroll) {
            window.scrollTo({left: 0, top: ref.current.offsetTop + 40, behavior: 'smooth'});
            setTimeout(() => {
                generalMenuStore.resetScroll();
            }, 600)
        }
    }, [menuStore.activeCategory]);


    useEffect(() => {
        if (generalMenuStore.scroll) return;

        const visibles = Object.entries(categoryData).filter(([key, item]) => item.visible).map(([key, item]) => {
            return {
                id: parseInt(key),
                ...item
            }
        });

        if (visibles.length === 1) {
            if (menuStore.activeCategory !== visibles[0].id) {
                const category = menuStore.categories.find(item => item.id === visibles[0].id);
                menuStore.setActiveCategory(visibles[0].id, false);
            }
        } else if (visibles.length > 0) {
            const sorted = visibles.sort((a, b) => Math.abs(a.offset) - Math.abs(b.offset));
            if (menuStore.activeCategory !== sorted[0].id) {
                const category = menuStore.categories.find(item => item.id === sorted[0].id);
                menuStore.setActiveCategory(sorted[0].id, false);
            }
        } else {
            //console.log(visibles);
        }

    }, [categoryData]);


    return <div className={styles.container}>
        <SideMenu/>
        <div>
            {categories.map(item => {
                if (menuStore.menu.data.themeSettings.storefrontPhotosMode === MenuItemsPhotosModes.defaultNoPhotos) {
                    return <CategoryContentNoPhoto
                        key={item.id}
                        categoryId={item.id}
                        ref={categoriesHeaderRefs[item.id]}
                        setIsVisible={(categoryId: number, isVisible: boolean, offset: number) => {
                            const data = categoryData[categoryId] || {
                                visible: false, offset: 10000
                            };
                            data.visible = isVisible;
                            data.offset = isVisible ? offset : 10000;
                            setCategoryData({
                                ...categoryData,
                                [categoryId]: data
                            });

                        }}
                    />
                } else {
                    return <CategoryContentWithPhoto
                        key={item.id}
                        categoryId={item.id}
                        ref={categoriesHeaderRefs[item.id]}
                        setIsVisible={(categoryId: number, isVisible: boolean, offset: number) => {
                            const data = categoryData[categoryId] || {
                                visible: false, offset: 10000
                            };
                            data.visible = isVisible;
                            data.offset = isVisible ? offset : 10000;
                            setCategoryData({
                                ...categoryData,
                                [categoryId]: data
                            });

                        }}
                    />
                }

            })}
        </div>
    </div>
};


export default observer(MenuItemsList);
