import { useHistory } from "react-router";
import { FlexRowStart } from "../../containers/containers";
import backButtonIosIcon from "../../../assets/back-button-ios-white.svg"
import style from "./style.module.sass"
import { useTranslation } from "react-i18next";


const BackButtonHeader = (props: { to?: string, title?: string }) => {
    const history = useHistory();
    const {t} = useTranslation(["general"]);
    const to = props.to;
    return <div className={style.backButtonContainer}>
        <FlexRowStart>
            {to ? <div className={style.backButton} onClick={() => history.push(to)}>
                    <img src={backButtonIosIcon} className={style.img}/>
                    <span className={style.title}>
                        {props.title || t("general:back")}
                    </span>
                </div>
                :
                <div onClick={() => history.goBack()} className={style.backButton}>
                    <img src={backButtonIosIcon} className={style.img}/>
                    <span className={style.title}>
                        {props.title || t("general:back")}
                    </span>
                </div>
            }
        </FlexRowStart>
    </div>
};

export default BackButtonHeader;
