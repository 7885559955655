import {BadgeType, BookingBadgeType, MenuBookingItem, MenuItem} from "../../../../services/menu/models";


export const getBadges = (item: MenuItem) => {
    return {
        [BadgeType.new]: {
            isEnabled: item.data.badges[BadgeType.new]?.isEnabled || false
        },
        [BadgeType.verySpicy]: {
            isEnabled: item.data.badges[BadgeType.verySpicy]?.isEnabled || false
        },
        [BadgeType.spicy]: {
            isEnabled: item.data.badges[BadgeType.spicy]?.isEnabled || false
        },
        [BadgeType.vegetarian]: {
            isEnabled: item.data.badges[BadgeType.vegetarian]?.isEnabled || false
        },
        [BadgeType.hit]: {
            isEnabled: item.data.badges[BadgeType.hit]?.isEnabled || false
        }
    }
}

export const getBookingBadges = (item: MenuBookingItem) => {
    return {
        [BookingBadgeType.new]: {
            isEnabled: item.data.badges[BookingBadgeType.new]?.isEnabled || false
        },
        [BookingBadgeType.profitable]: {
            isEnabled: item.data.badges[BookingBadgeType.profitable]?.isEnabled || false
        },
        [BookingBadgeType.hit]: {
            isEnabled: item.data.badges[BookingBadgeType.hit]?.isEnabled || false
        }
    }
}
