import style from "../style.module.sass"
import { useStores } from "../../../../../hooks";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import waiter from "./../assets/waiterCalled.svg";
import OrdinaryCircleLoader from "../../../../../components/ordinaryCircleLoader";
import {MenuAvailableLanguages} from "../../../../../services/menu/models";
import CloseButton from "../../../../../components/buttons/closeButton";


const checkGeneralLocal = (availableLanguages: string[], currentLang: string) => {
    let check;

    for (let i = 0; i < availableLanguages.length; i++) {
        if (currentLang === availableLanguages[i]) {
            check = true;
            break;
        } else {
            check = false;
        }
    }
    return check;
}

const getAvailableLanguages = (availableLanguages: MenuAvailableLanguages) => {
    const newArray = [];
    for (let [key, value] of Object.entries(availableLanguages)) {
        if (value.isEnabled) {
            newArray.push(key);
        }
    }
    return newArray;
}

const ChangeLangs = () => {
    const { bookingMenuStore, generalStore, generalMenuStore } = useStores();
    const { i18n, t } = useTranslation(['menu']);
    const isLangsModalOpen = generalMenuStore.isLangsModalOpen;
    const [availableLanguages, setAvailableLangs] = useState<string[]>([])
    const [topOffset, setTopOffset] = useState(60);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const blockRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        if (!bookingMenuStore.bookingMenu) return;
        const availableLangs = getAvailableLanguages(bookingMenuStore.bookingMenu.data.availableLanguages)
        let currentLang = generalStore.locale.id;
        const check = checkGeneralLocal(availableLangs, generalStore.locale.id);
        if (!check) {
            currentLang = generalStore.getDefaultLanguage(availableLangs)
            generalStore.setLang(currentLang)
        }
        i18n.changeLanguage(currentLang);
        setAvailableLangs(availableLangs)
    }, [bookingMenuStore.bookingMenu])

    useEffect(() => {
        if (!isLangsModalOpen) {
            setTopOffset(window.innerHeight);
            setOpacity(0);
            setHorizontalOffset(0);
        }else{
            if(blockRef.current){
                setTopOffset(window.innerHeight - blockRef.current?.clientHeight);
            }
            setOpacity(1);
            setHorizontalOffset(0);
        }
    }, [isLangsModalOpen])

    const classes = [style.isLangsModalSection];
    if (isLangsModalOpen) {
        classes.push(style.openLangsModal)
    }

    const closeLangsModal = () => {
        generalMenuStore.isLangsModalOpen = false;
        generalMenuStore.isOverlayOpen = false;
        generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    };
    return (
        <>
            <div className={classes.join(" ")}
                 style={{
                     top: topOffset,
                     opacity,
                     left: horizontalOffset,
                     right: horizontalOffset,
                 }}
            >
                <div ref={blockRef}>
                    <div className={style.lang}>
                        <p>{t("menu:languages.languageChoice")}</p>
                        <p>{t("menu:languages.menuLangs")}</p>
                    </div>

                    {availableLanguages.map(availableLang => {
                        const chosenLang = [style.chosenLanguage];
                        if (generalStore.locale.id ===availableLang) chosenLang.push(style.chosen);
                        return <div className={style.languages} key={availableLang}>
                            <div className={style.typeOfLanguage}
                                 onClick={() => {
                                     i18n.changeLanguage(availableLang);
                                     generalStore.setLang(availableLang);
                                     closeLangsModal();
                                 }}>
                                {availableLang === "ru" &&
									<p className={chosenLang.join(" ")}><span className={style.langEmoji}>&#x1F1F7;&#x1F1FA; </span><span
										style={{ lineHeight: "20px" }}>Русский</span></p>}
                                {availableLang === "en" &&
									<p className={chosenLang.join(" ")}><span className={style.langEmoji}>&#x1F1FA;&#x1F1F8; </span><span
										style={{ lineHeight: "20px" }}>English</span></p>}
                                {availableLang === "it" &&
									<p className={chosenLang.join(" ")}><span className={style.langEmoji}>&#127470;&#127481; </span><span
										style={{ lineHeight: "20px" }}>Italiana</span></p>}
                                {availableLang === "zh" &&
									<p className={chosenLang.join(" ")}><span className={style.langEmoji}>&#127464;&#127475; </span><span
										style={{ lineHeight: "20px" }}>中国人</span></p>}
                            </div>
                        </div>

                    })}

                    <div style={{
                        padding: 10
                    }}>
                        <CloseButton onClick={closeLangsModal}/>
                    </div>
                </div>

            </div>

        </>
    )
}

export default observer(ChangeLangs);
