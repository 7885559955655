import style from "./style.module.sass"
import {observer} from "mobx-react";
import React, {useState} from "react";
import {useStores} from "../../../../hooks";
import {useTranslation} from "react-i18next";
import logo from "../../../../assets/restify-logo-green.png";

const InfoMessagesModal = () => {
    const {menuStore} = useStores();
    const [close, setClose] = useState(false);
    const {t, i18n} = useTranslation(['general', 'menu'])

    const locale = i18n.language;
    const infoMessage = menuStore.menu?.data?.infoMessages?.[0]
    const buttonsColor = menuStore.menu?.data?.themeSettings?.buttonsBackgroundColor || '#FF2C3D'

    const isMessageEnabled = () => {
        if (!infoMessage?.isEnabled) return false
        if (!infoMessage?.title?.[locale]?.trim()) return false

        const infoMessageShowedAt = menuStore.infoMessageShowedAt

        if (infoMessageShowedAt) {
            const now = new Date()
            //@ts-ignore
            const diff = Math.floor(((now - infoMessageShowedAt)/1000)/60);

            // 2 hours
            if (diff <= 10) {
                return false
            }

        }

        return true
    }

    const showImage = () => {
        return infoMessage?.mediaItems?.[0]?.url?.trim();

    }

    const classes = [style.workingTimeInfo];
    if (close) classes.push(style.close);

    const isWhiteList = () => {
        return [
            738 // under
        ].includes(menuStore?.menu?.organizationBranchId)
    }

    return (
        <>
            {isMessageEnabled() ?
                <div className={classes.join(" ")}>
                    <div className={style.workingTimeContainer}>
                        <div className={style.infoContainer}>
                            {showImage() && (
                                <img
                                    className={style.imageBlock}
                                    src={infoMessage?.mediaItems?.[0]?.url || ""}
                                    alt="infoImage"/>
                            )}

                            <div>
                                <h4>{infoMessage?.title?.[locale] || ""}</h4>
                                <p>
                                    {infoMessage?.description?.[locale] || ""}
                                </p>
                            </div>
                        </div>
                        <div className={style.button} style={{background: buttonsColor}}
                             onClick={() => {
                                 menuStore.setInfoMessageShowedAt(new Date())
                                 setClose(!close)
                             }}>
                            {infoMessage?.callToAction?.[locale] || t('menu:openMenu')}
                        </div>
                    </div>
                    {!isWhiteList() && (
                        <div className={style.footer}>
                            <div onClick={() => {
                                window.open("https://restify.one")
                            }}>
                                <img src={logo} alt=""/>
                            </div>
                            <p>
                                {t("general:footer.description")}
                            </p>
                        </div>
                    )}
                </div>

                :
                <></>
            }
        </>
    )
}

export default observer(InfoMessagesModal);
