import {BookingOrder, Order, PaymentProviderType} from "../../services/order/models";
import useCloudPayments from "./useCloudpayments";
import useSberbank from "./useSberbank";
import { rootStore } from "../../store";
import { EventType, NewEventName } from "../../services/general/models";
import { useStores } from "../index";


const usePayments = () => {
    const { generalStore } = useStores();
    const { initCharge: initCloudpayments } = useCloudPayments();
    const { initCharge: initSberbank } = useSberbank();

    const initCharge = (type: PaymentProviderType, order: Order | BookingOrder, params: { onSuccess: () => void; onFail: () => void }) => {
        const event = {
            metainfo: null,
            name: NewEventName.checkout,
            type: EventType.conversion
        }

        if (type === PaymentProviderType.sberbank) {
            generalStore.addNewEvent({
                ...event,
                metainfo: JSON.stringify({
                    provider: PaymentProviderType.sberbank
                })
            });
            return initSberbank(params, order);
        } else if (type === PaymentProviderType.cloudpayments) {
            generalStore.addNewEvent({
                ...event,
                metainfo: JSON.stringify({
                    provider: PaymentProviderType.cloudpayments
                })
            });
            return initCloudpayments(params, order);
        } else {
            rootStore.checkoutStore.reloadOrder();
            params.onSuccess();
            return;
        }
    }

    return { initCharge }
}

export default usePayments;
