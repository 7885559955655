import style from "./style.module.sass"
import { useStores } from "../../../../hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import React from "react";
import BackButtonHeader from "../../../../components/buttons/backButtonHeader";
import closeIcon from "../../../../assets/close-icon.svg";
import infoIcon from './assets/info.svg';
import waiterIcon from './assets/waiter.svg';
import langsIcon from './assets/langs.svg';
import { OrderStatus } from "services/order/models";
import { useHistory } from "react-router";
import InfoIcon from './components/InfoIcon';
import {LangsIcon} from './components/LangsIcon';
import {WaiterIcon} from './components/WaiterIcon';

const Header = (props: { fromCatalog: boolean }) => {
    const { t, i18n } = useTranslation(["general", "menu"]);
    const { menuStore, generalMenuStore, catalogStore, ordersStore, generalStore } = useStores();
    const history = useHistory();

    const orders = ordersStore.orders;
    const notFinishedOrders = orders.filter((item) => {
        return (item.menuId === generalStore.menuId) && (item.status === OrderStatus.new);
    })

    const showInfoDetailes = () => {
        generalMenuStore.openInfoDetailesModal(() => {
            generalMenuStore.closeInfoDetailesModal()
        });
    };
    const showWaiterWanted = () => {
        menuStore.openWaiterWantedModal(() => {
            menuStore.closeWaiterWantedModal()
        });
    };
    const showLangs = () => {
        generalMenuStore.openLangsModal(() => {
            generalMenuStore.closeLangsModal()
        });
    };

    const dom8OrgBranch = 699;

    const buttonsColor = menuStore.menu?.data?.themeSettings?.buttonsBackgroundColor || '#FF2C3D'



    return <div className={style.header}>
        {props.fromCatalog && <BackButtonHeader to={`/catalog/${catalogStore.aliasBinding.alias}?tableId=${generalStore.tableId}`}
                                                title={t('general:backToCatalog')}/>}

        {menuStore.menu.data.photos.backgroundPhotoUrl !== null ?
            <>
                <div className={style.backgroundPhoto}>
                    <img src={menuStore.menu.data.photos.backgroundPhotoUrl} alt='background'/>
                    <div className={style.backgroundPhotoShaddow}/>
                    <div className={style.nameAndDescription}>
                        <div className={style.menuName}>
                            <div className={style.logoName}>
                                {menuStore.menu.data.photos.logoPhotoUrl !== null &&
                                    <div className={[style.logoTablet, props.fromCatalog ? style.mt20 : ""].join(" ")}>
                                        <img src={menuStore.menu.data.photos.logoPhotoUrl} alt=''
                                             onClick={() => history.push("/orders")}
                                        />
                                        {notFinishedOrders.length !== 0 &&
                                            <div className={style.orderReminder}>
                                                {notFinishedOrders.length}
                                            </div>}

                                    </div>
                                }
                                <div className={style.name}>
                                    <p className={style.text}>{menuStore.menu.data.name[i18n.language]}</p>
                                    <label>
                                        <input type='checkbox'/>
                                        <p className={style.description}>{menuStore.menu.data.description[i18n.language]}</p>
                                    </label>
                                </div>
                            </div>
                            <div className={style.iconButtons}>
                                {menuStore.isWaiterCallAvailable && <WaiterIcon
                                      fill={buttonsColor}
                                      onClick={showWaiterWanted}/>}
                                {menuStore.isChooseLangsEnabled && <LangsIcon fill={buttonsColor}
                                      onClick={showLangs}/>}
                                {menuStore.menu?.organizationBranchId != dom8OrgBranch && <InfoIcon
                                     fill={buttonsColor}
                                     onClick={showInfoDetailes}/>}
                            </div>
                        </div>

                    </div>
                    <div className={style.iconButtonsDesktop}>
                        {menuStore.isWaiterCallAvailable && <WaiterIcon
                            fill={buttonsColor}
                            onClick={showWaiterWanted}/>}
                        {menuStore.isChooseLangsEnabled && <LangsIcon fill={buttonsColor}
                                                                      onClick={showLangs}/>}
                        {menuStore.menu?.organizationBranchId != dom8OrgBranch && <InfoIcon
                            fill={buttonsColor}
                            onClick={showInfoDetailes}/>}
                    </div>
                </div>
            </>
            :
            <div style={{ padding: '5px 17px' }}>
                <div className={style.menuNameWithNoBackground}>
                    <p className={style.text}>{menuStore.menu.data.name[i18n.language]}</p>
                    <div className={style.infoIcon}
                         onClick={showInfoDetailes}>
                        <img src={infoIcon} alt=""/>
                    </div>
                </div>
                <label>
                    <input type='checkbox'/>
                    <p className={style.description}
                       style={{ marginTop: 5 }}>{menuStore.menu.data.description[i18n.language]}</p>
                </label>
            </div>

        }
        {(menuStore.menu.data.photos.backgroundPhotoUrl !== null && menuStore.menu.data.photos.logoPhotoUrl !== null) &&
        <div className={[style.logo, props.fromCatalog ? style.mt20 : ""].join(" ")}>
            <img src={menuStore.menu.data.photos.logoPhotoUrl} alt=''
                 onClick={() => history.push("/orders")}
            />
            {notFinishedOrders.length !== 0 &&
            <div className={style.orderReminder}>
                {notFinishedOrders.length}
            </div>}

        </div>
        }

        {
            generalStore.isWidget && <div
                className={style.closeButton}
                onClick={() => {
                    window.parent.postMessage("closeWidget", "*")
                }}
            >
                <img src={closeIcon} alt=""/>
            </div>

        }
    </div>
};

export default observer(Header);
