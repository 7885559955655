import style from "../style.module.sass";
import {useTranslation} from "react-i18next";


const NewBadge = () => {
    const { i18n, t } = useTranslation(["cart"]);
    return (
        <div className={style.newBadge}>
            <p>New</p>
        </div>
    )
}

export default NewBadge;
