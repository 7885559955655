import React, { useEffect } from "react"
import { observer } from "mobx-react";
import { useStores } from "../../hooks";
import { useHistory, useParams } from "react-router";
import { Loader } from "../../components/loader";
import Header from "./components/header";
import CatalogItems from "./components/catalogItems";
import { MenuMode } from "../../store/general";
import RestifyFooter from "../../components/restifyFooter";
import useQuery from "../../hooks/useQuery";

const CatalogPage = () => {
    const { catalogStore, generalStore, isInited } = useStores();
    const history = useHistory();


    const params = useParams<{
        alias: string
        tableId: string
    }>()

    const tableId = useQuery<{
        tableId?: string
    }>();


    useEffect(() => {
        catalogStore.init(params.alias);

        generalStore.setMode({
            mode: MenuMode.catalog,
            catalogAlias: params.alias
        })
        if (tableId.tableId) generalStore.setTableId(tableId.tableId)
    }, [params.alias, isInited, tableId.tableId]);

    useEffect(() => {
        if(!catalogStore.isReady) return;
        if(catalogStore.aliasBinding.data.aliasedMenus.length === 1){
            history.replace(`/${params.alias}`);
        }
    }, [catalogStore.isReady])


    if (!catalogStore.isReady) {
        return <div style={{
            height: "100vh",
            width: "100vw",
            maxWidth: 500,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
        }}>
            <Loader/>
        </div>
    }

    return <div style={{width: "100%", backgroundColor: "#F7F7F7"}}>

    <div style={{maxWidth: "1000px", margin: "auto", backgroundColor: "white", padding: "0, 20px"}}>
        <Header/>
        <CatalogItems/>
        <RestifyFooter/>
    </div>
    </div>
};


export default observer(CatalogPage);
