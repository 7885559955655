import {action, computed, makeAutoObservable, runInAction} from "mobx"
import {rootStore} from "./index";
import {BookingOrder, Order, OrderStatus} from "../services/order/models";
import OrdersService from "../services/order";
import {saveStoreToLocalStorage} from "./persistence";
import {MenuType, Table} from "../services/menu/models";


class OrdersStore {

    orders: Order[] = [];
    bookingOrders: BookingOrder[] = [];
    currentOrder?: Order;
    currentBookingOrder?: BookingOrder;
    isLoaded: boolean = false;
    table?: Table;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setData(obj: { orders: any[], bookingOrders: any[] }) {
        this.orders = obj.orders;
        this.bookingOrders = obj.bookingOrders;
    }

    static fromJSON(obj: any) {
        const ordersStore = new OrdersStore();
        ordersStore.setData({
            orders: obj.orders || [],
            bookingOrders: obj.bookingOrders || []
        });
        return ordersStore;
    }

    @action
    addOrder(order: Order | BookingOrder, type: MenuType) {
        if (type === MenuType.regular) {
            this.orders.push(order as Order)
        } else {
            this.bookingOrders.push(order as BookingOrder)
        }
        saveStoreToLocalStorage()
    }


    async setCurrentOrder(orderId: number, orgId: number, branchId: number, type: MenuType) {
        if (type === MenuType.regular) {
            const getOrderResponse = await OrdersService.getOrderById(orderId,
                orgId,
                branchId
            );
            runInAction(() => {
                this.currentOrder = getOrderResponse.order || undefined;
                this.table = getOrderResponse.table;
            })
        } else {
            const getOrderResponse = await OrdersService.getBookingOrder(
                {
                    orderId:  orderId,
                    organizationBranchId: branchId,
                    organizationId: orgId,
                }
            );
            runInAction(() => {
                this.currentBookingOrder = getOrderResponse.order || undefined;
            })
        }

    }


    @action
    forceReload() {
        this.isLoaded = false;
    }



    async reloadCurrentOrder(type: MenuType) {
        const store = rootStore;
        let id;
        if (type === MenuType.regular) {
            if (!this.currentOrder) return;
            id = this.currentOrder.id;
        } else {
            if (!this.currentBookingOrder) return;
            id = this.currentBookingOrder.id;
        }

            await this.setCurrentOrder(id,
                store.generalStore.organizationId,
                store.generalStore.organizationBranchId,
                type
            );

    }


    async reloadOrdersHistory( type: MenuType ){
        try{
            const { menuId, organizationBranchId, organizationId } = rootStore.generalStore;
            let promises;
            if (type === MenuType.regular) {
               promises = this.orders.map( async (order) => {
                    if(order.menuId === menuId){
                        // reload
                        const getOrderResponse = await OrdersService.getOrderById(order.id, organizationId, organizationBranchId);
                        if(getOrderResponse.order){
                            return {
                                ...getOrderResponse.order,
                                deliveryDetails: order.deliveryDetails,
                                clientId: order.clientId
                            }
                        }else{
                            return order;
                        }

                    }else{
                        return order;
                    }
                });
            } else {
                promises = this.bookingOrders.map( async (order) => {
                    if(order.menuBookingId === menuId){
                        // reload
                        const getOrderResponse = await OrdersService.getBookingOrder({
                            orderId: order.id,
                            organizationBranchId: organizationBranchId,
                            organizationId: organizationId,
                        });
                        if(getOrderResponse.order){
                            return {
                                ...getOrderResponse.order,
                                clientId: order.clientId
                            }
                        }else{
                            return order;
                        }

                    }else{
                        return order;
                    }
                });
            }

            const updatedList = await Promise.all(promises);

            runInAction(() => {
                if (type === MenuType.regular)  this.orders = <Order[]>updatedList;
                else this.bookingOrders = <BookingOrder[]>updatedList;
                saveStoreToLocalStorage();
            })
        }catch (err){
            console.log(err);
        }
    }

    @computed
    getOrdersByMenuId(menuId: number, type: MenuType){
        if (type === MenuType.regular) {
            return this.orders.filter(order => (order.menuId === menuId && order.status !== OrderStatus.preorder)).sort((a, b) => {
                return (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()
            });
        } else {
            return this.bookingOrders.filter(order => (order.menuBookingId === menuId && order.status !== OrderStatus.preorder)).sort((a, b) => {
                return (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()
            });
        }

    };
}

export default OrdersStore
