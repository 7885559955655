import style from "./../style.module.sass"
import {useTranslation} from "react-i18next";


const NewBadgeOverPhoto = () => {
    const { i18n, t } = useTranslation(["cart"]);
    return (
        <div className={style.newBadgeOverPhoto}>
            <p>New</p>
        </div>
    )
}

export default NewBadgeOverPhoto;
