interface IProps {
    fill: string
    onClick: any
}
const InfoIcon = (props: IProps) => {
    return (
        <svg onClick={props.onClick} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="white"/>
            <path d="M23.0116 20.002C21.9073 20.002 21.1221 20.5009 21.1221 21.236V31.2389C21.1221 31.869 21.9073 32.499 23.0116 32.499C24.0669 32.499 24.9257 31.869 24.9257 31.2389V21.2359C24.9257 20.5008 24.0669 20.002 23.0116 20.002Z" fill={props.fill}/>
            <path d="M23.0122 13.8325C21.8834 13.8325 21 14.6989 21 15.6966C21 16.6943 21.8834 17.587 23.0122 17.587C24.1165 17.587 25 16.6943 25 15.6966C25 14.6989 24.1164 13.8325 23.0122 13.8325Z" fill={props.fill}/>
        </svg>
    )
}

export default InfoIcon

