import style from "./style.module.sass"
import {useStores} from "../../hooks";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";


import logo from "../../assets/restify-logo-green.png"

const RestifyFooter = () => {
    const {i18n, t} = useTranslation(["general"]);

    const {catalogStore, menuStore} = useStores();

    // console.log(menuStore?.menu?.organizationBranchId)
    const isWhiteList = () => {
        return [
            738 // under
        ].includes(menuStore?.menu?.organizationBranchId)
    }

    return isWhiteList() ? <></> : <div className={style.footer}>
        <div onClick={() => {
            window.open("https://restify.one")
        }}>
            <img src={logo} alt=""/>
        </div>
        <p>
            {t("general:footer.description")}
        </p>
    </div>
};

export default observer(RestifyFooter);
