import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['navigator'],
    lookupQuerystring: 'lng'
}



i18n
    .use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        supportedLngs: ['ru', 'en', 'zh', 'it'],
        fallbackLng: "ru",
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
            useSuspense: false,
        },
        detection: options
    });



export default i18n;
