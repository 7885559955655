import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import Header from "./components/header";
import CategoriesTab from "./components/categories";
import MenuItemsList from "./components/menuItems"
import ModifiersModal from "./components/modifiersModal"
import Overlay from "./components/overlay"
import ToCheckoutButton from "./components/toCheckoutButton";
import { useStores } from "../../hooks";
import useQuery from "../../hooks/useQuery";
import useAlias from "../../hooks/useAlias";
import OrderingNotAvailable from "../orderingNotAvailable";
import { useHistory } from "react-router";
import { MenuMode } from "../../store/general";
import RestifyFooter from "../../components/restifyFooter";
import CategoriesBurgerMenu from "./components/categories/components/categoriesBurgerMenu";
import useCategoryIdAndItemId from "../../hooks/useCategoryIdAndItemId";
import InfoDetails from "./components/header/components/InfoDetails";
import { dropLocalState } from "../../store/persistence";
import RestifyLoader from "../restifyLoader";

import NonWorkingHours from "./components/nonWorkingHours";

import { EventType, NewEventName } from "../../services/general/models";
import WaiterCall from "./components/header/components/WaiterCall";
import ChangeLangs from "./components/header/components/ChangeLangs";
import InfoMessagesModal from './components/infoMessagesModal';


let timeout: any;
const MenuView = () => {

    const { menuStore, generalStore, generalMenuStore, isInited } = useStores();
    const [isFirstLunch, setIsFirstLunch] = useState(true);

    const [alias, isAliasReady] = useAlias();


    const history = useHistory();

    const params = useQuery<{
        menuId: string,
        orgId: string,
        branchId: string,
        tableId?: string,
        pointId?: string,
        viewOnly?: string,
        isWidget?: string
    }>();

    const routerParams = useCategoryIdAndItemId();


    useEffect(() => {
        (async () => {
            if (!menuStore.isLoaded) return;
            const params = {
                metainfo: null,
                name: NewEventName.menuVisit,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        })()
    }, [menuStore.isLoaded])


    useEffect(() => {

        if (!menuStore.isLoaded) return;
        if (isFirstLunch) {
            setTimeout(() => {
                if (routerParams.categoryId) {
                    menuStore.setActiveCategory(parseInt(routerParams.categoryId), true);
                }
                setIsFirstLunch(false);
            }, 600)
            setTimeout(() => {
                if (routerParams.itemId && routerParams.categoryId) {

                    const item = menuStore.items[routerParams.categoryId].find((t) => t.id.toString() === routerParams.itemId);
                    if (item) {
                        const name = item.data.name['ru'];
                        const menuTitle = menuStore.menu.data.name['ru'];
                        const menuDescription = menuStore.menu.data.description['ru'];

                        const description = item.data.description['ru'];
                        menuStore.updateSeoInfo({
                            name: `${name} в ${menuTitle}` || `${menuTitle} - позиция ${item.id}`,
                            description: description || `${menuDescription} - позиция ${item.id}`
                        });

                        menuStore.openModifiersPopup(item, () => {
                            menuStore.closeModifiersPopup();
                            const t = history.location.pathname.split("/");
                            const baseUrl = `/${t[0]}${history.location.search}`
                            history.replace(baseUrl);
                            menuStore.updateSeoInfo({
                                name: menuStore.menu.data.name['ru'],
                                description: menuStore.menu.data.description['ru'],
                            });
                        });
                    }

                }
                setIsFirstLunch(false)
            }, 800);
        } else {
            if (routerParams.itemId && routerParams.categoryId) {
                const item = menuStore.items[routerParams.categoryId].find((t) => t.id.toString() === routerParams.itemId);
                if (item) {
                    const name = item.data.name['ru'];
                    const menuTitle = menuStore.menu.data.name['ru'];
                    const menuDescription = menuStore.menu.data.description['ru'];

                    const description = item.data.description['ru'];
                    menuStore.updateSeoInfo({
                        name: `${name} в ${menuTitle}` || `${menuTitle} - позиция ${item.id}`,
                        description: description || `${menuDescription} - позиция ${item.id}`
                    });
                    menuStore.openModifiersPopup(item, () => {
                        menuStore.closeModifiersPopup();
                        history.goBack();
                        menuStore.updateSeoInfo({
                            name: menuStore.menu.data.name['ru'],
                            description: menuStore.menu.data.description['ru'],
                        });
                    });
                }

            }
        }


        if (!routerParams.itemId) {
            menuStore.closeModifiersPopup();
        }
    }, [menuStore.isLoaded, routerParams.itemId, routerParams.categoryId])

    useEffect(() => {
        let options = {
            organizationId: parseInt(params.orgId),
            organizationBranchId: parseInt(params.branchId),
            menuId: parseInt(params.menuId),
            tableId: params.tableId || "",
            pointId: params.pointId || "",
            viewOnly: !!params.viewOnly,
            isWidget: !!params.isWidget
        };


        if (!menuStore.isLoaded && isInited && isAliasReady) {
            if (alias && alias.data.aliasedMenus.length === 1) {
                const aliasedMenu = alias.data.aliasedMenus[0];
                options.menuId = aliasedMenu.menuId;
                options.organizationId = aliasedMenu.organizationId;
                options.organizationBranchId = aliasedMenu.organizationBranchId;
                generalStore.setAlias(alias.alias);
            } else if (alias && alias.data.aliasedMenus.length > 1) {
                history.replace(`/catalog/${alias.alias}?tableId=${options.tableId}`);
                return;
            } else {
                generalStore.setAlias(null);
            }

            if (
                (isNaN(options.menuId) ||
                    isNaN(options.organizationBranchId) ||
                    isNaN(options.organizationId)) && isInited
            ) {
                if (!isNaN(generalStore.menuId) &&
                    generalStore.menuId > 0 &&
                    !isNaN(generalStore.organizationId) &&
                    !isNaN(generalStore.organizationBranchId)) {
                    history.replace(`/menu?menuId=${generalStore.menuId}&branchId=${generalStore.organizationBranchId}&orgId=${generalStore.organizationId}`)
                    return;
                } else {
                    history.replace('/enter-alias');
                    menuStore.setError("Не найдено меню");
                    return;
                }
            }

            generalStore.init(options).then(() => {
                return menuStore.init({
                    organizationId: options.organizationId,
                    organizationBranchId: options.organizationBranchId,
                    menuId: options.menuId,
                });
            }).then(() => {
                generalMenuStore.setMenu(menuStore.menu);
                if (alias && alias.data.aliasedMenus.length === 1) {
                    const name = menuStore.menu.data.name['ru'];
                    const description = menuStore.menu.data.description['ru'];
                    const photo = menuStore.menu.data.photos.logoPhotoUrl;
                    menuStore.updateManifest(alias.alias, name, description, photo);
                }
            })
        }

        if ((options.menuId !== generalStore.menuId
            || options.organizationId !== generalStore.organizationId
            || options.organizationBranchId !== generalStore.organizationBranchId
        ) && !alias) {
            menuStore.setIsLoaded(false)
        }

    }, [params, menuStore, generalStore, isAliasReady, isInited, menuStore.isLoaded]);


    if (!menuStore.isLoaded || !isInited) {
        if (!timeout) {
            timeout = setTimeout(() => {
                // drop state
                dropLocalState();
                window.location.reload();
            }, 10000);

        }
    }

    if (!generalStore.isMenuAvailable) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = undefined;
        }
        return <OrderingNotAvailable/>
    }

    if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
    }

    return <div>
        {
            menuStore.isLoaded && isInited && <>
				<NonWorkingHours/>
                <InfoMessagesModal/>
				<Header fromCatalog={generalStore.modeData.mode === MenuMode.catalog}/>
				<InfoDetails/>
				<WaiterCall/>
				<ChangeLangs/>
				<CategoriesTab/>
				<MenuItemsList/>
				<ModifiersModal/>
				<Overlay/>
				<ToCheckoutButton/>
				<CategoriesBurgerMenu/>
				<RestifyFooter/>
			</>
        }
        {
            !menuStore.isLoaded && <RestifyLoader show={true}/>
        }
    </div>
};


export default observer(MenuView);
