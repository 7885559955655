import style from "../style.module.sass"
import { useStores } from "../../../../../hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import waiter from "./../assets/waiterCalled.svg";
import OrdinaryCircleLoader from "../../../../../components/ordinaryCircleLoader";

const WaiterCall = () => {
    const { menuStore, generalStore, generalMenuStore } = useStores();
    const { i18n, t } = useTranslation(['menu', 'cart']);
    const isWaiterWanted = menuStore.isWaiterWanted;
    const isWaiterCalled = menuStore.isWaiterCalled;
    const tableId = generalStore.tableId;
    const [topOffset, setTopOffset] = useState(60);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const [isWaiterButtonPushed, setIsWaiterButtonPushed] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null)

    const buttonsColor = menuStore.menu?.data?.themeSettings?.buttonsBackgroundColor || '#FF2C3D'


    useEffect(() => {


        if (!isWaiterWanted) {
            menuStore.setIsWaiterCalled(false);
            setIsWaiterButtonPushed(false);
            setTopOffset(window.innerHeight);
            setOpacity(0);
            setHorizontalOffset(0);
        } else {
            if(blockRef.current){
                setTopOffset(window.innerHeight - blockRef.current?.clientHeight - 20);
            }
            setOpacity(1);
            setHorizontalOffset(0);
        }
    }, [isWaiterWanted, blockRef.current]);


    const classes = [style.isWaiterWantedSection];
    if (isWaiterWanted) {
        classes.push(style.openWaiterCall)
    }

    const classesButton = [style.waiterButton];
    let title;
    if (tableId && !isWaiterButtonPushed) {
        title = <>{t("menu:waiter.waiterCall")}</>
    } else if (tableId && isWaiterButtonPushed) {
        classesButton.push(style.disabled);
        title = <></>
    } else if (!tableId) {
        classesButton.push(style.disabled);
        title = <>{t("menu:waiter.impossibleCall")}</>
    }

    const closeWaiterWanted = () => {
        menuStore.isWaiterWanted = false;
        generalMenuStore.isOverlayOpen = false;
        generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    };
    const waiterCall = async () => {
        if (tableId) {
            setIsWaiterButtonPushed(true);
            await menuStore.callWaiter()
            if (isWaiterCalled) setIsWaiterButtonPushed(false)
        }
    };

    const table = menuStore.getTableByPublicId(tableId);
    return (
        <>
            <div className={classes.join(" ")}
                 style={{
                     top: topOffset,
                     opacity,
                     left: horizontalOffset,
                     right: horizontalOffset,
                 }}
            >
                {!isWaiterCalled ?
                    <div className={style.waiter} ref={blockRef}>
                        <p>{t("menu:waiter.waiterCall")}</p>
                        <p>{t("menu:waiter.waiterCame")}</p>
                        <div className={style.table}>{table?.data.title[i18n.language]}</div>
                        <div className={classesButton.join(" ")}
                             style={{background: buttonsColor}}
                             onClick={waiterCall}
                        >
                            <span>{title}</span>
                            {isWaiterButtonPushed &&
								<OrdinaryCircleLoader color={`${buttonsColor} transparent transparent transparent`}/>}
                        </div>
                    </div>
                    :
                    <div className={style.waiterCalled}
                         onClick={closeWaiterWanted}
                         ref={blockRef}
                    >
                        <img src={waiter} alt='waiter called'/>
                        <p>{t("menu:waiter.waiterSoon")}</p>
                        <div className={style.buttonToMenu}>{t("menu:waiter.backToMenu")}</div>
                    </div>

                }

            </div>

        </>
    )
}

export default observer(WaiterCall);
