import { observer } from "mobx-react";
import style from "./style.module.sass"
import React, { useEffect, useState } from "react";
import loader from './rf-loader.svg'
import useMountTransition from "../../hooks/useMountTransition";


interface Props{
    show: boolean,
    text?: string
}
const RestifyLoader = ({ show, text }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(true);
    const hasTransitionedIn = useMountTransition(isMounted, 900);

    useEffect(() => {
        if(show){
            setIsLoading(true);
            setIsMounted(true);
        }else{
            setIsLoading(false);
            setTimeout(() => {
                setIsMounted(false)
            }, 500)
        }
    }, [show])
    const logoClasses = [style.logo];
    const blockClasses = [style.block];
    if (!isLoading) {
        logoClasses.push(style.active)
    }
    if(!isMounted && hasTransitionedIn){
        logoClasses.push(style.hide);
        blockClasses.push(style.hide)
    }

    if(!isMounted && !hasTransitionedIn) return null;

    return <div className={blockClasses.join(' ')}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className={logoClasses.join(" ")}>
                <div className={style.container}/>
                <div className={style.circle}>
                    <img src={loader} alt="" className={style.imgLoader}/>
                </div>

            </div>
            <div className={style.text}>
                {text}
            </div>
        </div>

    </div>

};


export default observer(RestifyLoader);
