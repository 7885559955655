import style from "./style.module.sass"
import { useStores } from "../../../../hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import React from "react";
import BackButtonHeader from "../../../../components/buttons/backButtonHeader";
import closeIcon from "../../../../assets/close-icon.svg";
import infoIcon from './assets/info.svg';
import waiterIcon from './assets/waiter.svg';
import langsIcon from './assets/langs.svg';
import { OrderStatus } from "services/order/models";
import { useHistory } from "react-router";

const Header = (props: { fromCatalog: boolean }) => {
    const { t, i18n } = useTranslation(["general", "menu"]);
    const {bookingMenuStore, generalMenuStore, catalogStore, ordersStore, generalStore } = useStores();
    const history = useHistory();

    const orders = ordersStore.bookingOrders;
    const notFinishedOrders = orders.filter((item) => {
        return (item.menuBookingId === generalStore.menuId) && (item.status === OrderStatus.new || item.status === OrderStatus.accepted);
    })

    const showInfoDetailes = () => {
        generalMenuStore.openInfoDetailesModal(() => {
            generalMenuStore.closeInfoDetailesModal()
        });
    };

    const showLangs = () => {
        generalMenuStore.openLangsModal(() => {
            generalMenuStore.closeLangsModal()
        });
    };



    return <div className={style.header}>
        {props.fromCatalog && <BackButtonHeader to={`/catalog/${catalogStore.aliasBinding.alias}`}
                                                title={t('general:backToCatalog')}/>}

        {bookingMenuStore.bookingMenu.data.photos.backgroundPhotoUrl !== null ?
            <>
                <div className={style.backgroundPhoto}>
                    <img src={bookingMenuStore.bookingMenu.data.photos.backgroundPhotoUrl} alt='background'/>
                    <div className={style.backgroundPhotoShaddow}/>
                    <div className={style.nameAndDescription}>
                        <div className={style.menuName}>
                            <div className={style.logoName}>
                                {bookingMenuStore.bookingMenu.data.photos.logoPhotoUrl !== null &&
                                    <div className={[style.logoTablet, props.fromCatalog ? style.mt20 : ""].join(" ")}>
                                        <img src={bookingMenuStore.bookingMenu.data.photos.logoPhotoUrl} alt=''
                                             onClick={() => history.push("/orders")}
                                        />
                                        {notFinishedOrders.length !== 0 &&
                                            <div className={style.orderReminder}>
                                                {notFinishedOrders.length}
                                            </div>}

                                    </div>
                                }
                                <div className={style.name}>
                                    <p className={style.text}>{bookingMenuStore.bookingMenu.data.name[i18n.language]}</p>
                                    <label>
                                        <input type='checkbox'/>
                                        <p className={style.description}>{bookingMenuStore.bookingMenu.data.description[i18n.language]}</p>
                                    </label>
                                </div>
                            </div>
                            <div className={style.iconButtons}>
                                {generalMenuStore.isChooseLangsEnabled && <img src={langsIcon} alt=""
                                      className={style.infoIcon}
                                      onClick={showLangs}/>}
                                <img src={infoIcon} alt=""
                                     className={style.infoIcon}
                                     onClick={showInfoDetailes}/>
                            </div>

                        </div>

                    </div>
                    <div className={style.iconButtonsDesktop}>

                        {generalMenuStore.isChooseLangsEnabled && <img src={langsIcon} alt=""
                                                                       className={style.infoIcon}
                                                                       onClick={showLangs}/>}
                        <img src={infoIcon} alt=""
                             className={style.infoIcon}
                             onClick={showInfoDetailes}/>
                    </div>
                </div>
            </>
            :
            <div style={{ padding: '5px 17px' }}>
                <div className={style.menuNameWithNoBackground}>
                    <p className={style.text}>{bookingMenuStore.bookingMenu.data.name[i18n.language]}</p>
                    <div className={style.infoIcon}
                         onClick={showInfoDetailes}>
                        <img src={infoIcon} alt=""/>
                    </div>
                </div>
                <label>
                    <input type='checkbox'/>
                    <p className={style.description}
                       style={{ marginTop: 5 }}>{bookingMenuStore.bookingMenu.data.description[i18n.language]}</p>
                </label>
            </div>

        }
        {(bookingMenuStore.bookingMenu.data.photos.backgroundPhotoUrl !== null && bookingMenuStore.bookingMenu.data.photos.logoPhotoUrl !== null) &&
        <div className={[style.logo, props.fromCatalog ? style.mt20 : ""].join(" ")}>
            <img src={bookingMenuStore.bookingMenu.data.photos.logoPhotoUrl} alt=''
                 onClick={() => history.push("/bookings")}
            />
            {notFinishedOrders.length !== 0 &&
            <div className={style.orderReminder}>
                {notFinishedOrders.length}
            </div>}

        </div>
        }

        {
            generalStore.isWidget && <div
                className={style.closeButton}
                onClick={() => {
                    window.parent.postMessage("closeWidget", "*")
                }}
            >
                <img src={closeIcon} alt=""/>
            </div>

        }
    </div>
};

export default observer(Header);
