import {observer} from "mobx-react";
import style from "./style.module.sass"
import {FadeInOutVertical} from "../../components/Animated";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useStores} from "../../hooks";
import {useTranslation} from "react-i18next";
import {BookingOrder, Order, PaymentProviderType} from "../../services/order/models";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copyIcon from "./assets/copy-solid.png"
import {Space} from "../../components/libs";
import {IToBankCardPaymentSettings, MenuType} from "../../services/menu/models";

const ToBankCardPaymentPage = () => {
    const history = useHistory();
    const {generalStore, menuStore, bookingMenuStore, generalMenuStore, ordersStore} = useStores();
    const params = useParams<{ orderId: string, orgId: string, branchId: string, menuId: string }>();

    const {t, i18n} = useTranslation(['general']);
    const [isReady, setIsReady] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const currentMenu = generalMenuStore.menu;
    let currentOrder: Order | BookingOrder | undefined;
    (currentMenu.type === MenuType.regular) ? currentOrder = ordersStore.currentOrder : currentOrder = ordersStore.currentBookingOrder;

    useEffect(() => {
        if (currentMenu.type === MenuType.regular) {
            menuStore.init({
                menuId: generalStore.menuId,
                organizationBranchId: generalStore.organizationBranchId,
                organizationId: generalStore.organizationId
            }).then(() => setIsReady(true));
        } else {
            bookingMenuStore.init({
                bookingMenuId: generalStore.menuId,
                organizationBranchId: generalStore.organizationBranchId,
                organizationId: generalStore.organizationId
            }).then(() => setIsReady(true));
        }

    }, []);

    useEffect(() => {
        (async () => {
            if (!currentOrder || currentOrder.id !== parseInt(params.orderId)) {
                await ordersStore.setCurrentOrder(
                    parseInt(params.orderId),
                    parseInt(params.orgId),
                    parseInt(params.branchId),
                    currentMenu.type
                );


                const order = currentOrder;

                if (order && generalStore.organizationId === -1) {
                    await generalStore.init({
                        organizationId: parseInt(params.orgId),
                        organizationBranchId: parseInt(params.branchId),
                        menuId: parseInt(params.menuId),
                        tableId: "0",
                        pointId: "",
                        viewOnly: false,
                        isWidget: false
                    })
                }
            }
        })()
    }, [currentOrder, ordersStore, params]);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 3000)
        }
    }, [isCopied])

    if (!isReady || !currentOrder) return null;

    let paymentSettings: IToBankCardPaymentSettings;
    (currentMenu.type === MenuType.regular) ? paymentSettings = menuStore.menu.data.paymentSettings[PaymentProviderType.toBankCard] :
        paymentSettings = bookingMenuStore.bookingMenu.data.paymentSettings[PaymentProviderType.toBankCard];


    const locale = i18n.language;


    if (!paymentSettings.isEnabled) {
        history.push(`/order-created?orderId=${params.orderId}`)
    }

    let hintClasses = [style.copiedHint];
    if(!isCopied){
        hintClasses.push(style.hidden)
    }
    return <div className={style.container}>
        <FadeInOutVertical>
            <div className={style.block}>
                <p className={style.header}>{paymentSettings.title[locale]} #{currentOrder.id}</p>
                <p className={style.text}>
                    {paymentSettings.description[locale]}
                </p>

                <div className={style.currency}>{(currentOrder.totalPrice.price/100).toFixed(0)} {t('general:currency.rub')}</div>
                <CopyToClipboard text={paymentSettings.requisites}
                                 onCopy={() => {
                                     setIsCopied(true);
                                 }}>
                    <p className={style.requisites}>
                        <span>
                                {paymentSettings.requisites}
                            </span>
                        <img src={copyIcon} />
                    </p>
                </CopyToClipboard>
                {
                    <p className={hintClasses.join(" ")}>
                        {t('general:copied')}
                    </p>
                }


                <div className={style.button} onClick={() => {
                    const { organizationId, organizationBranchId, menuId } = generalStore;
                    if (params.orderId) {
                        history.push(`/order/${organizationId}/${organizationBranchId}/${menuId}/${params.orderId}/view`);
                    } else {
                        history.push(
                            `/menu?orgId=${organizationId}&branchId=${organizationBranchId}&menuId=${menuId}&tableId=${generalStore.tableId}`
                        );
                    }
                }}>
                    {
                        t("general:finishToBankCardPayment")
                    }
                </div>
                <Space size={3}/>

                <div className={[style.button, style.inverse].join(" ")} onClick={() => {
                    window.open(paymentSettings.buttonUrl)
                }}>
                    {
                        paymentSettings.buttonTitle[locale]
                    }
                </div>


            </div>
        </FadeInOutVertical>

    </div>
};


export default observer(ToBankCardPaymentPage);
