export const FlexRowSpaceBetween = (props: { children: any, maxWidth?: string | number }) => {
    return <div style={{ display: "flex", justifyContent: "space-between", maxWidth: props.maxWidth, alignItems: 'center' }}>
    {props.children}
    </div>
};


export const FlexRowStart = (props: { children: any }) => {
    return <div style={{ display: "flex" }}>
    {props.children}
    </div>
};
