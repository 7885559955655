import style from "./style.module.sass";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const CloseButton = (props: {onClick: () => void, classes?: any[]}) => {

    const { t } = useTranslation(["general"]);

    return (
        <div className={[style.closeButton, ...(props.classes || [])].join(" ")}
             onClick={props.onClick}
                >
            {t('general:close')}
        </div>
    )
}
export default observer(CloseButton);
