import spicyImage from "./assets/spicy@3x.png";
import style from "../style.module.sass";

const SpicyBadge = () => {
    return (
        <img src={spicyImage} alt='' className={style.badge}
        />
    )
}

export default SpicyBadge;
