import React, {Suspense} from "react";


import { Helmet } from 'react-helmet';
import { StoreProvider } from "./contexts";
import {  rootStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import { config } from "./router";
import { renderRoutes } from "react-router-config";
import 'normalize.css';
import "./App.css"
import OrderingNotAvailable from "./pages/orderingNotAvailable";
import {useStores} from "./hooks";

function App() {
    return (
        <StoreProvider store={rootStore}>
                <React.Fragment>

                    <Helmet
                        titleTemplate="%s"
                        defaultTitle="restify.one"
                    />
                        {/*<OrderingNotAvailable/>*/}
                    <BrowserRouter>
                        {renderRoutes(config)}
                    </BrowserRouter>
                </React.Fragment>
        </StoreProvider>
    );
}

export default App
