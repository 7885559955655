import {
    BookingItemBadge,
    BookingScheduleSettings,
    DeliveryDetails,
    MediaItems, MenuBookingItemSuggestion,
    MenuItem, MenuItemModifierSetDescriptor,
    PriceInCurrency
} from "../menu/models";
import {ChosenMenuItemModifierSet, ChosenMenuItemModifierSetExtended} from "../../types/types";


export enum PaymentProviderType {
    cloudpayments = 'CloudPaymentsCheckout',
    offline = 'Offline',
    toBankCard = 'BankCardRequisites',
    companyInvoice =  'CompanyBankAccountRequisites',
    sbpTochka = 'SBPTochka',
    sberbank = 'SberbankCheckout'
}

export interface CloudPaymentsDetails{
    type: PaymentProviderType.cloudpayments,
    qrcode?: string,
    receiptLink?: string
}
export interface SbpTochkaDetails{
    type: PaymentProviderType.sbpTochka,
    isPaid: boolean;
    receiptLink: string | null,
    qrCodeId: string | null,
    qrCodeLink: string,
    refundReceiptLink: string | null,
    correctionReceiptLink: string | null,
    refundRequestId: string | null,
    qrCodeGeneratedAt: Date | null,
}

export interface OfflinePaymentsDetails{
    type: PaymentProviderType.offline,
}

export interface ToBankCardPaymentsDetails{
    type: PaymentProviderType.toBankCard,
    requisites: string;
}

export interface ICompanyBankAccountRequisites {
    type: PaymentProviderType.companyInvoice,
    pdfActUrl: string;
    pdfInvoiceUrl: string;
    recipientCompanyCard: RecipientCompanyCard;
}

export enum RecipientType{
    russianOOOCard = "RecipientRussianOOOCard",
    russianIPCard = "RecipientRussianIPCard"
}

export interface RecipientRussianOOOCard{
    type: RecipientType.russianOOOCard
    contractId: string;
    inn: string;
    legalAddress: string;
    shortTitle: string;
    kpp: string;

}

export interface RecipientRussianIPCard {
    type: RecipientType.russianIPCard,
    contractId: string;
    inn: string;
    legalAddress: string;
    shortTitle: string;
    ogrnip: string;
}

export type RecipientCompanyCard = RecipientRussianOOOCard | RecipientRussianIPCard;



export type OrderPaymentDetails = CloudPaymentsDetails | SbpTochkaDetails | OfflinePaymentsDetails | ToBankCardPaymentsDetails | ICompanyBankAccountRequisites;

export interface CloudPaymentsCheckout {
    type: PaymentProviderType.cloudpayments
}

export interface SberbankCheckout {
    type: PaymentProviderType.sberbank
}

export interface SBPTochka {
    type: PaymentProviderType.sbpTochka
}

export interface Offline {
    type: PaymentProviderType.offline
    changeFromAmount: PriceInCurrency | null;
    method: string | null;
}

export interface BankCardRequisites {
    type: PaymentProviderType.toBankCard
    requisites: string;
}


export interface CompanyBankAccountRequisites {
    recipientCompanyCard: RecipientCompanyCard;
    type: PaymentProviderType.companyInvoice
}

interface OrderItem {
    chosenMenuItemModifierSets: ChosenMenuItemModifierSetExtended[];
    comment: string | null;
    cookingProcessDetails: CookingProcessDetails;
    menuItem: MenuItem;
    menuItemsCount: number;
    price: PriceInCurrency;
}


export type NewOrderPaymentDetails = CloudPaymentsCheckout | SBPTochka | Offline | BankCardRequisites | CompanyBankAccountRequisites | SberbankCheckout



interface CookingProcessDetails {
    status: string;
}

export interface Order {
    clientId: number | null;
    clientSessionId: string | null;
    createdAt: Date;
    createdByAccountId: number | null;
    deliveryDetails: DeliveryDetails;
    id: number;
    items: OrderItem[];
    organizationBranchId: number;
    organizationId: number;
    paymentDetails: OrderPaymentDetails | null;
    status: OrderStatus;
    totalPrice: PriceInCurrency;
    clientLocaleId: string;
    comment: string;
    menuId: number;
    orderSessionId: string | null;
    statusHistory: StatusHistoryDetails[];
    tags: string[];
    usedMenuCategoriesById: { [key: number]: MenuCategory };
    usedMenuItemModifierSetsById: { [key: number]: MenuItemModifierSet };
    couponId: number | null;
    loyaltyProgram: LoyaltyProgram | null;
    externalOrderNumber?: string | null;
    externalId?: string | null;
}
export interface LoyaltyProgram {
    phoneNumber: string;
    typeOperation: OperationType;
}
export type OperationType = "Withdraw" | "Deposit";

export interface StatusHistoryDetails {
    changedAt: Date;
    changedByAccountId: number | null;
    fromStatus: string;
    toStatus: string;
}
export enum OrderStatus {
    preorder = 'Preorder',
    new = 'New',
    accepted = 'Accepted',
    paid = 'Paid',
    canceled = 'Canceled',
    ready = 'Ready',
    givenOut = 'GivenOut',
    readyToPickUp = 'ReadyToPickUp',
    givenOutToDelivery = 'GivenOutToDelivery',
    finished = 'Finished',
    canceledByKitchen = 'CanceledByKitchen',
    canceledWithRefund = 'CanceledWithRefund',
    sentToKitchen = 'SentToKitchen',
    acceptedByKitchen = 'AcceptedByKitchen'
}

export interface MenuCategory {
    data: MenuCategoryData;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export interface MenuCategoryData {
    isInStopList: boolean;
    name: { [key: string]: string };
    parentMenuCategoryId: number | null;
    sortOrder: number;
}

export interface MenuItemModifierSetData {
    modifiers: MenuItemModifier[];
    name: { [key: string]: string };
    type: string;
}

export interface MenuItemModifierSet {
    data: MenuItemModifierSetData;
    id: number;
    isRemoved: boolean;
    organizationBranchId: number;
}
export interface MenuItemModifier {
    data: MenuItemModifierData;
    id: string;
}

export interface MenuItemModifierData {
    inputProductAmountNetto: number;
    isInStopList: boolean;
    manufactureId: number | null;
    name: { [key: string]: string };
    outputProductAmountNetto: number | null;
    photosUrls: string[];
    productId: number | null;
    sellPrice: PriceInCurrency;
}

export enum CouponType {
    percentage = 'Percentage',
    fixedAmount = 'FixedAmount'

}

export interface PercentageCouponData {
    type: CouponType.percentage;
    code: string;
    endDate: Date;
    startDate: Date;
    totalUsagesLimit: number;
    discountPercent: number;
}
export interface FixedAmountCouponData {
    type: CouponType.fixedAmount;
    code: string;
    discountAmount: AmountInCurrency;
    endDate: Date;
    startDate: Date;
    totalUsagesLimit: number;
}

export interface AmountInCurrency {
    amount: number;
    currencyId: string;
}



export type CouponCalculationVariants = PercentageCouponData | FixedAmountCouponData;


export interface Coupon {
    data: CouponCalculationVariants;
    id: number;
    menuId: number;
    organizationBranchId: number;
    organizationId: number;
}




/*

  "type": "ToRestaurantTable",
            "tableId": "123"
 */


export interface NewOrderItem {
    chosenMenuItemModifierSets: ChosenMenuItemModifierSet[];
    comment: string | null;
    menuItemId: number;
    menuItemsCount: number;
}

export interface NewBookingOrderItem {
    chosenMenuItemModifierSets: NewChosenMenuItemModifierSet[];
    itemId: number;
    itemsCount: number;
    menuId: number;
    // type: OrderItemType;
}

export type NewBookingOrderItemExtended = NewBookingOrderItemBookingItem | NewBookingOrderItemRegularItem;

export interface NewBookingOrderItemBookingItem extends NewBookingOrderItem{
    type: OrderItemType.booking,
    menuId: number;
    itemId: number;
    itemsCount: number;
    chosenMenuItemModifierSets: NewChosenMenuItemModifierSet[];
    chosenMenuBookingItemSlots: ChosenMenuBookingItemSlot[],
}

export interface NewBookingOrderItemRegularItem extends NewBookingOrderItem{
    type: OrderItemType.regular;
    menuId: number;
    itemId: number;
    itemsCount: number;
    chosenMenuItemModifierSets: NewChosenMenuItemModifierSet[];
    relatedMenuBookingItemId: number,
}

export interface NewOrder {
    deliveryDetails: DeliveryDetails;
    paymentDetails: NewOrderPaymentDetails,
    items: NewOrderItem[];
    menuId: number;
    clientSessionId: string | null,
    orderSessionId: string | null,
    clientLocaleId: string,
    comment: string,
    tags: string[],
    couponCode: string | null,
    loyaltyProgram: LoyaltyProgram | null
}

export interface YandexDeliveryPerformerInfo {
    car_color: string | null;
    car_color_hex: string | null;
    car_model: string | null;
    car_number: string | null;
    courier_name: string;
    legal_name: string;
    transport_type: string | null;
}

export type TaxiClass = "Courier" | "Express" | "Cargo";

export interface OrderDeliveryPriceOffer {
    clientSessionId: string | null;
    createdAt: Date;
    distanceMeters: number | null;
    id: number;
    newOrder: NewOrder;
    organizationBranchId: number;
    price: PriceInCurrency;
    taxiClass: TaxiClass | null;
    timeEstimateMinutes: number | null;
    validUntil: Date;
}

export interface DeliveryPriceOffer {
    price: PriceInCurrency;
    updatedAt: Date;
    validUntil: Date;
}

export type ClaimStatus = "New" | "Estimating" | "EstimatingFailed" | "ReadyForApproval" | "Accepted" | "PerformerLookup" | "PerformerDraft" | "PerformerFound" | "PerformerNotFound" | "PickupArrived" | "ReadyForPickupConfirmation" | "Pickuped" | "DeliveryArrived" | "ReadyForDeliveryConfirmation" | "PayWaiting" | "Delivered" | "DeliveredFinish" | "Returning" | "ReturnArrived" | "ReadyForReturnConfirmation" | "Returned" | "ReturnedFinish" | "Failed" | "Cancelled" | "CancelledWithPayment" | "CancelledByTaxi" | "CancelledWithItemsOnHands";


export interface AddressCoordinates {
    city: string | null;
    settlement: string | null;
    settlement_type: string | null;
    settlement_type_full: string | null;
    city_area: string | null;
    city_district: string | null;
    country: string | null;
    flat: string | null;
    geo_lat: string | null;
    geo_lon: string | null;
    house: string | null;
    postal_code: string | null;
    qc_geo: CoordinatesPrecision;
    region: string | null;
    result: string | null;
    source: string | null;
    street: string | null;
    street_type: string | null;
    street_type_full: string | null;
    house_type: string | null;
    house_type_full: string | null;

    flat_type: string | null;
    flat_type_full: string | null;

    block_type: string | null;
    block_type_full: string | null;
    block: string | null;

}
export type CoordinatesPrecision = "Exact" | "NearestHouse" | "Street" | "Locality" | "City" | "Unknown";

export interface AddressSuggestion {
    data: AddressCoordinates;
    unrestricted_value: string;
    value: string;
}
export interface CoordinatesForYandex {
    latitude: number;
    longitude: number;
}

export interface Booking {
    createdAt: Date;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
    status: string;
    type: string;
}

export interface PublicMenuItemBooking {
    data: MenuItemBookingData;
    id: number;
    menuBookingItem: UsedBookingOrderMenuItem;
    menuId: number;
    organizationBranchId: number;
    status: string;
}

export interface MenuItemBooking extends Booking {
    bookingOrderId: number;
    createdAt: Date;
    data: MenuItemBookingData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuBookingItem: UsedBookingOrderMenuItem;
    menuId: number;
    organizationBranchId: number;
    status: string;
}


export interface MenuItemBookingData {
    amount: number;
    comment: string | null;
    fromDate: Date;
    toDate: Date;
}

export interface UsedBookingOrderMenuItemData {
    amount: number;
    bookingBadges: { [key: string]: BookingItemBadge };
    bookingScheduleSettings: BookingScheduleSettings;
    description: { [key: string]: string };
    isInStopList: boolean;
    media: MediaItems[];
    menuBookingCategoryId: number;
    menuBookingItemSuggestions: MenuBookingItemSuggestion[];
    menuItemModifierSets: MenuItemModifierSetDescriptor[];
    name: { [key: string]: string };
    sellPrice: PriceInCurrency;
    sortOrder: number;
}

export interface UsedBookingOrderMenuItem {
    data: UsedBookingOrderMenuItemData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export interface NewBookingOrder {
    clientLocaleId: string | null;
    clientPhoneNumber: string | null;
    clientSessionId: string | null;
    comment: string;
    couponCode: string | null;
    items: (NewBookingOrderItemBookingItem | NewBookingOrderItemRegularItem)[];
    loyaltyProgram: LoyaltyProgram | null;
    menuBookingId: number;
    orderSessionId: string | null;
    paymentDetails: NewOrderPaymentDetails;
}



export interface NewChosenMenuItemModifierSet {
    chosenMenuItemModifiers: NewBookingOrderChosenMenuItemModifier[];
    menuItemModifierSetId: number;
}

export interface NewBookingOrderChosenMenuItemModifier {
    count: number;
    menuItemModifierId: string;
}

export interface PublicBookingOrder extends BookingOrder {
    clientId: number | null;
    clientLocaleId: string | null;
    clientSessionId: string | null;
    comment: string;
    couponId: number | null;
    createdAt: Date;
    createdByAccountId: number | null;
    externalId: string | null;
    id: number;
    items: (BookingOrderItemBookingItem | BookingOrderItemRegularItem)[];
    loyaltyProgram: LoyaltyProgram | null;
    menuBookingId: number;
    orderSessionId: string | null;
    organizationBranchId: number;
    organizationId: number;
    paymentDetails: OrderPaymentDetails;
    status: string;
    statusHistory: StatusHistoryDetails[];
    totalPrice: PriceInCurrency;
    usedMenuBookingCategoriesById: { [key: number]: UsedOrderBookingMenuCategory };
    usedMenuCategoriesById: { [key: number]: UsedOrderMenuCategory };
    usedMenuItemModifierSetsById: { [key: number]: MenuItemModifierSet };
}

export interface BookingOrder {
    clientId: number | null;
    clientLocaleId: string | null;
    clientSessionId: string | null;
    comment: string;
    couponId: number | null;
    createdAt: Date;
    createdByAccountId: number | null;
    externalId: string | null;
    id: number;
    items: (BookingOrderItemBookingItem | BookingOrderItemRegularItem)[];
    loyaltyProgram: LoyaltyProgram | null;
    menuBookingId: number;
    orderSessionId: string | null;
    organizationBranchId: number;
    organizationId: number;
    paymentDetails: OrderPaymentDetails;
    status: string;
    statusHistory: StatusHistoryDetails[];
    totalPrice: PriceInCurrency;
    usedMenuBookingCategoriesById: { [key: number]: UsedOrderBookingMenuCategory };
    usedMenuCategoriesById: { [key: number]: UsedOrderMenuCategory };
    usedMenuItemModifierSetsById: { [key: number]: MenuItemModifierSet };
}


export interface BookingOrderItem {
    chosenMenuItemModifierSets: ChosenMenuItemModifierSetExtended[];
    itemsCount: number;
    price: PriceInCurrency;
    priceAfterDiscount: PriceInCurrency;
    type: string;
}

export enum BookingItemType {
    bookingOrderItemBookingItem = "BookingOrderItemBookingItem",
    bookingOrderItemRegularItem = "BookingOrderItemRegularItem"
}

export interface BookingOrderItemBookingItem extends BookingOrderItem{
    type: BookingItemType.bookingOrderItemBookingItem,
    menuBookingItem: UsedBookingOrderMenuItem,
    chosenMenuBookingItemSlots: ChosenMenuBookingItemSlot[];
    chosenMenuItemModifierSets: ChosenMenuItemModifierSetExtended[],
    itemsCount: number,
    price: PriceInCurrency,
    priceAfterDiscount: PriceInCurrency
}

export interface ChosenMenuBookingItemSlot {
    fromDate: Date,
    toDate: Date
};

export interface BookingOrderItemRegularItem extends BookingOrderItem{
    type:BookingItemType.bookingOrderItemRegularItem,
    menuItem: UsedOrderMenuItem;
    relatedBookingOrderItem: UsedBookingOrderMenuItem,
    chosenMenuItemModifierSets: ChosenMenuItemModifierSetExtended[],
    itemsCount: number,
    price: PriceInCurrency,
    priceAfterDiscount: PriceInCurrency

}


interface UsedOrderMenuItem {
    data: UsedOrderMenuItemData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}
//
// interface OrderItem {
//     chosenMenuItemModifierSets: ChosenMenuItemModifierSet[];
//     comment: string | null;
//     cookingProcessDetails: CookingProcessDetails;
//     menuItem: UsedOrderMenuItem;
//     menuItemsCount: number;
//     price: PriceInCurrency;
//     priceAfterDiscount: PriceInCurrency;
// }
//
export interface UsedOrderMenuItemData {
    badges: { [key: string]: MenuItemBadge };
    description: { [key: string]: string };
    inputProductAmountNetto: number;
    isInStopList: boolean;
    manufactureId: number | null;
    menuCategoryId: number;
    menuItemModifierSets: MenuItemModifierSetDescriptor[];
    name: { [key: string]: string };
    outputProductAmountNetto: number | null;
    participatesInPromotions: boolean;
    photosUrls: string[];
    productId: number | null;
    sellPrice: PriceInCurrency;
    sortOrder: number;
    stopListSettings: StopListSettings | null;
}

export interface MenuItemBadge {
    isEnabled: boolean;
    type: string;
}

export interface StopListSettings {
    finishesAt: Date | null;
    startsAt: Date | null;
}

export interface UsedOrderBookingMenuCategoryData {
    isInStopList: boolean;
    name: { [key: string]: string };
    sortOrder: number;
}

export interface UsedOrderBookingMenuCategory {
    data: UsedOrderBookingMenuCategoryData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export interface UsedOrderMenuCategoryData {
    isInStopList: boolean;
    name: { [key: string]: string };
    parentMenuCategoryId: number | null;
    sortOrder: number;
}

export interface UsedOrderMenuCategory {
    data: UsedOrderMenuCategoryData;
    externalId: string | null;
    id: number;
    isRemoved: boolean;
    menuId: number;
    organizationBranchId: number;
}

export enum OrderItemType {
    regular = 'Regular',
    booking = 'Booking'
}
















