import { observer } from "mobx-react";
import React, { Ref, useEffect } from "react"
import { useStores } from "../../../../../hooks";
import styles from "../components/style.module.sass"
import MenuItemComponent from "./MenuItem"
import useVisibility from "../../../../../hooks/useVisability";
import { useTranslation } from "react-i18next";

interface IProps {
    categoryId: number;
    setIsVisible: (categoryId: number, isVisible: boolean, offset: number) => void;
}

const CategoryContentWithPhoto = React.forwardRef(
    (props: IProps, ref: Ref<HTMLParagraphElement>) => {
        const {i18n} = useTranslation();
        const locale = i18n.language;

        const { menuStore } = useStores();
        const [isVisible, offsetTop] = useVisibility(ref, 0, 200);


        useEffect(() => {
            //console.log("Hey", isVisible, offsetTop);
            props.setIsVisible(props.categoryId, isVisible, offsetTop);
        }, [isVisible, offsetTop]);
        const items = menuStore.items[props.categoryId] || [];
        const category = menuStore.categories.find(item => item.id === props.categoryId);
        if (!category) return null;

        return <div className={styles.categoryWrapper}>
            <p className={styles.categoryHeader} ref={ref}>{category.data.name[locale]}</p>
            <div className={styles.categoryContainer}>
                {items.map((item) => {
                    return <MenuItemComponent key={item.id} item={item}/>
                })}
            </div>
        </div>
    });

export default observer(CategoryContentWithPhoto);
