import { getToken } from "./token";
import axios, { AxiosResponse } from "axios";


export const API_URL_EXECUTE_ENDPOINT = "/api/execute/";


export const execute = async <RequestType, ResponseType>(command: string, payload: RequestType) => {

    const result: AxiosResponse<ResponseType> = await axios.post(`${API_URL_EXECUTE_ENDPOINT}`, {
        ...payload,
        clientSyncId: generateClientSyncId(),
        method: command
    },{
        headers: {
            "AuthToken": getToken()
        },
        validateStatus: (status: number) => true
    });
    return result;
};

export const generateClientSyncId = () => Math.random().toString();



export enum ExecutionStatus{
    Pending = "Pending",
    Finished = "Finished",
    Failed = "Failed"
}

export interface GeneralResponse {
    clientSyncId: string;
    executionStatus: ExecutionStatus;
}

export interface GeneralRequest {
    //clientSyncId: string;
}

