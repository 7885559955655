import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStores} from "../hooks";
import useAlias from "../hooks/useAlias";
import {MenuType} from "../services/menu/models";
import MenuView from "./menu/MenuView";
import BookingMenuView from "./bookingMenu/BookingMenuView";
import useQuery from "../hooks/useQuery";
import {useHistory, useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {MenuMode} from "../store/general";
import Catalog from "./catalog";
import RestifyLoader from "./restifyLoader";


const MenuTypeWrapper = () => {

    const { generalMenuStore, bookingMenuStore, menuStore, generalStore, catalogStore} = useStores();
    const history = useHistory();



    const params = useQuery<{
        menuId: string,
        orgId: string,
        branchId: string
    }>();

    const aliasParams = useParams<{
        alias: string
    }>()


    const [alias, isAliasReady] = useAlias();
    const location = useLocation();
    const aliasString = location.pathname !== '/' ? location.pathname.split('/')[1] : null;

    useEffect(() => {
        if(generalMenuStore.type !== MenuType.none) return;

        if(!params.menuId || !params.orgId || !params.branchId) return;
        (async () => {
            await generalMenuStore.getMenuTypeByMenuId(
                parseInt(params.menuId),
                parseInt(params.orgId),
                parseInt(params.branchId)
            ).then(() => {
                if (generalMenuStore.type === MenuType.booking) {
                    bookingMenuStore.init({
                            bookingMenuId: parseInt(params.menuId),
                            organizationId: parseInt(params.orgId),
                            organizationBranchId: parseInt(params.branchId)
                        }
                    )
                    generalMenuStore.setMenu(bookingMenuStore.bookingMenu)
                } else {
                    menuStore.init({
                            menuId: parseInt(params.menuId),
                            organizationId: parseInt(params.orgId),
                            organizationBranchId: parseInt(params.branchId)
                        }
                    )
                    generalMenuStore.setMenu(menuStore.menu);
                }
            });})();

    }, [generalMenuStore.type, aliasParams.alias, catalogStore.isReady]);

    useEffect(() => {
        if(generalMenuStore.type !== MenuType.none) return;

        (async () => {
            if (alias && isAliasReady && alias.data.aliasedMenus.length === 1) {
                await generalMenuStore.getMenuByAlias(alias.alias, alias.data.aliasedMenus[0].menuId)
                    .then(() => {
                        const currentMenu = generalMenuStore.menu;
                        if (currentMenu.type === MenuType.booking) generalMenuStore.setMenuType(MenuType.booking);
                        else generalMenuStore.setMenuType(MenuType.regular);
                        generalMenuStore.setMenu(currentMenu)
                    })
            } else if (alias && isAliasReady && alias.data.aliasedMenus.length > 1) {

                if (aliasString ) {
                    history.push(`/catalog/${alias.alias}${window.location.search}`)
                    /*await catalogStore.init(aliasString);
                    generalStore.setMode({
                        mode: MenuMode.catalog,
                       catalogAlias: aliasString
                    })*/
                }
            }
        })();
    }, [alias, isAliasReady, generalMenuStore, bookingMenuStore, menuStore, generalStore]);

    useEffect(() => {
        if (window.location.pathname === '/') {
            history.replace(`/menu${window.location.search}`)
        }
    }, [window.location.pathname]);

    return <>
        <RestifyLoader
            show={!generalMenuStore.menu && generalStore.modeData.mode !== MenuMode.catalog}
        />
        {generalMenuStore.type === MenuType.regular &&
			<MenuView/>
        }
        {generalMenuStore.type === MenuType.booking &&
			<BookingMenuView/>
        }
        {!generalMenuStore.type && generalStore.modeData.mode === MenuMode.catalog &&
            <Catalog/>
        }
    </>
}

export default observer(MenuTypeWrapper)
