import style from "./../style.module.sass"
import {useTranslation} from "react-i18next";
import profitableImage from "./assets/profitable.png";


const ProfitableBadgeOverPhoto = () => {
    const { i18n, t } = useTranslation(["cart"]);
    return (
        <div className={style.profitableBadgeOverPhoto}>
            <img src={profitableImage} alt=''
            />
            <p>{t("cart:profitable")}</p>
        </div>
    )
}

export default ProfitableBadgeOverPhoto;
