import {observer} from "mobx-react";
import {FadeInOutVertical} from "../../../../components/Animated";
import style from "../../style.module.sass";
import {DeliveryConditionType, DeliveryDetailsTakeAwayToTime, DeliveryType} from "../../../../services/menu/models";
import React, {useEffect, useState} from "react";
import {changeEventHandlerDecorator} from "../../../../components/libs";
import PhoneInput from "react-phone-input-2";
import {useStores} from "../../../../hooks";
import {Trans, useTranslation} from "react-i18next";
import {PaperBlock} from "../../../../components/paperBlock";
import DeliveryTypeInput from "./DeliveryTypeInput";
import {AddressSuggestion} from "services/order/models";
import Autosuggest from 'react-autosuggest';
import OrdinaryCircleLoader from "../../../../components/ordinaryCircleLoader";
import SmallGreyText from "../../../../components/titles/SmallGreyTitle";
import 'rc-time-picker/assets/index.css';
import moment, {Moment} from "moment-timezone";
import TimePicker from "rc-time-picker";
import Select from 'react-select';

const getSuggestionValue = (suggestion: AddressSuggestion) => {

    let text: string[] = [];

    if (suggestion.data.settlement) {
        text.push(`${suggestion.data.settlement_type} ${suggestion.data.settlement}`)
    } else if (suggestion.data.city) {
        text.push(`${suggestion.data.city}`)
    }

    if(suggestion.data.street){
        text.push(`${suggestion.data.street} ${suggestion.data.street_type_full}`)
    }
    if(suggestion.data.house){
        text.push(`${suggestion.data.house_type_full} ${suggestion.data.house}`)
    }

    if(suggestion.data.block){
        text.push(`${suggestion.data.block_type_full} ${suggestion.data.block}`)
    }

    return text.length > 0 ? text.join(' ') : "Ничего не найдено";

}

const renderSuggestion = (text: string) => {
    return <div>
        <p>
            {text}
        </p>
    </div>
}

const DeliveryDetailsBlock = (props: {addDeliveryPrice?: boolean}) => {

    const { checkoutStore, menuStore, generalStore } = useStores();
    const { i18n, t } = useTranslation(["general", "cart"]);
    const locale = i18n.language;
    const deliverySettings = menuStore.menu.data.deliverySettings;
    const deliveryAddressSuggestions = checkoutStore.addressSuggestions;
    const isCheckingAddressSuggestionsInProgress = checkoutStore.isAddressQueryChecking;
    const failedAddressCheck = checkoutStore.failedAddressCheck;
    const [query, setQuery] = useState("");
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [dateOptions, setDateOptions] = useState<{ value: string, label: string }[]>([]);
    const [datePickerValue, setDatePickerValue] = useState<{ value: string, label: string } | null>(null)
    const [timePickerValue, setTimePickerValue] = useState<Moment>(moment());
    const [timePeriod, setTimePeriod] = useState(0);
    const deliveryConditions = deliverySettings[DeliveryType.toAddress].deliveryConditions;

    const magnitMenuIds = [709, 710, 711, 712, 713, 714, 715, 716, 717, 718]
    const dom8OrgBranch = 699

    useEffect(() => {
        checkoutStore.setIsReadyToSubmit(checkoutStore.validatePaymentDetails() && checkoutStore.validateDeliveryDetails());
    }, [checkoutStore.deliveryDetails, timePeriod, checkoutStore.isZoneEnabledForDelivery])

    useEffect(() => {

        //@ts-ignore
        const prevPhone = checkoutStore.deliveryDetails?.phoneNumber;
        const phoneSuggestion = generalStore.getPhoneSuggestion();
        if (checkoutStore.deliveryType === DeliveryType.toTable) {
            let table = menuStore.getTableByPublicId(generalStore.tableId);

            if (!table) {
                table = menuStore.getTableByPublicId("0");
            }

            if (!table) return;
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.toTable,
                tableId: table.id,
                seatId: null
            })
            checkoutStore.setIsReadyToSubmit(generalStore.tableId.length > 0);
        } else if (checkoutStore.deliveryType === DeliveryType.toAddress) {
            if (menuStore.menu?.organizationBranchId == dom8OrgBranch) {
                setQuery('г Москва, ул Малая Пироговская, д 8')
                checkoutStore.setDeliveryDetails({
                    type: DeliveryType.toAddress,
                    address: "г Москва, ул Малая Пироговская, д 8",
                    streetType: "ул",
                    street: "Малая Пироговская",
                    houseType: "д",
                    house: "8",
                    buildingType: "",
                    building: "",
                    phoneNumber: prevPhone || phoneSuggestion,
                    comment: null,
                    coordinates: {
                        latitude: 55.72961,
                        longitude: 37.570474
                    },
                    deliveryDetailsData: null,
                    flat: null,
                    floor: null,
                    porch: null,
                    name: null,
                    orderDeliveryPriceOfferId: null,
                });
            } else {
                checkoutStore.setDeliveryDetails({
                    type: DeliveryType.toAddress,
                    address: "",
                    streetType: "",
                    street: "",
                    houseType: "",
                    house: "",
                    buildingType: "",
                    building: "",
                    phoneNumber: prevPhone || phoneSuggestion,
                    comment: null,
                    coordinates: null,
                    deliveryDetailsData: null,
                    flat: null,
                    floor: null,
                    porch: null,
                    name: null,
                    orderDeliveryPriceOfferId: null,
                });
            }
            checkoutStore.setIsReadyToSubmit(false);
        } else if (checkoutStore.deliveryType === DeliveryType.toAddressByYandexDelivery) {
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.toAddressByYandexDelivery,
                address: "",
                streetType: "",
                street: "",
                houseType: "",
                house: "",
                buildingType: "",
                building: "",
                phoneNumber:  prevPhone || phoneSuggestion,
                comment: null,
                coordinates: null,
                deliveryDetailsData: null,
                flat: null,
                floor: null,
                porch: null,
                name: null,
                orderDeliveryPriceOfferId: null,
            })
            checkoutStore.setIsReadyToSubmit(false);
        } else if (checkoutStore.deliveryType === DeliveryType.takeaway) {
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.takeaway,
                phoneNumber:  prevPhone || phoneSuggestion,
                name: null,
            })
            checkoutStore.setIsReadyToSubmit(false);
        }else if (checkoutStore.deliveryType === DeliveryType.takeawayToTime) {
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.takeawayToTime,
                phoneNumber:  prevPhone || phoneSuggestion,
                name: null,
                toHour: null,
                toMinute: null,
                date: null,
            })

            let timeToPickUp: Moment;

            if (isTakeAwayToTimeDateRequired()) {
                timeToPickUp = moment().add(1, 'days')

                if (isMagnitMenu()) {
                    timeToPickUp.set('minutes', 30)
                    timeToPickUp.set('hours', 12)
                }

                checkoutStore.setDeliveryDetails({
                    ...checkoutStore.deliveryDetails as DeliveryDetailsTakeAwayToTime,
                    date: timeToPickUp.toDate(),
                    toHour: timeToPickUp.get('hour'),
                    toMinute: timeToPickUp.get('minute'),
                })
                setDateOptions(getDateOptions())
                setDatePickerValue({ value: timeToPickUp.format('DD.MM.YYYY'), label: getDateLabel(timeToPickUp) })
            } else {
                timeToPickUp = moment().add(1, 'hours');
            }
            setTimePickerValue(timeToPickUp)
            checkoutStore.setIsReadyToSubmit(false);
        } else if (checkoutStore.deliveryType === DeliveryType.insideTheRestaurant) {
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.insideTheRestaurant,
            })
            checkoutStore.setIsReadyToSubmit(false);
        } else if (checkoutStore.deliveryType === DeliveryType.toVehicle) {
            checkoutStore.setDeliveryDetails({
                type: DeliveryType.toVehicle,
                vehiclePlateNumber: "",
                vehicleColor: "",
                vehicleBrandName: "",
                //@ts-ignore
                phoneNumber: checkoutStore.deliveryDetails?.phoneNumber || phoneSuggestion
            })
            checkoutStore.setIsReadyToSubmit(false);
        }
    }, [checkoutStore.deliveryType]);

    const autosuggestionClasses = [];
    if (failedAddressCheck) {
        autosuggestionClasses.push(style.failedAddress)
    } else {
        autosuggestionClasses.push(style.address)
    }



    const openTimePick = () => {
        setOpenTimePicker(!openTimePicker)
    }

    const updateTimeToPickUp = (date?: Moment, hour?: number, minute?: number) => {
        const newTimeToPickUp = timePickerValue.clone()

        if (date !== undefined) {
            newTimeToPickUp.set('date', date.get('date'))
            newTimeToPickUp.set('month', date.get('month'))
            newTimeToPickUp.set('year', date.get('year'))
        }

        if (hour !== undefined) {
            newTimeToPickUp.set('hour', hour)
        }

        if (minute !== undefined) {
            newTimeToPickUp.set('minute', minute)
        }

        setTimePickerValue(newTimeToPickUp)
    }

    useEffect(() => {
        const now = moment()

        const diff = timePickerValue.diff(now)

        const newTimePeriod = Math.trunc(diff / (60 * 1000))

        if (newTimePeriod < 0) {
            checkoutStore.validateDeliveryTakeAwayToTimeDetails(false)
        } else {
            checkoutStore.validateDeliveryTakeAwayToTimeDetails(true)
        }

        setTimePeriod(newTimePeriod)
    }, [timePickerValue])

    const isTakeAwayTimeSet = () => {
        if (checkoutStore.deliveryDetails?.type != DeliveryType.takeawayToTime) return false
        return checkoutStore.deliveryDetails.toHour != null && checkoutStore.deliveryDetails.toMinute != null
    }

    const isTakeAwayToTimeDateRequired = () => {
        return menuStore.menu.data.deliverySettings[DeliveryType.takeawayToTime]?.isDateRequired
    }

    const getDateOptions = () => {
        const options: { value: string, label: string }[] = [];
        if (isTakeAwayToTimeDateRequired()) {
            const currentDate = moment()

            for (let i = 1; i < 15; i++) {
                const dt = currentDate.add(1, 'days')
                const dtStr = dt.format('DD.MM.YYYY')
                const label = getDateLabel(dt)

                options.push({
                    label: label,
                    value: dtStr,
                })
            }
        }

        return options
    }

    const getDateLabel = (dt: Moment) => {
        const day = dt.get('weekday')
        const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']

        return `${days[day]} (${dt.format('DD.MM')})`
    }

    const minutesForm = (minutes: number) => {
        if (minutes % 10 == 1) return 'минуту'
        if ([2, 3, 4].includes(minutes % 10)) return 'минуты'
        return 'минут'
    }

    const getTimePeriodForm = () => {
        if (timePeriod < 60) {
            return `Через ${timePeriod} мин.`
        } else {
            const hours = Math.floor(timePeriod / 60)
            const minutes = timePeriod % 60

            return `Через ${hours} ч. ${minutes} мин.`
        }
    }

    const isMagnitMenu = () => {
        return magnitMenuIds.includes(menuStore.menu.id || 1)
    }

    const disabledHours = () => {
        if (isMagnitMenu()) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23, 24]
        } else {
            return []
        }
    }

    const disabledMinutes = () => {
        if (isMagnitMenu()) {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
                52, 53, 54, 55, 56, 57, 58, 59]
        } else {
            return []
        }
    }


    return <>
        <DeliveryTypeInput/>

        {
            checkoutStore.deliveryDetails?.type === DeliveryType.toVehicle &&
			<div style={{ position: "relative", zIndex: 20 }}>
				<FadeInOutVertical>
					<PaperBlock>
						<p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToCarLabels.phoneNumber")}
						</p>
						<div style={{ marginTop: 10 }}>
							<PhoneInput
								country={'ru'}
								enableSearch={true}
								value={checkoutStore.deliveryDetails.phoneNumber}
								inputProps={{
                                    required: true,
                                }}
								onChange={(phone: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toVehicle) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            phoneNumber: phone
                                        })
                                    }
                                }}
							/>
						</div>

						<br/>
						<p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToCarLabels.registrationPlate")}
						</p>
						<div>
							<input
								className={style.deliverySettingsAddressInput}
								value={checkoutStore.deliveryDetails.vehiclePlateNumber}

								onChange={changeEventHandlerDecorator((plateNumber: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toVehicle) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            vehiclePlateNumber: plateNumber
                                        })
                                    }
                                })}
							/>
						</div>

						<br/>
						<p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToCarLabels.carModel")}
						</p>
						<div>
							<input
								className={style.deliverySettingsAddressInput}
								value={checkoutStore.deliveryDetails.vehicleBrandName}
								onChange={changeEventHandlerDecorator((carModel: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toVehicle) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            vehicleBrandName: carModel
                                        })
                                    }
                                })}
							/>
						</div>

						<br/>
						<p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToCarLabels.color")}
						</p>
						<div>
							<input
								className={style.deliverySettingsAddressInput}
								value={checkoutStore.deliveryDetails.vehicleColor}
								onChange={changeEventHandlerDecorator((color: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toVehicle) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            vehicleColor: color
                                        })
                                    }
                                })}
							/>
						</div>


					</PaperBlock>
				</FadeInOutVertical>
			</div>
        }

        {
            checkoutStore.deliveryDetails?.type === DeliveryType.toTable && <FadeInOutVertical>
				<PaperBlock>
					<div>
						<p className={style.deliverySettingsTitle} style={{ marginTop: 20 }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<span>{t("cart:deliveryToTable.table")}</span>
							</div>
						</p>
						<div>
							<select
								className={style.deliverySettingsAddressInput}
								value={checkoutStore.deliveryDetails.tableId || "default"}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toTable) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            tableId: parseInt(e.target.value),
                                            seatId: null
                                        });
                                    }
                                }}
                                disabled={!menuStore.menu.data.deliverySettings[DeliveryType.toTable].isChangeTableEnabled && !!generalStore.tableId
                            }
							>
                                {
                                    menuStore.tables.slice()
                                        .sort((a, b) => {
                                            return a.data.publicId - b.data.publicId
                                        })
                                        .map(table => {
                                            return <option value={table.id}>{table.data.title[locale]}</option>
                                        })
                                }

							</select>
						</div>
					</div>
                    {

                        deliverySettings[DeliveryType.toTable].canChooseSeat
                        && menuStore.getTableById(checkoutStore.deliveryDetails.tableId)
                        && menuStore.getTableById(checkoutStore.deliveryDetails.tableId)!.data.seats.length > 0 && <>
							<p className={style.deliverySettingsTitle} style={{ marginTop: 20 }}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<span>{t("cart:deliveryToTable.chooseSeat")}</span>
								</div>
							</p>
							<div>
								<select
									className={style.deliverySettingsAddressInput}
									value={checkoutStore.deliveryDetails.seatId || "default"}
									onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        if (checkoutStore.deliveryDetails?.type === DeliveryType.toTable) {
                                            checkoutStore.setDeliveryDetails({
                                                ...checkoutStore.deliveryDetails,
                                                seatId: e.target.value !== "default" ? e.target.value : null
                                            })
                                        }
                                    }}
								>
									<option value="default">{t("cart:deliveryToTable.chooseSeat")}</option>
                                    {
                                        menuStore.getTableById(checkoutStore.deliveryDetails.tableId)!.data.seats.map(item => {
                                            return <option value={item.publicId}>{item.publicId}</option>
                                        })
                                    }
								</select>
							</div>
						</>
                    }

				</PaperBlock>
			</FadeInOutVertical>
        }
        {
            (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) && <FadeInOutVertical>
				<PaperBlock>
					<p className={style.deliverySettingsTitle}>
                        {t("cart:deliveryToAddressLabels.contact")}?
					</p>
					<div style={{ marginTop: 5, marginBottom: 10 }}>
						<PhoneInput
							country={'ru'}
							enableSearch={true}
							value={checkoutStore.deliveryDetails.phoneNumber}
							inputProps={{
                                required: true,
                            }}
							onChange={(phone: string) => {
                                if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress
                                    || checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                    checkoutStore.setDeliveryDetails({
                                        ...checkoutStore.deliveryDetails,
                                        phoneNumber: phone
                                    })
                                }
                            }}
						/>
					</div>
					<input
						className={style.deliverySettingsAddressInput}
						placeholder={t("cart:deliveryToAddressLabels.name")}
						value={checkoutStore.deliveryDetails.name || ''}
						onChange={changeEventHandlerDecorator((value: string) => {
                            if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                checkoutStore.setDeliveryDetails({
                                    ...checkoutStore.deliveryDetails,
                                    name: value
                                })
                            }
                        })}
					/>
					<p className={style.deliverySettingsTitle} style={{marginTop: 20}}>
                        {t("cart:deliveryToAddressLabels.whereToDeliver")}
					</p>
					<div className={autosuggestionClasses[0]}>
						<Autosuggest suggestions={deliveryAddressSuggestions}
									 getSuggestionValue={getSuggestionValue}
									 inputProps={{
                                         placeholder:`${t("cart:deliveryToAddressLabels.address")}`,
                                         value: query,
                                         onChange: (e: any, { newValue }: any) => {
                                             if (typeof newValue === "string") {
                                                 setQuery(newValue);
                                             } else {
                                                 console.log('error')
                                             }
                                         }
                                     }}
									 onSuggestionsFetchRequested={({ value }: { value: string }) => {
                                         if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                             checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                             checkoutStore.setDeliveryAddress(value);
                                         }
                                     }}
									 onSuggestionSelected={(event: any, suggestion: any) => {
                                         if (suggestion.suggestion.data.house &&
                                             (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                                 checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery)) {
                                             let coordinates = null

                                             if (suggestion.suggestion.data.geo_lat && suggestion.suggestion.data.geo_lon) {
                                                 coordinates = {
                                                     latitude: parseFloat(suggestion.suggestion.data.geo_lat),
                                                     longitude: parseFloat(suggestion.suggestion.data.geo_lon),
                                                 }
                                             }
                                             checkoutStore.setDeliveryDetails({
                                                 ...checkoutStore.deliveryDetails,
                                                 address: getSuggestionValue(suggestion.suggestion),
                                                 streetType: suggestion.suggestion.data.street_type,
                                                 street: suggestion.suggestion.data.street,
                                                 houseType:suggestion.suggestion.data.house_type,
                                                 house: suggestion.suggestion.data.house,
                                                 buildingType: suggestion.suggestion.data.block_type,
                                                 building: suggestion.suggestion.data.block,
                                                 coordinates: coordinates,
                                             });
                                             if (checkoutStore.deliveryDetails.coordinates?.latitude) {
                                                 checkoutStore.getDeliveryPriceByCoordinates(checkoutStore.deliveryDetails.coordinates)
                                             }
                                         }
                                     }
                                     }
									 renderSuggestion={(t) => {
                                         return renderSuggestion(getSuggestionValue(t));
                                     }}
									 theme={style}
						/>
                        {isCheckingAddressSuggestionsInProgress && <div className={style.loader}>
							<OrdinaryCircleLoader color={"#FF2C3D transparent transparent transparent"}/>
						</div>}
                        {(failedAddressCheck && !isCheckingAddressSuggestionsInProgress) &&
							<div className={style.nothingFound}>
								<p>Ничего не найдено</p>
							</div>
                        }
					</div>

					<div className={style.floorPorch}>
						<div className={style.floor}>
							<p>{t("cart:deliveryToAddressLabels.floor")}</p>
							<input
								className={style.deliverySettingsAddressInput}
								placeholder={"1"}
								value={checkoutStore.deliveryDetails.floor || ''}
								onChange={changeEventHandlerDecorator((value: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                        checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            floor: parseInt(value)
                                        })
                                    }
                                })}
							/>
						</div>
						<div className={style.porch}>
							<p>{t("cart:deliveryToAddressLabels.porch")}</p>
							<input
								className={style.deliverySettingsAddressInput}
								placeholder={"1"}
								value={checkoutStore.deliveryDetails.porch || ''}
								onChange={changeEventHandlerDecorator((value: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                        checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            porch: value
                                        })
                                    }
                                })}
							/>
						</div>
                        <div className={style.flat}>
                            <p>{t("cart:deliveryToAddressLabels.flat")}</p>
                            <input
                                className={style.deliverySettingsAddressInput}
                                placeholder={"1"}
                                value={checkoutStore.deliveryDetails.flat || ''}
                                onChange={changeEventHandlerDecorator((value: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.toAddress ||
                                        checkoutStore.deliveryDetails?.type === DeliveryType.toAddressByYandexDelivery) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            flat: parseInt(value)
                                        })
                                    }
                                })}
                            />
                        </div>
					</div>

                    {(deliveryConditions.type === DeliveryConditionType.zonePrice &&
                            checkoutStore.deliveryDetails?.type === DeliveryType.toAddress && checkoutStore.isZoneEnabledForDelivery) &&
                        <div className={style.deliveryPrice}>
                            <p>{t("cart:deliveryPrice")}:</p>
                            {(deliveryConditions.type === DeliveryConditionType.zonePrice && checkoutStore.isZoneEnabledForDelivery &&
                                checkoutStore.zoneFixedPrice && checkoutStore.deliveryDetails.coordinates !==null ) ?
                                <>
                                {props.addDeliveryPrice ?
                                    <p>{(checkoutStore.zoneFixedPrice.price / 100).toFixed(0)} {t("general:currency.rub")}</p>
                                    :
                                    <p>0</p>
                                }
                                </>

                            :
                                <OrdinaryCircleLoader color={"#058F13 transparent transparent transparent"}/>
                            }
                        </div>
                    }

                    {(deliveryConditions.type === DeliveryConditionType.zonePrice && !checkoutStore.isZoneEnabledForDelivery) &&
                        <div className={style.noDeliveryPrice}>
                            {t("cart:wrongAddress")}
                        </div>
                    }


					<br/>
				</PaperBlock>
			</FadeInOutVertical>
        }


        {
            checkoutStore.deliveryDetails?.type === DeliveryType.takeaway &&
			<div style={{ position: "relative", zIndex: 20 }}>
				<FadeInOutVertical>
					<PaperBlock>
                        <p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToAddressLabels.contact")}
                        </p>
						<div style={{ marginBottom: 10, marginTop: 10 }}>
							<PhoneInput
								country={'ru'}
								enableSearch={true}
								value={checkoutStore.deliveryDetails.phoneNumber}
								inputProps={{
                                    required: true,
                                }}
								onChange={(phone: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.takeaway) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            phoneNumber: phone
                                        })
                                    }
                                }}
							/>
						</div>
                        <input
                            className={style.deliverySettingsAddressInput}
                            placeholder={t("cart:deliveryToAddressLabels.name")}
                            value={checkoutStore.deliveryDetails.name || ''}
                            onChange={changeEventHandlerDecorator((value: string) => {
                                if (checkoutStore.deliveryDetails?.type === DeliveryType.takeaway) {
                                    checkoutStore.setDeliveryDetails({
                                        ...checkoutStore.deliveryDetails,
                                        name: value
                                    })
                                }
                            })}
                        />

					</PaperBlock>
				</FadeInOutVertical>
			</div>
        }

        {
            checkoutStore.deliveryDetails?.type === DeliveryType.takeawayToTime &&
            <div style={{ position: "relative", zIndex: 20 }}>
                <PaperBlock>
                    <div>
                        <p className={style.deliverySettingsTitle}>
                            {t("cart:timeToPickUp")}?
                        </p>
                        {
                            isTakeAwayToTimeDateRequired() && <div style={{ marginTop: 10 }}>
                                <Select options={dateOptions} value={datePickerValue} onChange={(value) => {
                                    if (value?.value) {
                                        const date = moment(value.value, 'DD.MM.YYYY')
                                        date.set('hours', 12)
                                        setDatePickerValue(value)
                                        updateTimeToPickUp(date)
                                        if (checkoutStore.deliveryDetails?.type === DeliveryType.takeawayToTime) {
                                            checkoutStore.setDeliveryDetails({
                                                ...checkoutStore.deliveryDetails,
                                                date: date.toDate(),
                                            })
                                        }
                                    }
                                }}/>
                            </div>
                        }
                        <div className={style.timeToPickUp}>
                            {
                                !isTakeAwayTimeSet() && !openTimePicker &&
                                <div>
                                    <SmallGreyText>
                                        {t("cart:asapTime")}
                                    </SmallGreyText>
                                </div>
                            }

                            {/*<Timeit*/}
							{/*	// minuteExclude={[*/}
                            {/*    //     1, 2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22,*/}
                            {/*    //     23, 24, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,*/}
                            {/*    //     41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,*/}
                            {/*    //     58, 59*/}
                            {/*    // ]}*/}
                            {/*/>*/}
                            {   (isTakeAwayTimeSet() || openTimePicker) &&
                                <div>
                                    <TimePicker
                                        className={style.timepicker}
                                        defaultValue = {timePickerValue}
                                        showSecond={false}
                                        // showMinute={!isMagnitMenu()}
                                        disabledHours={disabledHours}
                                        disabledMinutes={disabledMinutes}
                                        hideDisabledOptions={true}
                                        open={openTimePicker}
                                        value={timePickerValue}
                                        onChange={(v)=> {
                                            if (v) {
                                                setTimePickerValue(v);
                                                if (checkoutStore.deliveryDetails?.type === DeliveryType.takeawayToTime) {
                                                    updateTimeToPickUp(undefined, v.get('hours'), v.get('minutes'))

                                                    checkoutStore.setDeliveryDetails({
                                                        ...checkoutStore.deliveryDetails,
                                                        toHour: (v).toDate().getHours(),
                                                        toMinute: (v).toDate().getMinutes()
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                    </TimePicker>
                                    <SmallGreyText>
                                        {isTakeAwayTimeSet() && timePeriod >= 0 && <>
                                            {getTimePeriodForm()}
                                        </>
                                        // < Trans i18nKey="general:inAMin" values ={{min : timePeriod, minutesForm: minutesForm(timePeriod)}}></Trans>
                                        }

                                        {isTakeAwayTimeSet() && timePeriod < 0 &&
                                        <>{t("cart:incorrectTime")}</>
                                        }
                                    </SmallGreyText>
                                </div>
                            }
                            <div className={style.changeTime}
                                 onClick={openTimePick}>
                                {
                                    openTimePicker ? <>{t("general:choose")}</> :<>{t("general:change")}</>
                                }
                            </div>

                        </div>
                    </div>
                </PaperBlock>
                <FadeInOutVertical>
                    <PaperBlock>
                        <p className={style.deliverySettingsTitle}>
                            {t("cart:deliveryToAddressLabels.contact")}
                        </p>
                        <div style={{ marginBottom: 10, marginTop: 10 }}>
                            <PhoneInput
                                country={'ru'}
                                enableSearch={true}
                                value={checkoutStore.deliveryDetails.phoneNumber}
                                inputProps={{
                                    required: true,
                                }}
                                onChange={(phone: string) => {
                                    if (checkoutStore.deliveryDetails?.type === DeliveryType.takeawayToTime) {
                                        checkoutStore.setDeliveryDetails({
                                            ...checkoutStore.deliveryDetails,
                                            phoneNumber: phone
                                        })
                                    }
                                }}
                            />
                        </div>
                        <input
                            className={style.deliverySettingsAddressInput}
                            placeholder={t("cart:deliveryToAddressLabels.name")}
                            value={checkoutStore.deliveryDetails.name || ''}
                            onChange={changeEventHandlerDecorator((value: string) => {
                                if (checkoutStore.deliveryDetails?.type === DeliveryType.takeawayToTime) {
                                    checkoutStore.setDeliveryDetails({
                                        ...checkoutStore.deliveryDetails,
                                        name: value
                                    })
                                }
                            })}
                        />
                    </PaperBlock>
                </FadeInOutVertical>
            </div>
        }


    </>


};


export default observer(DeliveryDetailsBlock);
