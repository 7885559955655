import { rootStore } from "./index";

const ROOT_STORE_KEY = "rootStoreKey";
export const saveStoreToLocalStorage = () => {
    const json = JSON.stringify(rootStore);
    localStorage.setItem(ROOT_STORE_KEY, json);
};


export const loadFromLocalStorage = (): string => {
    return localStorage.getItem(ROOT_STORE_KEY) || "";
};

export const saveKeyToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, value)
}

export const getKeyFromLocalStorage = (key: string) => {
    return localStorage.getItem(key)
}


export const loadFromIndexedDB = () => {
    return IndexedDBWrapper.get(ROOT_STORE_KEY) || "";
}

export const saveStoreToIndexedDB = () => {
    try{
        const json = JSON.stringify(rootStore);
        localStorage.setItem(ROOT_STORE_KEY, json);
        IndexedDBWrapper.set(ROOT_STORE_KEY, json);
    }catch (err){
        console.log(err)
    }
};

export const IndexedDBWrapper = {
    initialize() {
        return new Promise<void>((resolve, reject) => {
            // Then creates a new one
            let request = indexedDB.open("db", 1);

            request.onupgradeneeded = function() {
                if(!request.result.objectStoreNames.contains("state")){
                    request.result.createObjectStore('state')
                }
            }

            request.onsuccess = () => {
                if(request.result.objectStoreNames.contains("state")){
                    resolve()
                }
            }


            request.onerror = function() {
                reject(request.error)
            }
        })
    },

    get(key: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let oRequest = indexedDB.open('db')
            oRequest.onsuccess = function() {
                let db = oRequest.result
                let tx = db.transaction('state', 'readonly')
                let st = tx.objectStore('state')
                let gRequest = st.get(key)
                gRequest.onsuccess = function() {
                    resolve(gRequest.result)
                }
                gRequest.onerror = function() {
                    reject(gRequest.error)
                }
            }
            oRequest.onerror = function() {
                reject(oRequest.error)
            }
        })
    },

    set(key: string, value: string) {
        return new Promise<void>((resolve, reject) => {
            let oRequest = indexedDB.open('db')
            oRequest.onsuccess = function() {
                let db = oRequest.result
                let tx = db.transaction('state', 'readwrite')
                let st = tx.objectStore('state')
                let sRequest = st.put(value, key)
                sRequest.onsuccess = function() {
                    resolve()
                }
                sRequest.onerror = function() {
                    reject(sRequest.error)
                }
            }
            oRequest.onerror = function() {
                reject(oRequest.error)
            }
        })
    },

    remove(key: string) {
        return new Promise<void>((resolve, reject) => {
            let oRequest = indexedDB.open('db')
            oRequest.onsuccess = function() {
                let db = oRequest.result
                let tx = db.transaction('state', 'readwrite')
                let st = tx.objectStore('state')
                let rRequest = st.delete(key)
                rRequest.onsuccess = function() {
                    resolve()
                }
                rRequest.onerror = function() {
                    reject(rRequest.error)
                }
            }
            oRequest.onerror = function() {
                reject(oRequest.error)
            }
        })
    }
}




export const dropLocalState = () => {
    localStorage.removeItem(ROOT_STORE_KEY);
    IndexedDBWrapper.remove(ROOT_STORE_KEY);
}
