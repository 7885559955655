import React, {useEffect} from "react";
import BackButton from "../../components/buttons/backButton";
import style from "./style.module.sass";
import {Trans, useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {FadeInOutVertical} from "components/Animated";
import {useStores} from "hooks";
import {DeliveryType, MenuType} from "services/menu/models";
import {useHistory} from "react-router";
import {Order, OrderStatus} from "services/order/models";
import newOrderIcon from "../../assets/new-order.svg";
import cancelledOrderIcon from "../../assets/cancelled-order.svg";
import acceptedOrderIcon from "../../assets/accepted-order.svg";
import TitleList from "../../components/titles/titleList";
import {PaperBlock} from "../../components/paperBlock";
import {SimpleGreyBackgroundContainer} from "../../components/containers/simpleGreyBackgroundContainer";


const OrdersList = () => {
    const {ordersStore, generalStore} = useStores();
    const {t} = useTranslation(['cart', 'general']);
    const history = useHistory();

    const orders = ordersStore.getOrdersByMenuId(generalStore.menuId, MenuType.regular) as Order[];

    useEffect(() => {
        if (ordersStore.orders && ordersStore.orders.length > 1) ordersStore.forceReload();
        ordersStore.currentOrder = undefined;
    }, [ordersStore])

    const navigateTo = (orgId: number, branchId: number, menuId: number, orderId: number) => {
        history.push(`/order/${orgId}/${branchId}/${menuId}/${orderId}/view`);
    };

    const getOrderNumber = (order: Order) => {
        if (order.externalId) {
            if (order.externalOrderNumber) {
                return `${order.externalOrderNumber}`
            } else {
                if (order.status == OrderStatus.new) {
                    return ` ---`
                } else {
                    return `${order.id}`
                }
            }
        } else {
            return `${order.id}`
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            ordersStore.reloadOrdersHistory(MenuType.regular)
        }, 1000 * 60);
        ordersStore.reloadOrdersHistory(MenuType.regular);
        return () => {
            clearInterval(timer);
        }
    }, [ordersStore.orders.length]);

    return (
        <SimpleGreyBackgroundContainer>
            <div className={style.header}>
                <BackButton/>
                <TitleList>
                    {t('cart:orders')}
                </TitleList>
            </div>
            <FadeInOutVertical>
                <>
                    {orders.map(order => {
                        return (
                            <PaperBlock key={order.id.toString()}>
                                <div
                                    onClick={() => {
                                        navigateTo(order.organizationId, order.organizationBranchId, order.menuId, order.id)
                                    }}
                                    className={style.orderListItem}
                                >
                                    <div>
                                        <div className={style.orderId}>
                                            #{getOrderNumber(order)}
                                        </div>
                                        {(order.deliveryDetails.type === DeliveryType.insideTheRestaurant) &&
                                        <div className={style.deliveryType}>
                                            {t('cart:deliveryType.InsideTheRestaurant')}
                                        </div>
                                        }
                                        {(order.deliveryDetails.type === DeliveryType.takeaway) &&
                                        <>
                                            <div className={style.deliveryType}>
                                                {t('cart:deliveryType.TakeAway')}
                                            </div>
                                            <div
                                                className={style.deliveryDetailes}>{order.deliveryDetails.phoneNumber}</div>
                                        </>
                                        }
                                        {(order.deliveryDetails.type === DeliveryType.takeawayToTime) &&
                                            <>
                                                <div className={style.deliveryType}>
                                                    {t('cart:deliveryType.TakeAwayToTime')}
                                                </div>
                                                <div className={style.deliveryDetailes}>{order.deliveryDetails.phoneNumber}</div>
                                                <span> В {order.deliveryDetails.toHour}:{order.deliveryDetails.toMinute}</span>
                                            </>
                                        }
                                        {(order.deliveryDetails.type === DeliveryType.toTable) &&
                                        <>
                                            <div className={style.deliveryType}>
                                                {t('cart:deliveryType.ToRestaurantTable')}
                                            </div>

                                            <div className={style.deliveryDetailes}>
                                                <Trans i18nKey="cart:tableNumber"
                                                       values={{tableId: order.deliveryDetails.tableId}}/>
                                            </div>
                                        </>
                                        }
                                        {(order.deliveryDetails.type === DeliveryType.toVehicle) &&
                                        <>
                                            <div className={style.deliveryType}>
                                                {t('cart:deliveryType.ToVehicle')}
                                            </div>
                                            <div className={style.deliveryDetailes}>
                                                <div>{order.deliveryDetails.vehicleBrandName}, {order.deliveryDetails.vehicleColor}</div>
                                                <div>{order.deliveryDetails.phoneNumber}</div>
                                            </div>
                                        </>
                                        }
                                        {(order.deliveryDetails.type === DeliveryType.toAddress) &&
                                        <>
                                            <div className={style.deliveryType}>
                                                {t('cart:deliveryType.ToAddress')}
                                            </div>
                                            <div className={style.deliveryDetailes}>
                                                <div>{order.deliveryDetails.address}</div>
                                                <div>{order.deliveryDetails.phoneNumber}</div>
                                            </div>
                                        </>
                                        }
                                        {order.status === OrderStatus.new &&
                                        <div className={style.statusLabel}>
                                            <img src={newOrderIcon} alt=''/>
                                            <div className={style.newOrderIcon}>
                                                {t('general:newOrder')}
                                            </div>
                                        </div>
                                        }
                                        {(order.status === OrderStatus.canceled || order.status === OrderStatus.canceledWithRefund) &&
                                        <div className={style.statusLabel}>
                                            <img src={cancelledOrderIcon} alt=''/>
                                            <div className={style.cancelledOrderIcon}>
                                                {t('general:cancelledOrder')}
                                            </div>
                                        </div>
                                        }
                                        {(order.status !== OrderStatus.canceled && order.status !== OrderStatus.canceledWithRefund && order.status !== OrderStatus.new) &&
                                        <div className={style.statusLabel}>
                                            <img src={acceptedOrderIcon} alt=''/>
                                            <div className={style.acceptedOrderIcon}>
                                                {t('general:acceptedOrder')}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <svg className={style.arrowIcon} xmlns="http://www.w3.org/2000/svg" width="43.837"
                                         height="83.159" viewBox="0 0 43.837 83.159">
                                        <g id="Group_94" data-name="Group 94" transform="translate(-410.63 -74.425)">
                                            <g id="Group_93" data-name="Group 93" transform="translate(116 6)">
                                                <rect id="Rectangle_113" data-name="Rectangle 113" width="11.014"
                                                      height="55.072" rx="5.507"
                                                      transform="matrix(-0.766, 0.643, -0.643, -0.766, 338.467, 144.504)"
                                                      fill="#dadada"/>
                                                <rect id="Rectangle_114" data-name="Rectangle 114" width="11.015"
                                                      height="55.072" rx="5.507"
                                                      transform="translate(330.03 68.425) rotate(40)" fill="#dadada"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </PaperBlock>
                        )
                    })
                    }
                </>
            </FadeInOutVertical>
        </SimpleGreyBackgroundContainer>
    )
}

export default observer(OrdersList);
