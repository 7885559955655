import { observer } from "mobx-react";
import style from "./style.module.sass"
import { FadeInOutVertical } from "../../../components/Animated";
import orderCreatedImg from "../../../assets/order-created-success.png"
import React from "react";
import { useHistory } from "react-router";
import { useStores } from "../../../hooks";
import useQuery from "../../../hooks/useQuery";
import { useTranslation } from "react-i18next";



const PaymentResultSuccessFull = () => {
    const history = useHistory();
    const {generalStore} = useStores();
    const {orderId} = useQuery<{orderId?: string}>()
    const {t} = useTranslation(['general'])
    return <div className={style.container}>
        <FadeInOutVertical>
            <div className={style.block}>
                <img src={orderCreatedImg} alt=""/>
                <p className={style.header}>{t("general:thanksForYourOrder.title")}</p>
                <p className={style.text}>
                    {t("general:thanksForYourOrder.description")}
                </p>

                <div className={style.button} onClick={() => {
                    const {organizationId, organizationBranchId, menuId} = generalStore;
                    if(orderId){
                        history.push(`/order/${organizationId}/${organizationBranchId}/${menuId}/${orderId}/view`);
                    }else{
                        history.push(
                            `/menu?orgId=${organizationId}&branchId=${organizationBranchId}&menuId=${menuId}&tableId=${generalStore.tableId}`
                        );
                    }
                }}>
                    {
                        t("general:thanksForYourOrder.viewOrder")
                    }
                </div>
            </div>
        </FadeInOutVertical>

    </div>
};


export default observer(PaymentResultSuccessFull);
