import profitableImage from "./assets/profitable.png";
import style from "../style.module.sass";
import {useTranslation} from "react-i18next";

const ProfitableBadge = () => {

    const { i18n, t } = useTranslation(["cart"]);
    return (
            <div className={style.profitableBadge}>
                <img src={profitableImage} alt=''
                />
                <p>{t("cart:profitable")}</p>
            </div>
    )
}

export default ProfitableBadge;
