import React, { RefObject } from 'react';
import { motion } from 'framer-motion';
import debounce from './debounce';
import style from "../style.module.sass"
import {useStores} from "../../../../../hooks";
import {useTranslation} from "react-i18next";

interface IProps {
    refs: {[s: string]: RefObject<HTMLDivElement>},
    activeCategoryId: number,
    finishAnimating: () => void;
    animating: boolean
}
const ActiveCategoryHover = ({ refs, activeCategoryId, finishAnimating, animating }: IProps) => {
    const [{ x, width }, setAttributes] = React.useState({
        x: 0,
        width: 0,
    });
    const {generalStore, menuStore} = useStores();
    const { i18n } = useTranslation();

    const categoriesColor = menuStore.menu?.data?.themeSettings?.categoriesBackgroundColor || 'black'

    // A memoised function to update the state using the active element
    const updateAttributes = React.useCallback(() => {
        if (refs && refs[activeCategoryId] && refs[activeCategoryId].current) {
            setAttributes({
                x: refs[activeCategoryId]!.current!.offsetLeft - 15 ,
                width: refs[activeCategoryId]!.current!.getBoundingClientRect().width + 20,
            });
        }
    }, [activeCategoryId, refs, generalStore.locale.id]);





    React.useEffect(() => {
        updateAttributes();
    }, [activeCategoryId, updateAttributes]);

    React.useEffect(() => {
        const recalculateAttrs = debounce(() => {
            updateAttributes();
        }, 500, false);

        window.addEventListener('resize', recalculateAttrs);
        return () => {
            window.removeEventListener('resize', recalculateAttrs);
        };
    });

    return (
        <motion.div
            className={style.hoverElement}
            animate={{
                x,
                width,
            }}
            style={{ background: categoriesColor }}
            // Only show this when animating
            onAnimationComplete={finishAnimating}
        />
    );
};

export default ActiveCategoryHover;
