import { observer } from "mobx-react";
import { useStores } from "../../../../hooks";
import style from "./style.module.sass"
import SmallGreyText from "../../../../components/titles/SmallGreyTitle";
import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import classNames from "classnames"
import { useTranslation } from "react-i18next";


const BookingTimeSlots = () => {

    const { bookingMenuStore, bookingCheckoutStore } = useStores();
    const item = bookingMenuStore.selectedPopupItem;
    const { t } = useTranslation(['menu', 'contactDetails']);


    const days = [t('contactDetails:sunday'), t('contactDetails:monday'), t('contactDetails:tuesday'),
        t('contactDetails:wednesday'), t('contactDetails:thursday'), t('contactDetails:friday'), t('contactDetails:saturday')];
    const bookings = bookingCheckoutStore.booking;


    const getSlots = async (date: Moment) => {
        if (!item) return;
        await bookingCheckoutStore.getTimeSlots(date, item?.id);
    }

    useEffect(() => {
        getSlots(bookingCheckoutStore.dateForAvailableSlots);
    }, [])

    let dateContent = [];

    const today = moment();

    for (let i = 0; i < 7; i++) {
        const day = today.clone().add(i, 'days');
        dateContent.push(
            <div
                key={day.toDate().getDate()}
                className={classNames({
                    [style.dateButton]: true,
                    [style.checked]: bookingCheckoutStore.dateForAvailableSlots.toDate().getDate() === day.toDate().getDate(),

                })}
                onClick={() => {
                    if (bookingCheckoutStore.dateForAvailableSlots) {
                        getSlots(day);
                    }
                }}
            >
                <p>{day.date()}</p>
					<p>{days[day.weekday()]}</p>
            </div>
        );
    }

    let slotContent = [];
    const offset = bookingMenuStore.bookingMenu.data.workingSchedule.timezoneInMinutes;

    if (item) {
        const checkingSlotsContent = (fromDate: Moment): boolean => {
            const slot = bookingCheckoutStore.chosenBookingSlots.filter((slot) => {
                return slot.fromDate.toDate().getTime() === fromDate.clone().toDate().getTime()
            });
            return slot.length > 0;
        };

        const deleteSlot = (fromDate: Moment) => {
            const newValue = [...bookingCheckoutStore.chosenBookingSlots];
            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i].fromDate.toDate().getTime() === fromDate.clone().toDate().getTime()) {
                    newValue.splice(i, 1);
                    break;
                }
            }
            bookingCheckoutStore.setChosenBookingSlots(newValue)
        }
        const deleteBookedAmount = (amount: number) => {
            const newValue = [...bookingCheckoutStore.bookedAmountForDate];
            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i] === amount) {
                    newValue.splice(i, 1);
                    break;
                }
            }
            bookingCheckoutStore.setBookedAmountForDate(newValue);
        }

        const checkingBookings = (fromDate: Moment): boolean => {
            const booking = bookings.filter((booking) => {
                const bookingDate = moment(booking.data.fromDate).utcOffset(offset)
                return bookingDate.unix() === fromDate.clone().unix();
            });
            return booking.length > 0;
        };

        const getBookedAmount = (fromDate: Moment): number => {
            const booking = bookings.filter((booking) => {
                const bookingDate = moment(booking.data.fromDate).utcOffset(offset)
                return bookingDate.unix() === fromDate.clone().unix()
            });
            const amount = booking.reduce((sum, a) => sum + a.data.amount, 0);
            return amount;
        }


        const startSlotDate: Moment = bookingCheckoutStore.dateForAvailableSlots.clone().utcOffset(offset);
        const endSlotDate: Moment = bookingCheckoutStore.dateForAvailableSlots.clone().utcOffset(offset);

        const startTime = startSlotDate
            .hours(item.data.bookingScheduleSettings.fromHour)
            .minutes(item.data.bookingScheduleSettings.fromMinute)
            .startOf('minutes')

        const endTime = endSlotDate.hours(item.data.bookingScheduleSettings.toHour)
            .minutes(item.data.bookingScheduleSettings.toMinute)
            .startOf('minutes')

        //in minutes
        const step: number = item.data.bookingScheduleSettings.step;

        const interval = () => {
            let res: number;
            if (step < 15) res = step * 3600;
            else res = step * 60;
            return res * 1000;
        }
        const amountOfSlots = (endTime.toDate().getTime() - startTime.toDate().getTime()) / interval();

        let currentSlot = startTime.clone();
        for (let i = 0; i < amountOfSlots; i++) {
            const fromDate = currentSlot.clone()
            const toDate = currentSlot.clone().add(interval(), 'milliseconds');

            slotContent.push(
                <div
                    key={fromDate.toISOString()}
                    className={classNames({
                        [style.slot]: true,
                        [style.checked]: checkingSlotsContent(fromDate),
                        [style.disabled]: (checkingBookings(fromDate) && ((item.data.amount - getBookedAmount(fromDate)) === 0))
                    })}
                    onClick={() => {
                        if (checkingBookings(fromDate) && ((item.data.amount - getBookedAmount(fromDate)) === 0)) return null;
                        if (checkingSlotsContent(fromDate)) {
                            deleteBookedAmount(item.data.amount - getBookedAmount(fromDate));
                            deleteSlot(fromDate);
                        } else {
                            bookingCheckoutStore.setChosenBookingSlots([...bookingCheckoutStore.chosenBookingSlots, {
                                fromDate: fromDate,
                                toDate: toDate
                            }]);
                            bookingCheckoutStore.addBookedAmountForDate(item.data.amount - getBookedAmount(fromDate));
                        }
                    }}
                >
                    {fromDate.utcOffset(offset).format("HH:mm")} - {toDate.utcOffset(offset).format("HH:mm")}
                    <SmallGreyText>доступно: {item.data.amount - getBookedAmount(fromDate)}</SmallGreyText>
                </div>
            );
            currentSlot.add(interval(), 'milliseconds');
        }

    }


    return (
        <div>
            <h3>{t("menu:bookingSlots.date")}</h3>
            <div className={style.dateContainer}>
                {dateContent}
            </div>

            <h3>{t("menu:bookingSlots.time")}</h3>
            <SmallGreyText>{t("menu:bookingSlots.severalSlots")}</SmallGreyText>
            <div className={style.timeContainer}>
                {slotContent}
            </div>

        </div>
    )

}
export default observer(BookingTimeSlots)
