import React, { Ref, RefObject, useEffect, useRef } from "react";
import style from "./style.module.sass"
import { useStores } from "./../../../../../hooks";
import { observer } from "mobx-react";
import { MenuCategory } from "./../../../../../services/menu/models";
import {Trans, useTranslation} from "react-i18next";


interface ITabProps {
    item: MenuCategory,
    ref: RefObject<HTMLDivElement>,
    startAnimating: () => void;
    animating: boolean,
    active: boolean,
    setActiveCategory: (id: number,) => void;
    scrollTo: (left: number) => void;
    currentScrollX: number
}

const Tab = React.forwardRef(
    (
        (props: ITabProps, ref: Ref<HTMLDivElement>) => {
            const {i18n} = useTranslation()
            const { item, active, setActiveCategory } = props;
            const { menuStore, generalMenuStore, generalStore } = useStores();
            const classes = [style.item];
            if (active) {
                classes.push(style.active)
            }

            const categorizedItems = menuStore.items[item.id.toString()]

            let text = "";
            if (generalStore.locale.id === "ru") {
                if (categorizedItems.length % 10 === 1 && (categorizedItems.length < 10 || categorizedItems.length > 21)) text += "ия"
                else if ((categorizedItems.length % 10 === 2 ||
                    categorizedItems.length % 10 === 3 ||
                    categorizedItems.length % 10 === 4) && (categorizedItems.length < 10 || categorizedItems.length > 21)) text+= "ии"
                else if (categorizedItems.length > 10 && categorizedItems.length < 21) text+= "ий"
                else text+= "ий"
            }


            return  <div
                className={classes.join(" ")}
                ref={ref}
                onClick={() => {
                    setActiveCategory(item.id);
                }}
            >
                <span className={style.categoryName}>{item.data.name[i18n.language]}</span>
                <span className={style.amountPositions}>
                    <Trans i18nKey="menu:categoryItem" values={
                    {amount: `${categorizedItems.length}`}
                    }/>
                    {text}
                </span>
            </div>
        })
);

const SideMenu = () => {
    const [animating, setAnimating] = React.useState(false);
    const { menuStore, generalMenuStore } = useStores();
    const tabRefs = menuStore.categories.reduce((acc, item) => {
        acc[item.id] = React.createRef();
        return acc;
    }, {} as { [s: string]: any });


    const tabsRef = useRef<HTMLDivElement>(null);

    const scrollTo = (top: number) => {
        if(tabsRef && tabsRef.current){
            tabsRef!.current!.scrollTo({top: top, behavior: "smooth"})
        }
    };


    return <div>

        <div ref={tabsRef} className={style.menuContainer}>
            {menuStore.categories.map((item, index) => {

                return <>
                    <div className={style.hover}/>
                    <Tab
                        key={item.id}
                        item={item}
                        ref={tabRefs[item.id]}
                        startAnimating={() => setAnimating(true)}
                        animating={animating}
                        active={item.id === menuStore.activeCategory}
                        setActiveCategory={(id: number) => {
                            menuStore.setActiveCategory(id, true);
                        }}
                        scrollTo={scrollTo}
                        currentScrollX={tabsRef?.current?.scrollHeight || 0}
                    />
                </>
            })}
        </div>

    </div>
};

export default observer(SideMenu);
