import {action, computed, makeAutoObservable} from "mobx"
import {ChosenMenuItemModifierSet, ICartBookingItem, ICartItem} from "../types/types";
import {saveStoreToIndexedDB} from "./persistence";
import {rootStore} from "./index";
import {MenuBookingItem, MenuItem, MenuType} from "../services/menu/models";
import moment, {Moment} from "moment-timezone";
import {Coupon, OrderItemType} from "../services/order/models";


class Cart {
    list: ICartItem[] = [];
    bookingList: ICartBookingItem[] = [];
    menuId: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    getExactlyTheSameEntry(cartItem: ICartItem | ICartBookingItem, chosenMenuItemModifierSets: ChosenMenuItemModifierSet[]) {
        return cartItem.items.findIndex(item => {
            if (item.chosenMenuItemModifierSets.length !== chosenMenuItemModifierSets.length) {
                return false;
            }
            let result = true;
            chosenMenuItemModifierSets.forEach((set, index) => {
                const ciSet = item.chosenMenuItemModifierSets[index];
                if (ciSet.menuItemModifierSetId !== set.menuItemModifierSetId) {
                    return false
                }
                result = result && JSON.stringify(ciSet.chosenMenuItemModifiers) === JSON.stringify(set.chosenMenuItemModifiers);
            })
            return result;
        });

    }

    @action
    addItem(menuItem: any, chosenMenuItemModifierSets: ChosenMenuItemModifierSet[], count: number) {

        const item = this.list.find((item) => item.menuItemId === menuItem.id);
        // товар уже есть
        if (item) {
            const entryIndex = this.getExactlyTheSameEntry(item, chosenMenuItemModifierSets);
            if (entryIndex >= 0) {
                const entry = item.items[entryIndex];
                entry.count += count;
                item.count += count;
            } else {
                item.count += count;
                item.items.push({
                    count,
                    menuItem,
                    chosenMenuItemModifierSets
                });
            }
            saveStoreToIndexedDB();
            return;
        }

        // товара нет
        this.list.push({
            menuItemId: menuItem.id,
            count: count,
            items: [
                {
                    menuItem,
                    chosenMenuItemModifierSets,
                    count
                }
            ]
        });
        saveStoreToIndexedDB();
    }

    @action
    addBookingItem(menuItem: MenuBookingItem | MenuItem, chosenMenuItemModifierSets: ChosenMenuItemModifierSet[], chosenBookingSlots: { fromDate: Moment; toDate: Moment }[], count: number) {
        const item = this.bookingList.find((item) => item.menuItemId === menuItem.id);

        // товар уже есть
        if (item) {
            const entryIndex = this.getExactlyTheSameEntry(item, chosenMenuItemModifierSets);
            if (entryIndex >= 0) {
                const entry = item.items[entryIndex];
                if (entry.itemType === OrderItemType.booking) {
                    entry.chosenBookingSlots.push(...chosenBookingSlots);
                    const t: {[key: number]: boolean} =  {};
                    entry.chosenBookingSlots = entry.chosenBookingSlots.filter(slot =>{
                        if(t[slot.fromDate.unix()]){
                            return false
                        }
                        t[slot.fromDate.unix()] = true;
                        return true;

                    } )
                }

            } else {
                item.count += count;
                item.items.push({
                        count,
                        menuItem,
                        itemType: OrderItemType.booking,
                        chosenMenuItemModifierSets,
                        chosenBookingSlots: chosenBookingSlots
                    }
                );
            }
            saveStoreToIndexedDB();
            return;
        }

        // товара нет
        this.bookingList.push({
            menuItemId: menuItem.id,
            count: count,
            items: [
                {
                    menuItem,
                    chosenMenuItemModifierSets,
                    count,
                    itemType: OrderItemType.booking,
                    chosenBookingSlots: chosenBookingSlots
                }
            ],

        });
        saveStoreToIndexedDB();
    }


    @action
    removeItem(menuItemId: number, type: MenuType, options: { all: boolean, indexToRemove: number } = {
        all: false,
        indexToRemove: -1
    }) {
        let index;
        if (type === MenuType.booking) index = this.bookingList.findIndex((item) => item.menuItemId === menuItemId);

        else index = this.list.findIndex((item) => item.menuItemId === menuItemId);

        if (index < 0) {
            return;
        }
        let item;
        if (type === MenuType.booking) item = this.bookingList[index]
        else item = this.list[index];

        if (item.count > 1 && !options.all) {
            if (options.indexToRemove >= 0) {
                const entry = item.items[options.indexToRemove];
                if (entry.count === 1) {
                    item.items.splice(options.indexToRemove, 1);
                } else {
                    entry.count--;
                }
            } else {
                if (item.items[item.items.length - 1].count > 1) {
                    item.items[item.items.length - 1].count--;
                } else {
                    item.items.pop()
                }
            }

            item.count -= 1;
            saveStoreToIndexedDB();
            return;
        }
        if (type === MenuType.booking) this.bookingList.splice(index, 1);
        else this.list.splice(index, 1);
        saveStoreToIndexedDB();
    }

    @computed
    getDiscountAmount(type: MenuType, discountPercent: number) {
        return this.list.reduce((memo, item) => {
            return memo + item.items.reduce((memo2, item2, index2) => {

                let itemTotal = this.getTotalByMenuItem(item2.menuItem.id, index2, type)

                if (![5155, 139].includes(item2.menuItem.data.menuCategoryId)) {
                    return memo2 + (itemTotal * (discountPercent / 100))
                } else {
                    return memo2
                }
            }, 0)
        }, 0)
    }

    @computed
    getTotal(type: MenuType) {
        if (type === MenuType.booking) {
            return this.bookingList.reduce((memo, item) => {
                return memo + item.items.reduce((memo2, item2, index2) => {
                    return memo2 + this.getTotalByMenuItem(item2.menuItem.id, index2, type)
                }, 0)
            }, 0)
        } else {
            return this.list.reduce((memo, item) => {
                return memo + item.items.reduce((memo2, item2, index2) => {
                    return memo2 + this.getTotalByMenuItem(item2.menuItem.id, index2, type)
                }, 0)
            }, 0)
        }

    }

    @computed
    getTotalByMenuItem(menuItemId: number, index: number, type: MenuType) {
        let item: ICartItem | ICartBookingItem;
        type === MenuType.regular ? item = <ICartItem>this.list.find(item => item.menuItemId === menuItemId) :
            item = <ICartBookingItem>this.bookingList.find(item => item.menuItemId === menuItemId);
        if (!item) return -50000;


        if(type === MenuType.regular){
            const t = (item as ICartItem).items[index];

            let pricePerItem = t.menuItem.data.sellPrice.price;

            for (let modifiersSets of t.chosenMenuItemModifierSets) {
                const set = rootStore.menuStore.getModifiersById(modifiersSets.menuItemModifierSetId);
                for (let modifier of modifiersSets.chosenMenuItemModifiers) {
                    const obj = set.data.modifiers.find(item => item.id === modifier.menuItemModifierId);
                    if (obj) {
                        pricePerItem += obj.data.sellPrice.price * (modifier?.count || 1)
                    }
                }
            }
            return pricePerItem * t.count;
        }else if(type === MenuType.booking){
            const t = (item as ICartBookingItem).items[index];

            let pricePerItem = t.menuItem.data.sellPrice.price;
            for (let modifiersSets of t.chosenMenuItemModifierSets) {
                const set = rootStore.bookingMenuStore.getModifiersById(modifiersSets.menuItemModifierSetId);
                for (let modifier of modifiersSets.chosenMenuItemModifiers) {
                    const obj = set.data.modifiers.find(item => item.id === modifier.menuItemModifierId);
                    if (obj) {
                        pricePerItem += obj.data.sellPrice.price * (modifier?.count || 1)
                    }
                }
            }
            return pricePerItem * t.count * t.chosenBookingSlots.length;
        }
        return -50000;

    }

    @computed
    getCartItem(menuItem: MenuItem | MenuBookingItem, type: MenuType) {
        if (type === MenuType.regular) return this.list.find((item) => item.menuItemId === menuItem.id);
        else return this.bookingList.find((item) => item.menuItemId === menuItem.id);
    }

    @computed
    getCartItemById(menuItemId: number, type: MenuType) {
        if (type === MenuType.regular) return this.list.find((item) => item.menuItemId === menuItemId);
        else return this.bookingList.find((item) => item.menuItemId === menuItemId);
    }

    @computed
    isInCart(menuItemId: number, type: MenuType) {
        return !!this.getCartItemById(menuItemId, type);
    }


    @computed
    isCartEmpty(type: MenuType) {
        if (type === MenuType.regular) return this.list.length === 0;
        else return this.bookingList.length === 0
    }

    @action
    setList(list: ICartItem[]) {
        this.list = list;
    }

    @action
    setBookingList(list: ICartBookingItem[]) {
        this.bookingList = list;
    }

    @action
    setMenuId(menuId: number, type: MenuType) {

        if (this.menuId !== menuId) {
            (type === MenuType.regular) ? this.setList([]) : this.setBookingList([])
        }
        this.menuId = menuId;
    }


    @action
    reset() {
        this.list = [];
        this.bookingList = []
        saveStoreToIndexedDB();
    }

    static fromJSON(obj: any) {
        const cart = new Cart();
        cart.menuId = obj.menuId || 0;
        cart.setList(obj.list);

        if (obj.bookingList) {
            //@ts-ignore
            cart.setBookingList(obj.bookingList.map((booking) => {
                return {
                    ...booking,
                    //@ts-ignore
                    items: booking.items.map((item) => {
                        return {
                            ...item,
                            //@ts-ignore
                            chosenBookingSlots: item.chosenBookingSlots.map((slot) => {
                                return {
                                    fromDate: moment(slot.fromDate),
                                    toDate: moment(slot.toDate),
                                }
                            })
                        }
                    })
                }
            }));
        } else {
            cart.setBookingList([])
        }

        return cart;
    }

    @action
    filterCart(type: MenuType) {
        let items;
        (type === MenuType.regular) ? items = rootStore.menuStore.items :
            items = rootStore.bookingMenuStore.bookingItems;

        const obj = Object.entries(items)
            .reduce((memo, entry) => {
                for (let item of entry[1]) {
                    memo[item.id] = true;
                }
                return memo;
            }, {} as { [s: string]: boolean });

        (type === MenuType.regular) ? this.list = this.list.filter((item) => {
                return obj[item.menuItemId];
            }) :
            this.bookingList = this.bookingList.filter((item) => {
                return obj[item.menuItemId];
            });


        saveStoreToIndexedDB()
    }


}


export default Cart
