import { Ref, useEffect, useState } from "react";
import throttle from "lodash.throttle";


export default function useVisibility(
    ref: Ref<HTMLParagraphElement>,
    offset = 0,
    throttleMilliseconds = 100
): [boolean, number] {
    const [isVisible, setIsVisible] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);



    useEffect(() => {
        const onScroll = throttle(() => {

            //console.log(ref);
            //@ts-ignore
            if (!ref || !ref.current) {
                setIsVisible(false);
                return;
            }

            //@ts-ignore
            const top = ref.current.getBoundingClientRect().top;
            setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
            setOffsetTop(top);
        }, throttleMilliseconds);

        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    }, [ref]);

    return [isVisible, offsetTop];
}
