import { action, computed, makeAutoObservable, runInAction } from "mobx"
import content from "../content.json"
import { MenuItemModifierSet } from "../../types/types";
import MenuService from "../../services/menu";
import { ExecutionStatus } from "../../apiCommandsExecutor/api";
import {
    DeliveryType,
    Menu, MenuBookingCategory, MenuBookingItem,
    MenuCategory,
    MenuItem,
    ModifiersSetType,
    Table,
    WorkingScheduleDay
} from "../../services/menu/models";
import { rootStore } from "../index";
import moment, { Moment } from "moment";
import manifest from "../manifest.json"


class BookingMenuViewStore {
     activeBookingCategory: number = 21;
    // scroll: boolean = false;
    isReady: boolean = false;
    // @ts-ignore
    bookingMenu: Menu = null;
    //@ts-ignore
    modifiersSet: { [s: string]: MenuItemModifierSet } = {};
    bookingCategories: MenuBookingCategory[] = [];

    //@ts-ignore
    bookingItems: { [s: string]: MenuBookingItem[] } = {};
    tables: Table[] = [];
    products = content.productsById as { [s: number]: any };

    // isModifiersPopupVisible: boolean = false;
    // isCategoryMenuVisible: boolean = false;
    // isDetailsModalVisible: boolean = false;
    // isWaiterWanted:boolean = false;
    // isWaiterCalled: boolean = false;
    selectedPopupItem?: MenuBookingItem;
    // isOverlayOpen: boolean = false;
    // isLangsModalOpen: boolean = false;

    //onOverlayClick?: () => void;


    isLoaded: boolean = false;

    status: {
        err: boolean,
        msg: string
    } = { err: false, msg: "" };

    constructor() {
        makeAutoObservable(this);
    }


    // get isChooseLangsEnabled(){
    //     return Object.values(this.bookingMenu.data.availableLanguages).filter(t => t.isEnabled).length > 1;
    // }

    async init(params: { bookingMenuId: number, organizationId: number, organizationBranchId: number }) {
        const res = await MenuService.getBookingMenu(params);
        if (res.executionStatus === ExecutionStatus.Finished) {
            runInAction(() => {
                if(!res.bookingMenu) return;
                this.bookingMenu = res.bookingMenu;
                this.modifiersSet = res.menuItemModifierSetsById;
                this.bookingItems = Object.entries(res.menuBookingItemsByCategoryId)
                    .map(([key, items]) => {
                        let res = items.filter(item => !item.data.isInStopList);
                        return [key, res] as [string, MenuBookingItem[]];
                    }).reduce((memo, entry) => {
                        memo[entry[0]] = entry[1];
                        return memo;
                    }, {} as { [s: string]: MenuBookingItem[] })
                this.activeBookingCategory = res.menuBookingCategories[0]?.id;
                this.bookingCategories = res.menuBookingCategories.filter((category) => {
                    return this.bookingItems[category.id] && this.bookingItems[category.id].length > 0
                }).sort((item1, item2) => {
                    return item1.data.sortOrder - item2.data.sortOrder
                })
                this.tables = res.tables;
            });

            if(!res.bookingMenu) return;
            rootStore.generalStore.setIsMenuAvailable(
                res.bookingMenu && res.bookingMenu.data.isMenuEnabled && this.bookingCategories.length > 0
            );
            rootStore.generalStore.setIsAcceptingOrders(res.bookingMenu.data.isOrderCreationEnabled);

            rootStore.generalStore.setViewOnlyMode(!rootStore.generalStore.isAcceptingOrders);
            if(res.bookingMenu){
                rootStore.cartStore.setMenuId(res.bookingMenu.id, res.bookingMenu.type)
                rootStore.cartStore.filterCart(res.bookingMenu.type);
            }
        } else {
            rootStore.generalStore.setIsAcceptingOrders(false);
        }

        runInAction(() => this.isLoaded = true);
    }



    @action
    setError(msg: string) {
        this.status.err = true;
        this.status.msg = msg;
    }

    @action
    removeError() {
        this.status.err = false;
        this.status.msg = "";
    }


    @action
    checkingScheduleHours() {
        if (!this.bookingMenu) return;
        const today = moment().add(this.bookingMenu.data.workingSchedule.timezoneInMinutes, 'minutes');
        const weekBefore = today.clone().subtract(7, 'days');

        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const daysMoment: {
            [s: number]: Moment
        } = [];
        let t = weekBefore.clone();
        while(t.toDate() <= today.toDate()){
            daysMoment[t.day()] = t;
            t = t.clone().add(1, 'day');
        }


        let slots: {
            from: Moment,
            to: Moment
        }[] = [];

        slots = Object.entries(this.bookingMenu.data.workingSchedule).filter(([day, item]) => {
            if(days.indexOf(day) < 0) return false;
            const schedule =  item as WorkingScheduleDay;
            return !schedule.isDayOff
        }, []).map(([day, schedule]: [string, WorkingScheduleDay]) => {
            const dayNumber = days.indexOf(day);
            const date = daysMoment[dayNumber];
            const slot = schedule.slots[0];
            let from = date.clone().hours(slot.fromHour).minutes(slot.fromMinute).add(this.bookingMenu.data.workingSchedule.timezoneInMinutes, 'minutes')
            let to = date.clone().hours(slot.toHour).minutes(slot.toMinute).add(this.bookingMenu.data.workingSchedule.timezoneInMinutes, 'minutes');
            if(slot.toHour < slot.fromHour){
                to.add(1, 'day')
            }
            return {
                from, to
            };
        })

        const result = slots.filter(slot => {
            return today.toDate() >= slot.from.toDate() && today.toDate() <= slot.to.toDate()
        }).length

        return result > 0;
}



    @computed
    get workingTime(){
        return this.checkingScheduleHours();
    }


    @action
    setIsReady(value: boolean) {
        this.isReady = value;
    }

    @action
    setIsLoaded(value: boolean) {
        this.isLoaded = value;
    }


    @action
    setActiveCategory(categoryId: number, scroll: boolean = false) {
        this.activeBookingCategory = categoryId;
        rootStore.generalMenuStore.scroll = scroll;
    }

    // @action
    // resetScroll() {
    //     this.scroll = false;
    // }


    @computed
    getProductById(id: number) {
        return this.products[id]
    }

    @computed
    getModifiersById(id: number) {

        //@ts-ignore
        return this.modifiersSet[id.toString()]
    }

    @action
    openModifiersPopup(item: MenuBookingItem, onOverlayClick: () => void) {
        rootStore.generalMenuStore.isModifiersPopupVisible = true;
        rootStore.generalMenuStore.isOverlayOpen = true;
        rootStore.generalMenuStore.onOverlayClick = onOverlayClick;
        this.selectedPopupItem = item;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeModifiersPopup() {
        rootStore.generalMenuStore.isModifiersPopupVisible = false;
        rootStore.generalMenuStore.isOverlayOpen = false;
        rootStore.generalMenuStore.onOverlayClick = undefined;
        this.selectedPopupItem = undefined;
        rootStore.bookingCheckoutStore.resetBookedAmountForDate()
        rootStore.bookingCheckoutStore.resetChosenBookingSlots();
        rootStore.bookingCheckoutStore.resetDateForAvailableSlots();
        document.body.style.overflow = 'auto';
    }

    // @action
    // openCategoryMenu( onOverlayClick: () => void) {
    //     this.isCategoryMenuVisible = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }

    // @action
    // closeCategoryMenu() {
    //     this.isCategoryMenuVisible = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }
    //
    // @action
    // openInfoDetailesModal( onOverlayClick: () => void) {
    //     this.isDetailsModalVisible = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }
    // @action
    // closeInfoDetailesModal() {
    //     this.isDetailsModalVisible = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    // @action
    // openWaiterWantedModal( onOverlayClick: () => void) {
    //     this.isWaiterWanted = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }
    // @action
    // closeWaiterWantedModal() {
    //     this.isWaiterWanted = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }
    // @action
    // openLangsModal( onOverlayClick: () => void) {
    //     this.isLangsModalOpen = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     document.body.style.overflow = 'hidden';
    // }
    // @action
    // closeLangsModal() {
    //     this.isLangsModalOpen = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    @action
    setData(obj: any) {
        //this.activeCategory = obj.activeCategory;
        //this.categories = obj.categories;
        //this.productsByCategory = obj.productsByCategory;
    }

    @computed
    getMinPrice(item: MenuBookingItem) {
        let minPrice = 0;
        item.data.menuItemModifierSets.forEach((setId) => {
            const set = this.getModifiersById(setId.menuItemModifierSetId);
            let min = Number.MAX_SAFE_INTEGER;
            set.data.modifiers.forEach((item) => {
                if (item.data.sellPrice.price < min) {
                    min = item.data.sellPrice.price
                }
            })
            if(set.data.type === ModifiersSetType.ChooseOne){
                 minPrice += min;
            }else{
                let {minCountTotalChosen, maxCountTotalChosen} = set.data;
                if(minCountTotalChosen > 0) minPrice += min;
            }
        });
        if (minPrice === Number.MAX_SAFE_INTEGER) return 0;
        return minPrice;
    }

    static fromJSON(obj: any) {
        const products = new BookingMenuViewStore();
        products.setData(obj);
        return products;
    }


    @computed
    getTableById(id: number) {
        return this.tables.find(item => item.id === id);
    }

    @computed
    getTableByPublicId(id: string) {
        return this.tables.find(item => item.data.publicId === parseInt(id));
    }

    @computed
    get storeFrontMode(){
        return this.bookingMenu.data.themeSettings.storefrontPhotosMode;
    }

    updateManifest(alias: string, name: string, logoUrl: string | null){
        const newManifest = {
            ...manifest,
            short_name: name,
            name: name,
            start_url: `${alias}`,
            icons: logoUrl ? [
                {
                    "src": logoUrl,
                    "sizes": "391x391",
                    "type": "image/jpeg"
                }
            ] : manifest.icons
        }
        const stringManifest = JSON.stringify(newManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.getElementById('my-manifest-placeholder')?.setAttribute('href', manifestURL);
        document.title = name;
        if(logoUrl){
            document.getElementById('apple-touch-icon')?.setAttribute('href',  logoUrl);
        }



    }
}


export default BookingMenuViewStore
