import {action, computed, makeAutoObservable, runInAction} from "mobx"
import {rootStore} from "./index";
import {
    BookingOrder,
    Coupon,
    LoyaltyProgram,
    NewBookingOrder,
    NewBookingOrderItemBookingItem,
    NewBookingOrderItemRegularItem,
    NewOrderPaymentDetails,
    OperationType,
    OrderItemType,
    PaymentProviderType,
    PublicMenuItemBooking,
    RecipientType
} from "../services/order/models";
import OrdersService from "../services/order";
import {ExecutionStatus} from "../apiCommandsExecutor/api";
import {CouponsGetCouponPublicRequest} from "../services/order/requestsResponses";
import moment, {Moment} from "moment-timezone";


class BookingCheckout {
    order: BookingOrder | null = null;
    paymentDetails: NewOrderPaymentDetails | null = null;
    paymentType: PaymentProviderType = PaymentProviderType.offline;
    loyaltyType: OperationType = "Withdraw";
    loyaltyPhone:string = "";
    isReadyToSubmit: boolean = false;
    comment: string = "";
    clientId: number | null = null;
    coupon: Coupon | null = null;
    failedCouponCheck: boolean = false;
    couponCode: string = "";
    isCouponCodeChecking: boolean = false;
    timer: any;
    isBonusCardModalOpen: boolean = false;
    loyaltyProgram: LoyaltyProgram | null = null;
    booking: PublicMenuItemBooking[] = [];
    chosenBookingSlots: { fromDate: Moment, toDate: Moment }[] = [];
    dateForAvailableSlots: Moment = moment();
    bookedAmountForDate: number[] = [];
    bookingTimeSlotsLoading:  boolean = false;



    constructor() {
        makeAutoObservable(this);
        this.init();
    }

    @action
    addBookedAmountForDate(amount: number) {
        this.bookedAmountForDate.push(amount);
    }
    @action
    setBookedAmountForDate(arr: number[]) {
        this.bookedAmountForDate = arr;
    }

    @action
    resetBookedAmountForDate () {
        this.bookedAmountForDate = [];
    }

    @action
    setChosenBookingSlots(slots: { fromDate: Moment; toDate: Moment }[]) {
        this.chosenBookingSlots = slots;
    }

    @action
    resetChosenBookingSlots () {
        this.chosenBookingSlots = [];
    }

    @action
    setDateForAvailableSlots(date: Moment) {
        this.dateForAvailableSlots = date;
    }

    @action
    resetDateForAvailableSlots () {
        this.dateForAvailableSlots = moment();
    }

    @action
    async init() {

    }

    isCouponValid(coupon: Coupon | null){
        if(coupon && new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now()){
            return true
        }else{
            return false
        }
    }

    @action
    setIsReadyToSubmit(value: boolean) {
        this.isReadyToSubmit = value;
    }


    @action
    setData(obj: any) {
        this.comment = obj.comment || "";
    }

    @action
    setPaymentType(type: PaymentProviderType) {
        this.paymentType = type;
    }


    @action
    setLoyaltyType(type: OperationType) {
        this.loyaltyType = type;
    }
    @action
    setLoyaltyPhone(phone: string) {
        this.loyaltyPhone = phone;
    }


    async createBookingOrder(): Promise<{ err: false, order: BookingOrder } | { err: true, msg: string }> {
        const store = rootStore;
        if(!this.paymentDetails) {
            return {
                err: true, msg: "Нет деталей оплаты"
            }
        }

        let regularItems: NewBookingOrderItemRegularItem [] = [];

        store.cartStore.bookingList.forEach(itemsByMenuId => {
            itemsByMenuId.items.forEach(item => {
            if (item.itemType === OrderItemType.regular) {
                regularItems.push({
                    chosenMenuItemModifierSets: item.chosenMenuItemModifierSets,
                    itemId: item.menuItem.id,
                    itemsCount: item.count,
                    menuId: rootStore.generalMenuStore.menu.id,
                    type: OrderItemType.regular,
                    relatedMenuBookingItemId: item.menuItem.id
                })
            }
            })
        });

        let bookingItems: NewBookingOrderItemBookingItem[] = [];
        store.cartStore.bookingList.forEach(itemsByMenuId => {
            itemsByMenuId.items.forEach(item => {
                if (item.itemType === OrderItemType.booking) {
                    bookingItems.push({
                        chosenMenuItemModifierSets: item.chosenMenuItemModifierSets,
                        itemId: item.menuItem.id,
                        itemsCount: item.count,
                        menuId: rootStore.generalMenuStore.menu.id,
                        type: OrderItemType.booking,
                        chosenMenuBookingItemSlots: item.chosenBookingSlots.map(slot => ({
                            toDate: slot.toDate.toDate(),
                            fromDate: slot.fromDate.toDate(),
                        })),
                    })
                }
            })
        });
        let items = [...regularItems, ... bookingItems];

        const order: NewBookingOrder = {
            clientSessionId: store.generalStore.clientSessionId,
            items,
            menuBookingId: store.generalStore.menuId,
            clientLocaleId: store.generalStore.locale.id,
            paymentDetails: this.paymentDetails,
            comment: this.comment,
            couponCode: this.coupon?.data.code || null,
            loyaltyProgram: this.loyaltyProgram,
            orderSessionId: store.generalStore.orderSessionId,
            clientPhoneNumber: this.loyaltyPhone,
        };

        let createOrderResponse = await OrdersService.createBookingOrder(
            {
                newBookingOrder:  order,
                organizationBranchId:  store.generalStore.organizationBranchId,
                organizationId: store.generalStore.organizationId,
            }
        );


        if (createOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }


        const orderId = createOrderResponse.orderId;
        if (!orderId) return {
            err: true,
            msg: ``
        };

        const getOrderResponse = await OrdersService.getBookingOrder(
            {
                orderId: orderId,
                organizationBranchId: store.generalStore.organizationBranchId,
                organizationId: store.generalStore.organizationId,
            }
        );

        if (getOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }


        if (!getOrderResponse.order) {
            return {
                err: true,
                msg: `Failed to get order by id ${createOrderResponse.orderId}`
            }
        }

        runInAction(() => {
            this.order = getOrderResponse.order;
            this.clientId = createOrderResponse.clientId;
            this.resetChosenBookingSlots();
        });


        return {
            err: false,
            order: getOrderResponse.order
        }
    }

    @action
    reset() {
        this.order = null;
        this.comment = '';
        this.coupon = null;
        this.couponCode = '';
    }



    async setCouponCode(code: string){

        runInAction(() => {
            this.couponCode = code;
        })
        if(this.timer){
            clearTimeout(this.timer);
            this.timer = undefined;
        }

        if(code.length === 0){
            this.setFailedCouponCheck(false);
            this.setIsCouponLoading(false);
            return;
        }

        const now = Date.now();
        this.setIsCouponLoading(true);

        const generalStore = rootStore.generalStore;
        const couponRequest: CouponsGetCouponPublicRequest = {
            couponCode: code,
            menuId: generalStore.menuId,
            organizationBranchId: generalStore.organizationBranchId,
            organizationId: generalStore.organizationId
        };
        const checkCouponResponse = await OrdersService.checkCoupon(couponRequest);

        if(Date.now() - now < 1000){
            this.timer = setTimeout(() => {
                this.setFailedCouponCheck(checkCouponResponse.executionStatus === ExecutionStatus.Failed)
                this.setCoupon(checkCouponResponse.coupon);
                this.setIsCouponLoading(false);
            }, 1000)
        }else{
            this.setFailedCouponCheck(checkCouponResponse.executionStatus === ExecutionStatus.Failed)
            this.setCoupon(checkCouponResponse.coupon);
            this.setIsCouponLoading(false);
        }
    }

    @action
    setFailedCouponCheck(v: boolean) {
        this.failedCouponCheck = v;
    }
    @action
    setIsCouponLoading(v: boolean) {
        this.isCouponCodeChecking = v;
    }

    @action
    setCoupon(coupon: Coupon | null){
        this.coupon = coupon;
    }

    @action
    resetCouponCode(){
        this.couponCode = ""
    }

    async getTimeSlots(date: Moment, itemId: number) {
        runInAction(() => {
            this.bookingTimeSlotsLoading = true;
        })
        const data = {
            bookingMenuItemId: itemId,
            date: date.toDate(),
            organizationBranchId: rootStore.generalStore.organizationBranchId,
            menuId: rootStore.generalStore.menuId
        };
        const res = await OrdersService.getMenuItemBookingByDate(data);
        runInAction(() => {
            this.dateForAvailableSlots = date;
            this.booking = res.booking;
            this.bookingTimeSlotsLoading = false;
        });
    }



    async reloadOrder() {
        if (!this.order) return;
        const store = rootStore;

        const getOrderResponse = await OrdersService.getBookingOrder(
            {
                orderId: this.order.id,
                organizationId: store.generalStore.organizationId,
                organizationBranchId: store.generalStore.organizationBranchId,
            }
        );

        if (getOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }

        if (!getOrderResponse.order) {
            return {
                err: true,
                msg: `Failed to get order by id ${this.order.id}`
            }
        }
        runInAction(() => {
            this.order = getOrderResponse.order;
        });
    }

    static fromJSON(obj: any) {
        const checkout = new BookingCheckout();
        checkout.setData(obj);
        return checkout;
    }

    @action
    setLoyaltyProgram(loyaltyProgram: LoyaltyProgram) {
        this.loyaltyProgram = loyaltyProgram;
    }

    @action
    setPaymentDetails(paymentDetails: NewOrderPaymentDetails) {
        this.paymentDetails = paymentDetails;
    }

    @action
    setComment(comment: string) {
        this.comment = comment;
    }

    @computed
    validateInvoiceToCompanyDetails() {
        if (this.paymentDetails === null) {
            return false;
        }

        if (this.paymentDetails.type !== PaymentProviderType.companyInvoice) return true;
        if (this.paymentDetails.recipientCompanyCard.contractId.length === 0) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.shortTitle.length < 10) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.legalAddress.length < 10) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.type === RecipientType.russianOOOCard) {
            if (this.paymentDetails.recipientCompanyCard.inn.length !== 10) {
                return false;
            }

            if (this.paymentDetails.recipientCompanyCard.kpp.length !== 9) {
                return false;
            }
        } else if (this.paymentDetails.recipientCompanyCard.type === RecipientType.russianIPCard) {
            if (this.paymentDetails.recipientCompanyCard.inn.length !== 12) {
                return false;
            }

            if (this.paymentDetails.recipientCompanyCard.ogrnip.length !== 15) {
                return false;
            }
        }

        return true;
    }

    @computed
    validatePaymentDetails() {
        if (this.paymentDetails === null) return false;

        if ([PaymentProviderType.offline,
            PaymentProviderType.toBankCard,
            PaymentProviderType.cloudpayments,
            PaymentProviderType.sberbank]
            .indexOf(this.paymentDetails.type) >= 0) {
            return true
        } else {
            return this.validateInvoiceToCompanyDetails();
        }
    }

    @action
    openBonusCardModal (onOverlayClick: () => void) {
        this.isBonusCardModalOpen = true;
        rootStore.generalMenuStore.isOverlayOpen = true;
        rootStore.generalMenuStore.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeBonusCardModal() {
        this.isBonusCardModalOpen = false;
        rootStore.generalMenuStore.isOverlayOpen = false;
        rootStore.generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }
}

export default BookingCheckout
