import style from "./style.module.sass";
import {BookingOrder, Order, OrderStatus} from "../../../../services/order/models";
import acceptedOrder from "../../../../assets/accepted-order.svg";
import newOrder from "../../../../assets/new-order.svg";
import cancelledOrder from "../../../../assets/cancelled-order.svg";
import readyOrder from "../../../../assets/ready-order.svg";
import givenOutOrder from "../../../../assets/given-out-order.svg";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useStores} from "../../../../hooks";
import {observer} from "mobx-react";
import {Blinking} from "../../../../components/typography";
import {MenuType} from "../../../../services/menu/models";


interface Props {
    order: Order | BookingOrder
}

const OrderStatusBlock = (props: Props) => {
    const { ordersStore } = useStores();
    useEffect(() => {
        const timer = setInterval(() => {
            ordersStore.reloadCurrentOrder(MenuType.booking);
        }, 20000)
        return () => {
            clearInterval(timer);
        }
    }, []);

    const currentOrder = props.order;
    const { t } = useTranslation(['viewOrder', "general"])

    return <div className={style.paperBlock}>
        {(currentOrder.status !== OrderStatus.new && currentOrder.status !== OrderStatus.canceled &&
                currentOrder.status !== OrderStatus.canceledWithRefund &&
                currentOrder.status !== OrderStatus.preorder &&
                currentOrder.status !== OrderStatus.ready &&
                currentOrder.status !== OrderStatus.givenOutToDelivery &&
                currentOrder.status !== OrderStatus.givenOut
        ) &&
        <div className={style.orderStatus}>
            <img src={acceptedOrder} alt='' className={style.statusIcon}/>
            <p className={style.text}> {t("viewOrder:orderAccepted")}</p>
            <p className={style.orderCreatedText}
               style={{ marginTop: '-5px' }}>{t("viewOrder:restaurantIsCooking")}</p>
        </div>
        }
        {(currentOrder.status === OrderStatus.givenOut || currentOrder.status === OrderStatus.givenOutToDelivery) &&
        <div className={style.orderStatus}>
            <img src={givenOutOrder} alt='' className={style.statusIcon}/>
            <p className={style.text}>{t("viewOrder:orderGivenOut")}</p>
            <p className={style.orderCreatedText}
               style={{ marginTop: '-5px' }}>{t("viewOrder:restaurantGivenOut")}</p>
        </div>
        }
        {currentOrder.status === OrderStatus.ready &&
        <div className={style.orderStatus}>
            <img src={readyOrder} alt='' className={style.statusIcon}/>
            <p className={style.text}>{t("viewOrder:orderReady")}</p>
            <p className={style.orderCreatedText}
               style={{ marginTop: '-5px' }}>{t("viewOrder:restaurantIsReady")}</p>
        </div>
        }
        {currentOrder.status === OrderStatus.new &&
        <div className={style.orderStatus}>
            <Blinking>
                <img src={newOrder} alt='' className={style.statusIcon}/>
            </Blinking>
            <p className={style.text}>{t("viewOrder:orderCreated")}</p>

            <p className={style.orderCreatedText}
               style={{ marginTop: '-5px' }}>{t("viewOrder:restaurantWillAccepted")}</p>
        </div>
        }

        {(currentOrder.status === OrderStatus.canceled || currentOrder.status === OrderStatus.canceledWithRefund) &&
        <div className={style.orderStatus}>
            <img src={cancelledOrder} alt='' className={style.statusIcon}/>
            <p className={style.text}>{t("viewOrder:orderCancelled")}</p>
            <p className={style.orderCreatedText}
               style={{ marginTop: '-5px' }}>{t("viewOrder:restaurantCancelledOrder")}</p>
        </div>
        }
    </div>
};


export default observer(OrderStatusBlock);
