import { observer } from "mobx-react";
import * as React from "react";
import styles from "./style.module.sass"
import { MenuItem, MenuItemModifier } from "../../services/menu/models";
import { useStores } from "../../hooks";
import { MinusButton, PlusButton } from "../buttons/plusButton";


interface IProps {
    count: number,
    add: () => void;
    remove: () => void;
}

const PlusMinusWidgetModifiers = (props: IProps) => {

    let { count, add, remove } = props;

    const counterClasses = [styles.counter ,styles.small];

    return <div className={styles.plusMinusWidget}>
        {
            count > 0 && <>
                <MinusButton
                    size='small'
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        remove();
                    }}/>
                <span className={counterClasses.join(" ")}>{count}</span>
            </>
        }
        <PlusButton size='small'
                    onClick={(e: React.MouseEvent) => {
                        add();
                        e.stopPropagation();
                    }}/>
    </div>
};


export default observer(PlusMinusWidgetModifiers)
