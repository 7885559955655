import { PaymentProviderType } from "../order/models";


export interface CloudPaymentsCheckoutDetails{
    branchName: string;
    publicId: string;
    type: PaymentProviderType.cloudpayments
}

export interface SberbankCheckoutDetails{
    branchName: string;
    token: string;
    type: PaymentProviderType.sberbank
}

export type PaymentProviderDetails = {
    [PaymentProviderType.cloudpayments]: CloudPaymentsCheckoutDetails | null,
    [PaymentProviderType.sberbank]: SberbankCheckoutDetails | null,
};

export interface NewEvent {
    clientSessionId: string | null;
    menuId: number | null;
    metainfo: string | null;
    name: NewEventName;
    orderId: number | null;
    orderSessionId: string | null;
    organizationBranchId: number | null;
    organizationId: number | null;
    type: EventType;
}



export enum NewEventName {
    menuVisit = 'MenuVisit',
    cart = 'Cart',
    orderPaid = 'OrderPaid',
    checkout = 'Checkout',
    orderCreated = 'orderCreated',
    addToCart = 'addToCart', // Only front for vk, not supported on backend
}
export enum EventType {
    conversion = 'conversion',
    numeric = 'numeric'
}




export interface LocalizedString {
    [s: string]: string | undefined
}


export type ICompanyCard = IRussianOOOCard | IRussianIPCard;

export enum CompanyCardTypes {
    RussianOOOCard = "RussianOOOCard",
    RussianIPCard = "RussianIPCard"
}

export  interface IRussianOOOCard {
    type: CompanyCardTypes.RussianOOOCard;
    shortTitle: LocalizedString;
    fullTitle: LocalizedString;
    inn: string;
    kpp: string;
    legalAddress: LocalizedString;
    ogrn: string;
    phoneNumber: string;
}

export interface IRussianIPCard {
    type: CompanyCardTypes.RussianIPCard;
    fullTitle: LocalizedString;
    inn: string;
    legalAddress: LocalizedString;
    ogrnip: string;
    phoneNumber: string;
    shortTitle: LocalizedString;
}
