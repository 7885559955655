import style from "./style.module.sass"
import { useStores } from "../../../../hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const Header = () => {
    const {t, i18n} = useTranslation(["general", "menu"]);

    const {catalogStore} = useStores();
    return <div className={style.header}>
        <div className={style.backgroundImage}>
            <img src={catalogStore.aliasBinding.data.photosUrls[0]} alt=""/>
        </div>
        <div className={style.nameAndDescription}>
            <p className={style.title}>{catalogStore.aliasBinding.data.title[i18n.language]}</p>
            <p className={style.description}
               dangerouslySetInnerHTML={{__html: catalogStore.aliasBinding.data.description[i18n.language]}}/>
        </div>

{/*
        <img src={infoIcon} alt=""/>
*/}
    </div>
};

export default observer(Header);
