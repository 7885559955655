import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function useQuery<T = {}>() {
    const location = useLocation<T>();
    const t = new URLSearchParams(location.search.slice(1));
    let newRes = {};
    //@ts-ignore
    for(let [key, value] of t){
        //@ts-ignore
        newRes[key] = value;
    }
    return newRes as T;
}

export default useQuery;
