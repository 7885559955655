import style from "./style.module.sass";
import React, {PropsWithChildren, ReactNode} from "react";

interface Props {
    children: ReactNode;
}

const SmallGreyText: React.FC<Props> = (props) => {

    return <p className={style.smallGreyText}>{props.children}</p>
}

export default SmallGreyText;