import { action, computed, makeAutoObservable, runInAction } from "mobx"
import { rootStore } from "./index";
import {
    AddressSuggestion,
    Coupon,
    LoyaltyProgram,
    NewOrder,
    NewOrderItem,
    NewOrderPaymentDetails,
    OperationType,
    Order,
    PaymentProviderType,
    RecipientType
} from "../services/order/models";
import OrdersService from "../services/order";
import { ExecutionStatus } from "../apiCommandsExecutor/api";
import {
    Coordinates, DeliveryConditionType,
    DeliveryDetails,
    DeliveryType, PriceInCurrency
} from "../services/menu/models";
import {
    CouponsGetCouponPublicRequest,
    GeoCodingGetAddressSuggestionsPublicRequest
} from "../services/order/requestsResponses";






class Checkout {
    order: Order | null = null;
    deliveryDetails: DeliveryDetails | null = null;
    paymentDetails: NewOrderPaymentDetails | null = null;
    deliveryType: DeliveryType = DeliveryType.toTable;
    paymentType: PaymentProviderType = PaymentProviderType.offline;
    loyaltyType: OperationType = "Withdraw";
    loyaltyPhone:string = "";
    isReadyToSubmit: boolean = false;
    comment: string = "";
    clientId: number | null = null;
    coupon: Coupon | null = null;
    failedCouponCheck: boolean = false;
    couponCode: string = "";
    isCouponCodeChecking: boolean = false;
    timer: any;
    addressQuery: string = "";
    isAddressQueryChecking: boolean = false;
    failedAddressCheck: boolean = false;
    addressSuggestions: AddressSuggestion[] = [] ;
    isBonusCardModalOpen: boolean = false;
    loyaltyProgram: LoyaltyProgram | null = null;
    validateTakeAwayToTime: boolean = true;
    zoneFixedPrice: PriceInCurrency = {
                                            currencyId: "RUB",
                                            price: 0
                                        };
    zoneFreeDeliveryMinimalOrderPrice: PriceInCurrency = {
                                                            currencyId: "RUB",
                                                            price: 0
                                                        };
    isZoneEnabledForDelivery: boolean = true;


    constructor() {
        makeAutoObservable(this);
        this.init();
    }

    async getDeliveryPriceByCoordinates (coordinates: Coordinates) {
        const res = await OrdersService.getDeliveryPriceByCoordinates({
            menuId: rootStore.generalStore.menuId,
            coordinates: coordinates
        });

        if (res.executionStatus === ExecutionStatus.Finished) {
            runInAction(() => {
                this.isZoneEnabledForDelivery = true;
                this.zoneFixedPrice = res.fixedDeliveryPrice;
                this.zoneFreeDeliveryMinimalOrderPrice = res.freeDeliveryMinimalOrderPrice;
            })
        } else if (res.executionStatus === ExecutionStatus.Failed && res.errorMessages[0] === "Point not exist in polygon") {
            runInAction(() => {
               this.isZoneEnabledForDelivery = false;
            })}

    }

    @action
    async init() {

    }

    isCouponValid(coupon: Coupon | null){
        if(coupon && new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now()){
            return true
        }else{
            return false
        }
    }

    @action
    setIsReadyToSubmit(value: boolean) {
        this.isReadyToSubmit = value;
    }


    @action
    setData(obj: any) {
        this.deliveryDetails = obj.deliveryDetails;
        this.deliveryType = obj.deliveryType;
        this.comment = obj.comment || "";
    }

    @action
    setPaymentType(type: PaymentProviderType) {
        this.paymentType = type;
    }

    @action
    setDeliveryType(type: DeliveryType) {
        this.deliveryType = type;
    }

    @action
    setDeliveryDetailsType(type: DeliveryType) {
        if (this.deliveryDetails) this.deliveryDetails.type = type;
    }

    @action
    setLoyaltyType(type: OperationType) {
        this.loyaltyType = type;
    }
    @action
    setLoyaltyPhone(phone: string) {
        this.loyaltyPhone = phone;
    }


    async createOrder(): Promise<{ err: false, order: Order } | { err: true, msg: string }> {
        const store = rootStore;
        if (!this.deliveryDetails) {
            return {
                err: true, msg: "Не указаны детали доставки"
            }
        }
        if(!this.paymentDetails) {
            return {
                err: true, msg: "Нет деталей оплаты"
            }
        }

        let items: NewOrderItem[] = [];
        store.cartStore.list.forEach(itemsByMenuId => {
            itemsByMenuId.items.forEach(item => {
                items.push({
                    chosenMenuItemModifierSets: item.chosenMenuItemModifierSets,
                    comment: null,
                    menuItemId: item.menuItem.id,
                    menuItemsCount: item.count
                })
            })
        });


        const order: NewOrder = {
            clientSessionId: store.generalStore.clientSessionId,
            items,
            menuId: store.generalStore.menuId,
            deliveryDetails: this.deliveryDetails,
            clientLocaleId: store.generalStore.locale.id,
            paymentDetails: this.paymentDetails,
            comment: this.comment,
            tags: [],
            couponCode: this.coupon?.data.code || null,
            loyaltyProgram: this.loyaltyProgram,
            orderSessionId: store.generalStore.orderSessionId
        };

        let createOrderResponse = await OrdersService.createNewOrder(
            order,
            store.generalStore.organizationId,
            store.generalStore.organizationBranchId,
        );


        if (createOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }


        const orderId = createOrderResponse.orderId;
        if (!orderId) return {
            err: true,
            msg: ``
        };

        const getOrderResponse = await OrdersService.getOrderById(
            orderId,
            store.generalStore.organizationId,
            store.generalStore.organizationBranchId,
        );

        if (getOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }


        if (!getOrderResponse.order) {
            return {
                err: true,
                msg: `Failed to get order by id ${createOrderResponse.orderId}`
            }
        }

        runInAction(() => {
            this.order = getOrderResponse.order;
            this.clientId = createOrderResponse.clientId;
        });

        this.saveOrderDataForFutureUsage(order);

        return {
            err: false,
            order: getOrderResponse.order
        }
    }

    @action
    reset() {
        this.order = null;
        this.deliveryDetails = null;
        this.deliveryType = DeliveryType.toTable;
        this.comment = '';
        this.coupon = null;
        this.couponCode = '';
    }



    saveOrderDataForFutureUsage(order: NewOrder){
        if(order.deliveryDetails.type === DeliveryType.toAddress){
            rootStore.generalStore.saveAddress(order.deliveryDetails.address);
            rootStore.generalStore.savePhone(order.deliveryDetails.phoneNumber);

        }else if(order.deliveryDetails.type === DeliveryType.toAddressByYandexDelivery){
            rootStore.generalStore.saveAddress(order.deliveryDetails.address);
            rootStore.generalStore.savePhone(order.deliveryDetails.phoneNumber);
        }else if(order.deliveryDetails.type === DeliveryType.takeaway ||
            order.deliveryDetails.type === DeliveryType.takeawayToTime){
            rootStore.generalStore.savePhone(order.deliveryDetails.phoneNumber);
        }
    }

    async setCouponCode(code: string){

        runInAction(() => {
            this.couponCode = code;
        })
        if(this.timer){
            clearTimeout(this.timer);
            this.timer = undefined;
        }

        if(code.length === 0){
            this.setFailedCouponCheck(false);
            this.setIsCouponLoading(false);
            return;
        }

        const now = Date.now();
        this.setIsCouponLoading(true);

        const generalStore = rootStore.generalStore;
        const couponRequest: CouponsGetCouponPublicRequest = {
            couponCode: code,
            menuId: generalStore.menuId,
            organizationBranchId: generalStore.organizationBranchId,
            organizationId: generalStore.organizationId
        };
        const checkCouponResponse = await OrdersService.checkCoupon(couponRequest);

        if(Date.now() - now < 1000){
            this.timer = setTimeout(() => {
                this.setFailedCouponCheck(checkCouponResponse.executionStatus === ExecutionStatus.Failed)
                this.setCoupon(checkCouponResponse.coupon);
                this.setIsCouponLoading(false);
            }, 1000)
        }else{
            this.setFailedCouponCheck(checkCouponResponse.executionStatus === ExecutionStatus.Failed)
            this.setCoupon(checkCouponResponse.coupon);
            this.setIsCouponLoading(false);
        }
    }

    @action
    setFailedCouponCheck(v: boolean) {
        this.failedCouponCheck = v;
    }
    @action
    setIsCouponLoading(v: boolean) {
        this.isCouponCodeChecking = v;
    }

    @action
    setCoupon(coupon: Coupon | null){
        this.coupon = coupon;
    }

    @action
    resetCouponCode(){
        this.couponCode = ""
    }

    async setDeliveryAddress(address: string) {

        runInAction(() => {
            this.addressQuery = address;
        })
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }

        if (address.length === 0) {
            this.setFailedAddressCheck(false);
            this.setIsAddressQueryChecking(false);
            return;
        }

        const now = Date.now();
        this.setIsAddressQueryChecking(true);

        const generalStore = rootStore.generalStore;
        const getSuggestionsRequest: GeoCodingGetAddressSuggestionsPublicRequest = {
            query: address,
            organizationBranchId: generalStore.organizationBranchId,
            organizationId: generalStore.organizationId,
            menuId:generalStore.menuId
        };
        const getSuggestionsResponse = await OrdersService.getAddressSuggestions(getSuggestionsRequest);

        if (Date.now() - now < 1000) {
            this.timer = setTimeout(() => {
                this.setFailedAddressCheck(getSuggestionsResponse.suggestions.length===0)
                this.setAddressSuggestions(getSuggestionsResponse.suggestions);
                this.setIsAddressQueryChecking(false);
            }, 1000)
        } else {
            this.setFailedAddressCheck(getSuggestionsResponse.suggestions.length === 0)
            this.setAddressSuggestions(getSuggestionsResponse.suggestions);
            this.setIsAddressQueryChecking(false);
        }
    }

    @action
    setIsAddressQueryChecking(v: boolean) {
        this.isAddressQueryChecking = v;
    }

    @action
    setFailedAddressCheck(v: boolean) {
        this.failedAddressCheck = v;
    }

    @action
    setAddressSuggestions(addressSuggestions: AddressSuggestion[]) {
        this.addressSuggestions = addressSuggestions;
    }


    async reloadOrder() {
        if (!this.order) return;
        const store = rootStore;

        const getOrderResponse = await OrdersService.getOrderById(
            this.order.id,
            store.generalStore.organizationId,
            store.generalStore.organizationBranchId,
        );

        if (getOrderResponse.executionStatus !== ExecutionStatus.Finished) {
            return {
                err: true,
                msg: `Failed to create order`
            }
        }

        if (!getOrderResponse.order) {
            return {
                err: true,
                msg: `Failed to get order by id ${this.order.id}`
            }
        }
        runInAction(() => {
            this.order = getOrderResponse.order;
        });
    }

    static fromJSON(obj: any) {
        const checkout = new Checkout();
        checkout.setData(obj);
        return checkout;
    }

    @action
    setDeliveryDetails(deliveryDetails: DeliveryDetails) {
        this.deliveryDetails = deliveryDetails;
    }
    @action
    setLoyaltyProgram(loyaltyProgram: LoyaltyProgram) {
        this.loyaltyProgram = loyaltyProgram;
    }

    @action
    setPaymentDetails(paymentDetails: NewOrderPaymentDetails) {
        this.paymentDetails = paymentDetails;
    }

    @action
    setComment(comment: string) {
        this.comment = comment;
    }


    @computed
    validateInvoiceToCompanyDetails() {
        if (this.paymentDetails === null) {
            return false;
        }

        if (this.paymentDetails.type !== PaymentProviderType.companyInvoice) return true;
        if (this.paymentDetails.recipientCompanyCard.contractId.length === 0) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.shortTitle.length < 10) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.legalAddress.length < 10) {
            return false;
        }

        if (this.paymentDetails.recipientCompanyCard.type === RecipientType.russianOOOCard) {
            if (this.paymentDetails.recipientCompanyCard.inn.length !== 10) {
                return false;
            }

            if (this.paymentDetails.recipientCompanyCard.kpp.length !== 9) {
                return false;
            }
        } else if (this.paymentDetails.recipientCompanyCard.type === RecipientType.russianIPCard) {
            if (this.paymentDetails.recipientCompanyCard.inn.length !== 12) {
                return false;
            }

            if (this.paymentDetails.recipientCompanyCard.ogrnip.length !== 15) {
                return false;
            }
        }

        return true;
    }

    @computed
    validatePaymentDetails() {
        if (this.paymentDetails === null) return false;

        if ([PaymentProviderType.offline,
            PaymentProviderType.toBankCard,
            PaymentProviderType.cloudpayments,
            PaymentProviderType.sberbank]
            .indexOf(this.paymentDetails.type) >= 0) {
            return true
        } else {
            return this.validateInvoiceToCompanyDetails();
        }
    }

    @computed
    validateDeliveryTakeAwayToTimeDetails(v: boolean) {
        this.validateTakeAwayToTime = v;
    }

    @computed
    validateDeliveryDetails() {
        if (this.deliveryDetails?.type === DeliveryType.toTable) {
            const table = rootStore.menuStore.getTableById(this.deliveryDetails.tableId);
            let hasTableBeenChosen = false;
            let hasSeats = false;
            if (table) {
                hasTableBeenChosen = true;
                hasSeats = table.data.seats.length > 0;
            }
            const hasToChooseSeat = rootStore.menuStore.menu.data.deliverySettings[DeliveryType.toTable].canChooseSeat && hasSeats;
            return (hasToChooseSeat
                && this.deliveryDetails.seatId !== null
                || !hasToChooseSeat)
                && hasTableBeenChosen && !!table && table.data.publicId !== 0;
        } else if (this.deliveryDetails?.type === DeliveryType.toAddress){

            const dom8OrgBranch = 699

            if (rootStore.menuStore.menu.organizationBranchId == dom8OrgBranch) {
                const dom8DataFilled = (this.deliveryDetails.name?.length || 0) > 0 && (this.deliveryDetails.porch?.length || 0) > 0 && this.deliveryDetails.flat != null && this.deliveryDetails.floor != null

                if (rootStore.menuStore.menu.data.deliverySettings[DeliveryType.toAddress]?.deliveryConditions.type === DeliveryConditionType.zonePrice) {
                    return this.deliveryDetails.phoneNumber.length > 0 && this.deliveryDetails.address.length > 5 && this.isZoneEnabledForDelivery && dom8DataFilled;
                } else return this.deliveryDetails.phoneNumber.length > 0 && this.deliveryDetails.address.length > 5 && dom8DataFilled;
            } else {
                if (rootStore.menuStore.menu.data.deliverySettings[DeliveryType.toAddress]?.deliveryConditions.type === DeliveryConditionType.zonePrice) {
                    return this.deliveryDetails.phoneNumber.length > 0 && this.deliveryDetails.address.length > 5 && this.isZoneEnabledForDelivery;
                } else return this.deliveryDetails.phoneNumber.length > 0 && this.deliveryDetails.address.length > 5;
            }
        }else if(this.deliveryDetails?.type === DeliveryType.takeaway ){
            return this.deliveryDetails.phoneNumber.length > 9
        }else if(this.deliveryDetails?.type === DeliveryType.takeawayToTime){
            return (this.deliveryDetails.phoneNumber.length > 9 && this.validateTakeAwayToTime)
        } else if(this.deliveryDetails?.type === DeliveryType.toVehicle){
            const phoneCorrect = this.deliveryDetails.phoneNumber.length > 9;
            const hasPlateNumber = this.deliveryDetails.vehiclePlateNumber.length >= 3;
            return phoneCorrect && hasPlateNumber;
        }else if(this.deliveryDetails?.type === DeliveryType.insideTheRestaurant){
            return true;
        }
        return false;
    }

    @action
    openBonusCardModal (onOverlayClick: () => void) {
        this.isBonusCardModalOpen = true;
        rootStore.generalMenuStore.isOverlayOpen = true;
        rootStore.generalMenuStore.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeBonusCardModal() {
        this.isBonusCardModalOpen = false;
        rootStore.generalMenuStore.isOverlayOpen = false;
        rootStore.generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }
}

export default Checkout
