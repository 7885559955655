import styles from "./style.module.sass"
import { numberWithSpaces, toRubbles } from "../libs";
import {MenuType, PriceInCurrency} from "../../services/menu/models";
import { useTranslation } from "react-i18next";
import {useStores} from "../../hooks";
import * as React from "react";

interface IProps {
    item: PriceInCurrency,
    priceText?: string,
    from?: boolean,
    crossed?: boolean
}

export const PriceComponent = ({item, priceText, from, crossed}: IProps ) => {
    const {t} = useTranslation(['general']);
    const {generalMenuStore} = useStores();
    const currentMenu = generalMenuStore.menu;

    return <span className={styles.priceContainer} style={{ paddingBottom: crossed ? 0 : undefined }} >

        {(currentMenu && currentMenu.type === MenuType.booking) ?
            <>
                {from && <span>{t('general:from')} </span>}
                <span className={styles.price}>
                    {priceText || numberWithSpaces(toRubbles(item.price))}
                </span>
                <span className={styles.currency}>
                    {t('general:currency.rub')}
                </span>
            </> :
            <>
                <span className={crossed ? styles.crossedPrice : styles.price}>
                    {priceText || numberWithSpaces(toRubbles(item.price))}
                </span>
                <span className={crossed ? styles.crossedCurrency : styles.currency}>
                    {t('general:currency.rub')}
                </span>
            </>
        }
        { crossed && <span className={styles.crossedOverlay}/> }
    </span>
};

export default PriceComponent;
