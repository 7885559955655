import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import Header from "./components/header";
import CategoriesTab from "./components/categories";
import MenuItemsList from "./components/menuItems"
import ModifiersModal from "./components/modifiersModal"
import Overlay from "./components/overlay"
import ToCheckoutButton from "./components/toCheckoutButton";
import { useStores } from "../../hooks";
import useQuery from "../../hooks/useQuery";
import useAlias from "../../hooks/useAlias";
import OrderingNotAvailable from "../orderingNotAvailable";
import { useHistory } from "react-router";
import { MenuMode } from "../../store/general";
import RestifyFooter from "../../components/restifyFooter";
import CategoriesBurgerMenu from "./components/categories/components/categoriesBurgerMenu";
import useCategoryIdAndItemId from "../../hooks/useCategoryIdAndItemId";
import InfoDetails from "./components/header/components/InfoDetails";
import { dropLocalState } from "../../store/persistence";
import RestifyLoader from "../restifyLoader";

import NonWorkingHours from "./components/nonWorkingHours";

import { EventType, NewEventName } from "../../services/general/models";
import ChangeLangs from "./components/header/components/ChangeLangs";


let timeout: any;
const BookingMenuView = () => {

    const { generalStore, bookingMenuStore, isInited, generalMenuStore } = useStores();
    const [isFirstLunch, setIsFirstLunch] = useState(true);



    const history = useHistory();
    const params = useQuery<{
        menuId: string,
        orgId: string,
        branchId: string,
        tableId?: string,
        pointId?: string,
        viewOnly?: string,
        isWidget?: string
    }>();


    const [alias, isAliasReady] = useAlias();

    const routerParams = useCategoryIdAndItemId();

    useEffect(() => {
        (async () => {
            if (!bookingMenuStore.isLoaded) return;
            const params = {
                metainfo: null,
                name: NewEventName.menuVisit,
                type: EventType.conversion
            }
            await generalStore.addNewEvent(params);
        })()
    }, [bookingMenuStore.isLoaded, bookingMenuStore])

    useEffect(() => {

        if (!bookingMenuStore.isLoaded) return;
        if (isFirstLunch) {
            setTimeout(() => {
                if (routerParams.categoryId) {
                    bookingMenuStore.setActiveCategory(parseInt(routerParams.categoryId), true);
                }
                setIsFirstLunch(false);
            }, 600)
            setTimeout(() => {
                if (routerParams.itemId && routerParams.categoryId) {
                    const item = bookingMenuStore.bookingItems[routerParams.categoryId].find((t) => t.id.toString() === routerParams.itemId);
                    item && bookingMenuStore.openModifiersPopup(item, () => {
                        bookingMenuStore.closeModifiersPopup();
                        const t = history.location.pathname.split("/");
                        const baseUrl = `/${t[0]}${history.location.search}`
                        history.replace(baseUrl);
                    });
                }
                setIsFirstLunch(false)
            }, 800);
        } else {
            if (routerParams.itemId && routerParams.categoryId) {
                const item = bookingMenuStore.bookingItems[routerParams.categoryId].find((t) => t.id.toString() === routerParams.itemId);
                item && bookingMenuStore.openModifiersPopup(item, () => {
                    bookingMenuStore.closeModifiersPopup();
                    history.goBack();
                });
            }
        }

        if (!routerParams.itemId) {
            bookingMenuStore.closeModifiersPopup();
        }
    }, [bookingMenuStore.isLoaded, routerParams.itemId, routerParams.categoryId])


    useEffect(() => {
        let options = {
            organizationId: parseInt(params.orgId),
            organizationBranchId: parseInt(params.branchId),
            menuId: parseInt(params.menuId),
            tableId: params.tableId || "",
            pointId: params.pointId || "",
            viewOnly: !!params.viewOnly,
            isWidget: !!params.isWidget
        };

        if (!bookingMenuStore.isLoaded && isInited && isAliasReady) {
            if (alias && alias.data.aliasedMenus.length === 1) {
                const aliasedMenu = alias.data.aliasedMenus[0];
                options.menuId= aliasedMenu.menuId;
                options.organizationId = aliasedMenu.organizationId;
                options.organizationBranchId = aliasedMenu.organizationBranchId;
                generalStore.setAlias(alias.alias);
            } else if (alias && alias.data.aliasedMenus.length > 1) {
                history.replace(`/catalog/${alias.alias}?tableId=${options.tableId}`);
                return;
            } else {
                generalStore.setAlias(null);
            }

            if (
                (isNaN(options.menuId) ||
                    isNaN(options.organizationBranchId) ||
                    isNaN(options.organizationId)) && isInited
            ) {
                if (!isNaN(generalStore.menuId) &&
                    generalStore.menuId > 0 &&
                    !isNaN(generalStore.organizationId) &&
                    !isNaN(generalStore.organizationBranchId)) {
                    history.replace(`/?menuId=${generalStore.menuId}&branchId=${generalStore.organizationBranchId}&orgId=${generalStore.organizationId}`)
                    return;
                } else {
                    history.replace('/enter-alias');
                    bookingMenuStore.setError("Не найдено меню");
                    return;
                }
            }

            generalStore.init(options).then(() => {
                return bookingMenuStore.init({
                    organizationId: options.organizationId,
                    organizationBranchId: options.organizationBranchId,
                    bookingMenuId: options.menuId,
                });
            }).then(() => {
                generalMenuStore.setMenu(bookingMenuStore.bookingMenu);
                if(alias && alias.data.aliasedMenus.length === 1){
                    const name = bookingMenuStore.bookingMenu.data.name['ru'];
                    const photo = bookingMenuStore.bookingMenu.data.photos.logoPhotoUrl;
                    bookingMenuStore.updateManifest(alias.alias, name, photo);
                }
            })
        }

        if ((options.menuId !== generalStore.menuId || options.organizationId !== generalStore.organizationId) && !alias) {
            bookingMenuStore.setIsLoaded(false)
        }
    }, [params, bookingMenuStore, generalStore, isAliasReady, isInited, bookingMenuStore.isLoaded]);



    if (!bookingMenuStore.isLoaded || !isInited) {
        if (!timeout) {
            timeout = setTimeout(() => {
                // drop state
                dropLocalState();
                window.location.reload();
            }, 10000);

        }
    }


    if (!generalStore.isMenuAvailable) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = undefined;
        }
        return <OrderingNotAvailable/>
    }

    if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
    }


    return <div>
        {
            bookingMenuStore.isLoaded && isInited && <>
				<NonWorkingHours/>
				<Header fromCatalog={generalStore.modeData.mode === MenuMode.catalog}/>
				<InfoDetails/>
                <ChangeLangs/>
				<CategoriesTab/>
				<MenuItemsList/>
				<ModifiersModal/>
				<Overlay/>
				<ToCheckoutButton/>
				<CategoriesBurgerMenu/>
				<RestifyFooter/>
			</>
        }
    </div>
};


export default observer(BookingMenuView);
