import style from "../style.module.sass"
import { useStores } from "../../../../../hooks";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import locationIcon from "../assets/location.svg";
import phoneIcon from "../assets/phone.svg";
import instagramIcon from "../assets/instagram.svg";
import websiteIcon from "../assets/website.svg";
import whatsappIcon from "../assets/whatsapp.svg";
import WorkingSchedule from "./WorkingSchedule";
import CloseButton from "../../../../../components/buttons/closeButton";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

const InfoDetails = () => {
    const { generalStore, bookingMenuStore, generalMenuStore } = useStores();
    const { i18n, t } = useTranslation(['general']);
    const openDetailes = generalMenuStore.isDetailsModalVisible;
    const [topOffset, setTopOffset] = useState(60);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null)
    const [topScroll, setTopScroll] = useState<number>(0);


    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            if(topScroll > 0) return
            generalMenuStore.onOverlayClick && generalMenuStore.onOverlayClick();
        },
        onSwiping: (eventData) => {
            if(topScroll > 0) return;
            if (eventData.deltaY < 0) {
                setTopOffset(0);
                setOpacity(1);
                setHorizontalOffset(0);
            } else {
                setTopOffset(eventData.deltaY);
                setOpacity(1 - eventData.deltaY / 400);
                setHorizontalOffset(eventData.deltaY / 50);
            }

        },
        preventScrollOnSwipe: true,
        delta: 40
    })


    useEffect(() => {
        setTimeout(() => {
            if(!containerRef.current) return;
            containerRef.current.onscroll =  (data) => {
                setTopScroll(containerRef.current?.scrollTop || 0);
            }
        }, 900)
    }, [openDetailes])


    useEffect(() => {
        if (!openDetailes) {
            setTopOffset(500);
            setOpacity(0);
            setHorizontalOffset(0);
        }else{
            setTopOffset(20);
            setOpacity(1);
            setHorizontalOffset(0);
        }
    }, [openDetailes])

    const classes = [style.infoDetailesSection];
    if (openDetailes) {
        classes.push(style.open)
    }

    if(!openDetailes){
        return null;
    }

    const closeInfoDetails = () => {
        generalMenuStore.isDetailsModalVisible = false;
        generalMenuStore.isOverlayOpen = false;
        generalMenuStore.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    };

    const legalInfo = generalStore.extractLegalInfo();
    const swippableHandlers = topScroll <= 0 ? handlers : {};

    return (
        <>
            <div className={classes.join(" ")}
                 {...swippableHandlers}
                 style={{
                     top: topOffset,
                     opacity,
                     left: horizontalOffset,
                     right: horizontalOffset,
                 }}
            >
                <div className={style.infoDetailsSectionContainer} ref={containerRef}>
                    {/*<div className={style.nameAndDescription}>
                        {menuStore.menu.data.photos.logoPhotoUrl !== null &&
                        <>
                            <div className={style.logo}>
                                <img src={menuStore.menu.data.photos.logoPhotoUrl} alt=''/>
                            </div>
                            <div className={style.menuName} style={{ paddingTop: '40px' }}>
                                <p className={style.text}>{menuStore.menu.data.name[i18n.language]}</p>
                            </div>
                        </>
                        }
                        {menuStore.menu.data.photos.logoPhotoUrl == null &&

                        <div className={style.menuName}>
                            <p className={style.text}>{menuStore.menu.data.name[i18n.language]}</p>
                        </div>
                        }
                        <label>
                            <input type='checkbox'/>
                            <p className={style.description}>{menuStore.menu.data.description[i18n.language]}</p>
                        </label>
                    </div>*/}

                    <div className={style.shadowContainer}>
                        <p className={style.sectionTitle}> {t("general:aboutCo")}</p>
                        <WorkingSchedule/>
                        {bookingMenuStore.bookingMenu.data.addressDetails.title !== null && bookingMenuStore.bookingMenu.data.addressDetails.title[i18n.language] &&
						<div className={style.address}>
							<div className={style.detailsIcon}>
								<img src={locationIcon} alt=""/>
							</div>
							<a >
								<p>{bookingMenuStore.bookingMenu.data.addressDetails.title[i18n.language]}</p>
							</a>

						</div>
                    }
                        {bookingMenuStore.bookingMenu.data.contactDetails.phoneNumber !== null &&
							<div className={style.phoneNumber}>
								<div className={style.detailsIcon}>
									<img src={phoneIcon} alt=""/>
								</div>
								<a href={'tel:' + bookingMenuStore.bookingMenu.data.contactDetails.phoneNumber}>
									<p>{bookingMenuStore.bookingMenu.data.contactDetails.phoneNumber}</p>
								</a>
							</div>
                        }
                        {bookingMenuStore.bookingMenu.data.contactDetails.instagram !== null &&
							<div className={style.instagram} style={{ marginTop: '20px' }}>
								<div className={style.detailsIcon}>
									<img src={instagramIcon} alt=""/>
								</div>
								<a href={'https://www.instagram.com/' + bookingMenuStore.bookingMenu.data.contactDetails.instagram}>
									<p>@{bookingMenuStore.bookingMenu.data.contactDetails.instagram}</p>
								</a>
							</div>
                        }
                        {bookingMenuStore.bookingMenu.data.contactDetails.websiteUrl !== null &&
							<div className={style.websiteIcon}>
								<div className={style.detailsIcon}>
									<img src={websiteIcon} alt=""/>
								</div>
								<a href={'https://' + bookingMenuStore.bookingMenu.data.contactDetails.websiteUrl}>
									<p>{bookingMenuStore.bookingMenu.data.contactDetails.websiteUrl}</p>
								</a>
							</div>
                        }
                        {bookingMenuStore.bookingMenu.data.contactDetails.whatsApp !== null &&
							<div className={style.websiteIcon}>
								<div className={style.detailsIcon}>
									<img src={whatsappIcon} alt=""/>
								</div>
								<a href={'https://wa.me/' + bookingMenuStore.bookingMenu.data.contactDetails.whatsApp}>
									<p>{bookingMenuStore.bookingMenu.data.contactDetails.whatsApp}</p>
								</a>
							</div>
                        }
                    </div>


                    {
                        legalInfo &&
						<div
							className={style.legalInfo}
						>
							<p className={style.sectionTitle}>
								Юридическая информация ресторана
							</p>
							<p className={style.infoItem}>{legalInfo?.title}</p>
							<p className={style.infoItem}>{t('general:infoDetails.ogrn')}: {legalInfo?.ogrn}</p>
							<p className={style.infoItem}>{t('general:infoDetails.inn')}: {legalInfo?.inn}</p>
							<p className={style.infoItem}>{t('general:infoDetails.legalAddress')}: {legalInfo?.legalAddress}</p>
                            <p className={style.infoItem}>
								<Trans i18nKey="general:infoDetails.oferta">
									Используя меню, <br></br> вы соглашаетесь с <a href={'https://restify.one/documents/oferta'}>
									офертой</a>
								</Trans>
							</p>
						</div>
                    }

                </div>



                <div style={{
                    padding: 10
                }}>
                    <CloseButton onClick={closeInfoDetails}/>
                </div>
            </div>

        </>
    )
}

export default observer(InfoDetails);
