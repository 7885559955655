import { action, makeAutoObservable, runInAction } from "mobx"
import { getPublicAliasBindingByAlias } from "../services/aliases";
import { Menu } from "../services/menu/models";
import { AliasBinding } from "../services/aliases/models";


class CatalogStore {
    alias: string | null = null;
    menus: {[s: string]: Menu} = {};
    aliasBinding: AliasBinding = {} as AliasBinding;
    isReady: boolean = false;
    constructor() {
        makeAutoObservable(this)
    }

    @action
    setAlias(alias: string){
        this.alias = alias;
    }


    async init(alias: string){
        const res = await getPublicAliasBindingByAlias({
            alias: alias
        });

        runInAction(() => {
            if(!res.aliasBinding) return;
            this.alias = alias;
            this.menus = Object.values(res.menusByOrgBranchIdByMenuId).reduce((memo, items) => {
                return {
                    ...memo,
                    ...items
                }
            })
            this.aliasBinding = res.aliasBinding;
            this.isReady = true;
        })
    }


    getMenuById(menuId: number) {
        return this.menus[menuId];
    }

}



export default CatalogStore
