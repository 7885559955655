import {observer} from "mobx-react";
import {useStores} from "hooks";
import {Trans, useTranslation} from "react-i18next";
import {CouponType} from "../../../../services/order/models";
import {DeliveryType, MenuType} from "../../../../services/menu/models";
import style from "../../style.module.sass"

const PriceWithCoupon = () => {

    const {cartStore, bookingCheckoutStore } = useStores();
    const {t} = useTranslation(["general", "cart", "coupon"]);

    const failedCouponCheck = bookingCheckoutStore.failedCouponCheck;
    const subtotal = cartStore.getTotal(MenuType.booking);


    const coupon = bookingCheckoutStore.coupon;
    const totalWithFixedDiscount = (coupon && coupon.data.type===CouponType.fixedAmount) ? 
    ((cartStore.getTotal(MenuType.booking) / 100) - coupon.data.discountAmount.amount/100).toFixed(0) :
    0;

    return (
        <>
        {(coupon && coupon.data.type === CouponType.percentage &&
                    new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now() &&
                    !failedCouponCheck) &&
                    <>
                        <div className={style.horizontalLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:totalWithoutDiscount")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.booking) * bookingCheckoutStore.chosenBookingSlots.length / 100).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemDiscountTitle}>
                                        <Trans i18nKey="cart:discountAmount" values={{ discountPercent: coupon.data.discountPercent}}/>
                                    </p>
                                </div>
                                <p className={style.itemDiscountPrice}>
                                    -{((cartStore.getTotal(MenuType.booking) * bookingCheckoutStore.chosenBookingSlots.length / 100) * coupon.data.discountPercent / 100).toFixed(0)}
                                    <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                        <div className={style.horizontalRedLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:toPay")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {((cartStore.getTotal(MenuType.booking) * bookingCheckoutStore.chosenBookingSlots.length / 100) -
                                        (cartStore.getTotal(MenuType.booking) * bookingCheckoutStore.chosenBookingSlots.length / 100) * coupon.data.discountPercent / 100).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                    </>
                }
                {(coupon && coupon.data.type === CouponType.fixedAmount &&
                    new Date(coupon.data.startDate).getTime() < Date.now() && new Date(coupon.data.endDate).getTime() > Date.now() &&
                    !failedCouponCheck) &&
                    <>
                        <div className={style.horizontalLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:totalWithoutDiscount")}
                                    </p>
                                </div>
                                <p className={style.itemPrice}>
                                    {(cartStore.getTotal(MenuType.booking) * bookingCheckoutStore.chosenBookingSlots.length / 100).toFixed(0)} <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemDiscountTitle}>
                                        {t("coupon:discount")}
                                    </p>
                                </div>
                                <p className={style.itemDiscountPrice}>
                                    -{coupon.data.discountAmount.amount/100}
                                    <span className={style.currency}>{t("general:currency.rub")}</span>
                                </p>
                            </div>
                        </div>
                        <div className={style.horizontalRedLine}>
                            <div className={style.item}>
                                <div>
                                    <p className={style.itemTitle}>
                                        {t("cart:toPay")}
                                    </p>
                                </div>
                                { (totalWithFixedDiscount>0) ?
                                    <p className={style.itemPrice}>
                                        {totalWithFixedDiscount}
                                        <span className={style.currency}>{t("general:currency.rub")}</span>
                                    </p>
                                    :
                                    <p className={style.itemPrice}>
                                        0
                                        <span className={style.currency}>{t("general:currency.rub")}</span>
                                    </p>
                                }
                            </div>
                        </div>
                    </>
                }
        </>
    )
}

export default observer(PriceWithCoupon);