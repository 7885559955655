import { execute } from "../../apiCommandsExecutor/api";
import { OrdersApiMethods } from "./methods";
import { NewOrder } from "./models";
import {
    BookingOrdersCancelBookingOrderPaymentRequest,
    BookingOrdersCancelBookingOrderPaymentResponse,
    BookingOrdersCreateBookingOrderPublicRequest,
    BookingOrdersCreateBookingOrderPublicResponse,
    BookingOrdersGetBookingOrderPublicRequest,
    BookingOrdersGetBookingOrderPublicResponse,
    BookingsDeleteBookingRequest,
    BookingsDeleteBookingResponse,
    BookingsGetBookingRequest,
    BookingsGetBookingResponse,
    BookingsGetBookingsRequest,
    BookingsGetBookingsResponse,
    BookingsGetPublicMenuItemBookingsRequest,
    BookingsGetPublicMenuItemBookingsResponse,
    CouponsGetCouponPublicRequest,
    CouponsGetCouponPublicResponse,
    GeoCodingGetAddressSuggestionsPublicRequest,
    GeoCodingGetAddressSuggestionsPublicResponse,
    ICreateOrderRequest,
    ICreateOrderResponse,
    IGetOrderRequest,
    IGetOrderResponse,
    IGetOrdersRequest,
    IGetOrdersResponse, OrdersDeliveryPriceFromZonePublicRequest, OrdersDeliveryPriceFromZonePublicResponse
} from "./requestsResponses";
import {Coordinates} from "../menu/models";


class OrdersService {
    static async createNewOrder(order: NewOrder, organizationId: number, organizationBranchId: number){
        const res = await execute<ICreateOrderRequest, ICreateOrderResponse>(
            OrdersApiMethods.createOrder,
            {
                newOrder: order,
                organizationBranchId,
                organizationId
            });
        return res.data;
    }


    static async getOrderById(orderId: number, organizationId: number, organizationBranchId: number){
        const res = await execute<IGetOrderRequest, IGetOrderResponse>(
            OrdersApiMethods.getOrder,
            {
                orderId, organizationBranchId, organizationId
            }
        );
        return res.data;
    }


    static async getOrders(tableId: string, organizationId: number, organizationBranchId: number){
        const res = await execute<IGetOrdersRequest, IGetOrdersResponse>(
            OrdersApiMethods.getOrdersByTableId,
            {
                organizationBranchId,
                organizationId,
                toRestaurantTableId: tableId
            }
        );
        return res.data;
    }

    static async checkCoupon( params:CouponsGetCouponPublicRequest){
        const res = await execute<CouponsGetCouponPublicRequest, CouponsGetCouponPublicResponse>(
            OrdersApiMethods.couponsGetCouponPublic,
            params
        );
        return res.data;
    }

    static async getAddressSuggestions(params: {organizationBranchId: number; organizationId: number; menuId: number; query: string;}){
        const res = await execute<GeoCodingGetAddressSuggestionsPublicRequest, GeoCodingGetAddressSuggestionsPublicResponse>(
            OrdersApiMethods.geoCodingGetAddressSuggestionsPublic,
            params);
        return res.data;
    }

    static async getDeliveryPriceByCoordinates(params: {menuId: number; coordinates: Coordinates}){
        const res = await execute<OrdersDeliveryPriceFromZonePublicRequest, OrdersDeliveryPriceFromZonePublicResponse >(
            OrdersApiMethods.getDeliveryPriceFromZone,
            params);
        return res.data;
    }

    static async getMenuItemBookingByDate(params:  BookingsGetPublicMenuItemBookingsRequest){
        const res = await execute< BookingsGetPublicMenuItemBookingsRequest,  BookingsGetPublicMenuItemBookingsResponse>(
            OrdersApiMethods.bookingsGetPublicMenuItemBookings,
            params);
        return res.data;
    }

    static async createBookingOrder(params:  BookingOrdersCreateBookingOrderPublicRequest){
        const res = await execute< BookingOrdersCreateBookingOrderPublicRequest,  BookingOrdersCreateBookingOrderPublicResponse>(
            OrdersApiMethods.bookingOrdersCreateBookingOrderPublic,
            params);
        return res.data;
    }
    static async cancelBookingOrder(params:  BookingOrdersCancelBookingOrderPaymentRequest){
        const res = await execute< BookingOrdersCancelBookingOrderPaymentRequest,  BookingOrdersCancelBookingOrderPaymentResponse>(
            OrdersApiMethods.bookingOrdersCancelBookingOrderPayment,
            params);
        return res.data;
    }

    static async getBookingOrder(params:  BookingOrdersGetBookingOrderPublicRequest){
        const res = await execute< BookingOrdersGetBookingOrderPublicRequest,  BookingOrdersGetBookingOrderPublicResponse>(
            OrdersApiMethods.bookingOrdersGetBookingOrderPublic,
            params);
        return res.data;
    }

    static async getBooking(params:  BookingsGetBookingRequest){
        const res = await execute< BookingsGetBookingRequest,  BookingsGetBookingResponse>(
            OrdersApiMethods.bookingsGetBooking,
            params);
        return res.data;

    }

    static async getBookings(params:  BookingsGetBookingsRequest){
        const res = await execute< BookingsGetBookingsRequest,  BookingsGetBookingsResponse>(
            OrdersApiMethods.bookingsGetBookings,
            params);
        return res.data;

    }

    static async deleteBooking(params:  BookingsDeleteBookingRequest){
        const res = await execute< BookingsDeleteBookingRequest,  BookingsDeleteBookingResponse>(
            OrdersApiMethods.bookingsDeleteBooking,
            params);
        return res.data;

    }

}

export default OrdersService;
