import { useLocation } from "react-router-dom";

function useCategoryIdAndItemId() {
    const location = useLocation();
    const list =  location.pathname.split('/');
    if(list.length === 6){
        return {
            categoryId: list[3],
            itemId: list[5]
        }
    }else if(list.length === 4){
        return {
            categoryId: list[3]
        }
    }

    return {};
}

export default useCategoryIdAndItemId;
