

import EnterAliasPage from "../pages/enterAlias/"
import CatalogPage from "../pages/catalog/"
import PayPreorderPage from "../pages/payPreorder"
import PaymentResultSuccess from "../pages/paymentsResult/success"
import ToBankCardPayment from "../pages/toBankCardPayment"
import OrderingNotAvailable from "../pages/orderingNotAvailable"
import ViewOrderPage from "../pages/viewOrder/index"
import ToCompanyByInvoicePage from "../pages/companyInvoice";
import OrdersList from "pages/orderList/OrdersList"
import OrderPaymentCheck from "../pages/OrderPaymentCheck";
import MenuTypeWrapper from "../pages/MenuTypeWrapper";
import MenuTypeCartWrapper from "../pages/MenuTypeCartWrapper";
import BookingOrdersList from "../pages/bookingOrderList/OrdersList";
import ViewBookingOrderPage from "../pages/viewBookingOrder";

export const config = [
    {
        id: "cart",
        path: "/cart",
        exact: true,
        name: "Корзина",
        component: MenuTypeCartWrapper,
    },
    {
        id: "ordersList",
        path: "/orders",
        exact: true,
        name: "список заказов",
        component: OrdersList,
    },
    {
        id: "bookingOrdersList",
        path: "/bookings",
        exact: true,
        name: "список бронирований",
        component: BookingOrdersList,
    },

    {
        id: "payPreorder",
        path: "/preorder/:orgId/:branchId/:menuId/:preorderId/pay",
        exact: true,
        name: "Оплата предзаказа",
        component: PayPreorderPage,
    },
    {
        id: "orderCreated",
        path: "/order-created",
        exact: true,
        name: "Заказ создан",
        component: PaymentResultSuccess,
    },
    {
        id: "orderPaymentCheck",
        path: "/order/:orgId/:branchId/:menuId/:orderId/payment",
        exact: true,
        name: "Ожидание оплаты",
        component: OrderPaymentCheck,
    },
    {
        id: "toBankCardPayment",
        path: "/order/:orgId/:branchId/:menuId/:orderId/toBankCardPayment",
        exact: true,
        name: "Оплата заказа",
        component: ToBankCardPayment,
    },
    {
        id: "toCompanyByInvoice",
        path: "/order/:orgId/:branchId/:menuId/:orderId/toCompanyByInvoice",
        exact: true,
        name: "Оплата заказа",
        component: ToCompanyByInvoicePage,
    },
    {
        id: "viewOrder",
        path: "/order/:orgId/:branchId/:menuId/:orderId/view",
        exact: true,
        name: "Заказ создан",
        component: ViewOrderPage,
    },
    {
        id: "viewBookingOrder",
        path: "/booking-order/:orgId/:branchId/:menuId/:orderId/view",
        exact: true,
        name: "Заказ создан",
        component: ViewBookingOrderPage,
    },
    {
        id: "orderingNotAvailable",
        path: "/orders-not-available",
        exact: true,
        name: "E-menu выключено",
        component: OrderingNotAvailable,
    },
    {
        id: "menu",
        path: "/catalog/:alias/",
        name: "Каталог",
        component: CatalogPage,
    },
    {
        id: "enterAlias",
        path: "/enter-alias",
        name: "Введите алиас",
        component: EnterAliasPage,
    },
    /*{
        id: "menu",
        path: "/:alias/category/:categoryId/",
        name: "Меню",
        component: MenuView,
        exact: true
    },
    {
        id: "menu",
        path: "/:alias/category/:categoryId/item/:itemId",
        name: "Меню",
        component: MenuView,
    },*/
    {
        id: "menu",
        path: "/",
        name: "Меню",
        component: MenuTypeWrapper,
    },


];


