import { action, computed, makeAutoObservable, runInAction } from "mobx"
import { Menu, MenuBookingItem, MenuItem, MenuType, Table } from "../../services/menu/models";
import { getPublicAliasBindingByAlias } from "../../services/aliases";
import MenuService from "../../services/menu/index";
import { ExecutionStatus } from "../../apiCommandsExecutor/api";


class GeneralMenuViewStore {
    //activeCategory: number = 21;
    scroll: boolean = false;
    //isReady: boolean = false;
    //@ts-ignore
    menu: Menu = null;
    //@ts-ignore
    // modifiersSet: { [s: string]: MenuItemModifierSet } = {};
    // categories: MenuCategory[] = [];
    menus: { [s: string]: Menu } = {};
    type: MenuType = MenuType.none;

    //@ts-ignore
    items: { [s: string]: MenuItem[] } | { [s: string]: MenuBookingItem[] } = {};
    tables: Table[] = [];
    //products = content.productsById as { [s: number]: any };

    isModifiersPopupVisible: boolean = false;
    isCategoryMenuVisible: boolean = false;
    isDetailsModalVisible: boolean = false;
    isCartModalVisible: boolean = false;
    //selectedPopupItem?: MenuItem | MenuBookingItem;
    isOverlayOpen: boolean = false;
    isLangsModalOpen: boolean = false;

    onOverlayClick?: () => void;


    // isLoaded: boolean = false;
    //
    // status: {
    //     err: boolean,
    //     msg: string
    // } = {err: false, msg: ""};

    constructor() {
        makeAutoObservable(this);
    }


    get isChooseLangsEnabled() {
        if (this.menu) {
            return Object.values(this.menu.data.availableLanguages).filter(t => t.isEnabled).length > 1;}
    }


    @action
    setOverlay (b: boolean) {
        this.isOverlayOpen=true;
    }

    @action
    setMenu (menu: Menu) {
        this.menu = menu;
    }


    @action
    setMenuType (type: MenuType) {
        this.type = type;
    }

    // @action
    // setError(msg: string) {
    //     this.status.err = true;
    //     this.status.msg = msg;
    // }
    //
    // @action
    // removeError() {
    //     this.status.err = false;
    //     this.status.msg = "";
    // }


    // @action
    // checkingScheduleHours() {
    //     if (!this.menu) return;
    //     const today = moment().add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes');
    //     const weekBefore = today.clone().subtract(7, 'days');
    //
    //     const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    //     const daysMoment: {
    //         [s: number]: Moment
    //     } = [];
    //     let t = weekBefore.clone();
    //     while (t.toDate() <= today.toDate()) {
    //         daysMoment[t.day()] = t;
    //         t = t.clone().add(1, 'day');
    //     }
    //
    //
    //     let slots: {
    //         from: Moment,
    //         to: Moment
    //     }[] = [];
    //
    //     slots = Object.entries(this.menu.data.workingSchedule).filter(([day, item]) => {
    //         if (days.indexOf(day) < 0) return false;
    //         const schedule = item as WorkingScheduleDay;
    //         return !schedule.isDayOff
    //     }, []).map(([day, schedule]: [string, WorkingScheduleDay]) => {
    //         const dayNumber = days.indexOf(day);
    //         const date = daysMoment[dayNumber];
    //         const slot = schedule.slots[0];
    //         let from = date.clone().hours(slot.fromHour).minutes(slot.fromMinute).add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes')
    //         let to = date.clone().hours(slot.toHour).minutes(slot.toMinute).add(this.menu.data.workingSchedule.timezoneInMinutes, 'minutes');
    //         if (slot.toHour < slot.fromHour) {
    //             to.add(1, 'day')
    //         }
    //         return {
    //             from, to
    //         };
    //     })
    //
    //     const result = slots.filter(slot => {
    //         return today.toDate() >= slot.from.toDate() && today.toDate() <= slot.to.toDate()
    //     }).length
    //
    //     return result > 0;
    // }
    //
    //
    // @computed
    // get workingTime() {
    //     return this.checkingScheduleHours();
    // }


    // @action
    // setIsReady(value: boolean) {
    //     this.isReady = value;
    // }
    //
    // @action
    // setIsLoaded(value: boolean) {
    //     this.isLoaded = value;
    // }
    //
    //
    // @action
    // setActiveCategory(categoryId: number, scroll: boolean = false) {
    //     this.activeCategory = categoryId;
    //     this.scroll = scroll;
    // }

    @action
    resetScroll() {
        this.scroll = false;
    }


    // @computed
    // getProductById(id: number) {
    //     return this.products[id]
    // }

    @computed
    getModifiersById(id: number) {

        //@ts-ignore
        return this.modifiersSet[id.toString()]
    }

    // @action
    // openModifiersPopup(item: MenuItem | MenuBookingItem, onOverlayClick: () => void) {
    //     this.isModifiersPopupVisible = true;
    //     this.isOverlayOpen = true;
    //     this.onOverlayClick = onOverlayClick;
    //     this.selectedPopupItem = item;
    //     document.body.style.overflow = 'hidden';
    // }
    //
    // @action
    // closeModifiersPopup() {
    //     this.isModifiersPopupVisible = false;
    //     this.isOverlayOpen = false;
    //     this.onOverlayClick = undefined;
    //     this.selectedPopupItem = undefined;
    //     document.body.style.overflow = 'auto';
    // }

    @action
    openCategoryMenu(onOverlayClick: () => void) {
        this.isCategoryMenuVisible = true;
        this.isOverlayOpen = true;
        this.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeCategoryMenu() {
        this.isCategoryMenuVisible = false;
        this.isOverlayOpen = false;
        this.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }

    @action
    openCartModal(onOverlayClick: () => void) {
        this.isCartModalVisible = true;
        this.isOverlayOpen = true;
        this.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeCartModal() {
        this.isCartModalVisible = false;
        this.isOverlayOpen = false;
        this.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }

    @action
    openInfoDetailesModal(onOverlayClick: () => void) {
        this.isDetailsModalVisible = true;
        this.isOverlayOpen = true;
        this.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeInfoDetailesModal() {
        this.isDetailsModalVisible = false;
        this.isOverlayOpen = false;
        this.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }

    @action
    openLangsModal(onOverlayClick: () => void) {
        this.isLangsModalOpen = true;
        this.isOverlayOpen = true;
        this.onOverlayClick = onOverlayClick;
        document.body.style.overflow = 'hidden';
    }

    @action
    closeLangsModal() {
        this.isLangsModalOpen = false;
        this.isOverlayOpen = false;
        this.onOverlayClick = undefined;
        document.body.style.overflow = 'auto';
    }

    // @action
    // setData(obj: any) {
    //     //this.activeCategory = obj.activeCategory;
    //     //this.categories = obj.categories;
    //     //this.productsByCategory = obj.productsByCategory;
    // }

    // @computed
    // getMinPrice(item: MenuItem) {
    //     let minPrice = 0;
    //     item.data.menuItemModifierSets.forEach((setId) => {
    //         const set = this.getModifiersById(setId.menuItemModifierSetId);
    //         let min = Number.MAX_SAFE_INTEGER;
    //         set.data.modifiers.forEach((item) => {
    //             if (item.data.sellPrice.price < min) {
    //                 min = item.data.sellPrice.price
    //             }
    //         })
    //         if (set.data.type === ModifiersSetType.ChooseOne) {
    //             minPrice += min;
    //         } else {
    //             let {minCountTotalChosen, maxCountTotalChosen} = set.data;
    //             if (minCountTotalChosen > 0) minPrice += min;
    //         }
    //     });
    //     if (minPrice === Number.MAX_SAFE_INTEGER) return 0;
    //     return minPrice;
    // }
    //
    static fromJSON(obj: any) {
        const products = new GeneralMenuViewStore();
        //products.setData(obj);
        return products;
    }


    // @computed
    // getTableById(id: number) {
    //     return this.tables.find(item => item.id === id);
    // }
    //
    // @computed
    // getTableByPublicId(id: string) {
    //     return this.tables.find(item => item.data.publicId === parseInt(id));
    // }
    //
    // @computed
    // get storeFrontMode() {
    //     return this.menu.data.themeSettings.storefrontPhotosMode;
    // }
    //
    // updateManifest(alias: string, name: string, logoUrl: string | null) {
    //     const newManifest = {
    //         ...manifest,
    //         short_name: name,
    //         name: name,
    //         start_url: `${alias}`,
    //         icons: logoUrl ? [
    //             {
    //                 "src": logoUrl,
    //                 "sizes": "391x391",
    //                 "type": "image/jpeg"
    //             }
    //         ] : manifest.icons
    //     }
    //     const stringManifest = JSON.stringify(newManifest);
    //     const blob = new Blob([stringManifest], {type: 'application/json'});
    //     const manifestURL = URL.createObjectURL(blob);
    //     document.getElementById('my-manifest-placeholder')?.setAttribute('href', manifestURL);
    //     document.title = name;
    //     if (logoUrl) {
    //         document.getElementById('apple-touch-icon')?.setAttribute('href', logoUrl);
    //     }
    //
    //
    // }

    async getMenuByAlias(alias: string, menuId: number) {
        const res = await getPublicAliasBindingByAlias({
            alias: alias
        });
        runInAction(() => {
            this.menus = Object.values(res.menusByOrgBranchIdByMenuId).reduce((memo, items) => {
                return {
                    ...memo,
                    ...items
                }
            });
            this.menu = this.menus[menuId]
        })
    }
    async getMenuTypeByMenuId(menuId: number, orgId: number, branchId: number) {
        const res = await MenuService.getMenuTypeByMenuId({
            menuId:menuId,
            organizationBranchId: orgId,
            organizationId: branchId
        });
        runInAction(() => {
            if (res.executionStatus === ExecutionStatus.Finished) {
                this.type = res?.menu?.type
            }
        })
    }
}

export default GeneralMenuViewStore;
