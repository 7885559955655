import style from "../style.module.sass";
import {useTranslation} from "react-i18next";


const HitBadge = () => {
    const { i18n, t } = useTranslation(["cart"]);
    return (
        <div className={style.hitBadge}>
            <p>&#128293; {t("cart:hit")}</p>
        </div>
    )
}

export default HitBadge;
